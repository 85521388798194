import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PaymentTypes } from '../../../features/payment';
import { CartTypes, cartSelectors } from '../../../features/cart';
import { orderLinesSelectors } from '../../../features/order-lines';
import { paymentSelectors } from '../../../features/payment';
import { subtotalsSelectors } from '../../../features/subtotals';
import { uiSelectors, uiActions } from '../../../features/ui';
import PaymentButtonLoading from './PaymentButtonLoading';
import './PaymentButton.scss';
import PaymentTcAcceptance from '../PaymentTcAcceptance/PaymentTcAcceptance';
import { NonGenericPaymentButton } from '../NonGenericPaymentButton';

// NOTA BENE
// Gateway = third party services handling payment technically (For exemple split it)
// PSP = Payment Service Provider - third party services providing gateway for multiple methods AND bank account contract (for exemple adyen and braintree)
// Orchestrator = service to handle all payment methods in a single point (not implemented yet) - (for exemple Primer)
// in a coming futur we are going to use a single orchestrator to deal with all payment methods
// for now we are using Braintree (PSP with gateway) / Adyen (PSP with gateway) / Split it (gateway) / Affirm (gateway)

const AdyenPaymentButton = React.lazy(() => import('../AdyenPaymentButton/AdyenPaymentButton'));
const PaypalPaymentButton = React.lazy(() => import('../PaypalPaymentButton/PaypalPaymentButton'));

type SelectedPaymentButtonProps = {
  paymentMethod: PaymentTypes.OrderPaymentMethod;
};

export const SelectedPaymentButton: React.FC<SelectedPaymentButtonProps> = ({
  paymentMethod,
}: SelectedPaymentButtonProps) => {
  const { t } = useTranslation();
  const orderLines = useAppSelector(orderLinesSelectors.getValues);
  const subtotals = useAppSelector(subtotalsSelectors.getRoot);
  const cart = useAppSelector(cartSelectors.getRoot);
  const genericGatewayInfo = useAppSelector(paymentSelectors.getGenericGatewayInfo);
  const nonGenericGatewayInfo = useAppSelector(paymentSelectors.getNonGenericGatewayInfo);
  const { pspName: gatewayName } = paymentMethod;
  const loadAdyen = genericGatewayInfo && gatewayName === PaymentTypes.GatewayEnums.ADYEN;
  const loadBraintree = genericGatewayInfo && gatewayName === PaymentTypes.GatewayEnums.BRAINTREE;
  const loadNonGenericGateways =
    nonGenericGatewayInfo &&
    [PaymentTypes.GatewayEnums.AFFIRM, PaymentTypes.GatewayEnums.SPLITIT].includes(
      gatewayName as PaymentTypes.GatewayEnums
    );

  return (
    <div className="paymentButtonsWrapper">
      {loadBraintree && (
        <React.Suspense fallback={<PaymentButtonLoading text={t('PAYMENT.PAY_BUTTON', 'Submit order')} />}>
          <PaypalPaymentButton
            clientToken={String(genericGatewayInfo.remoteRef)}
            paymentToken={genericGatewayInfo.paymentId}
            orderLines={orderLines}
            subtotals={subtotals}
            cartId={cart.id}
          />
        </React.Suspense>
      )}
      {loadAdyen && (
        <React.Suspense fallback={<PaymentButtonLoading text={t('PAYMENT.PAY_BUTTON', 'Submit order')} />}>
          <AdyenPaymentButton
            gatewayInfo={genericGatewayInfo}
            paymentMethod={paymentMethod}
            orderId={cart.id}
            orderAmountFormatted={subtotals.totalAmount.formatted}
          />
        </React.Suspense>
      )}
      {loadNonGenericGateways && (
        <React.Suspense fallback={<PaymentButtonLoading text={t('PAYMENT.PAY_BUTTON', 'Submit order')} />}>
          <NonGenericPaymentButton gatewayInfo={nonGenericGatewayInfo} />
        </React.Suspense>
      )}
      <PaymentTcAcceptance />
    </div>
  );
};

const PaymentButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(uiSelectors.getPayButtonLoading);
  const isSummaryLoading = useAppSelector(uiSelectors.getSummaryLoading);
  const paymentMethod = useAppSelector(paymentSelectors.getSelectedPaymentMethod);
  const gatewayStatus = useAppSelector(paymentSelectors.getGatewayStatus);
  const cartStatus = useAppSelector(cartSelectors.getStatus);
  const isBillingAddressMissing = cartStatus === CartTypes.StatusEnum.ERROR_REASON_NO_BILLING_ADDRESS;

  useEffect(() => {
    if (PaymentTypes.StatusEnums.IDLE === gatewayStatus || gatewayStatus.startsWith('PEND') || isSummaryLoading) {
      dispatch(uiActions.setPayButtonLoading(true));
      return;
    }
    dispatch(uiActions.setPayButtonLoading(false));
  }, [dispatch, gatewayStatus, isSummaryLoading]);

  if (!paymentMethod || isLoading || isSummaryLoading) {
    return (
      <div className="paymentButtonsWrapper">
        <PaymentButtonLoading text={t('PAYMENT.PAY_BUTTON', 'Submit order')} noLoader={isBillingAddressMissing} />
        <PaymentTcAcceptance />
      </div>
    );
  }

  if (gatewayStatus === 'OK_INIT_ITEM') return <SelectedPaymentButton paymentMethod={paymentMethod} />;

  return null;
};

export default PaymentButton;
