import type { RootState } from '../../store';
import { State, Country } from './types';
import { get2DigitLanguage } from './services';
import { updateRegionIdIfNecessary, MIGRATION_SITE_INFO } from '../../helpers';

export const getRoot = (state: RootState): State => state.i18n;
export const getLanguage = (state: RootState): string => state.i18n.language;

export const getListOfCountries = ({ i18n, bucketTest }: RootState) => {
  const countryList = i18n.dict?.country || {
    fullList: [],
    shortLists: [],
  };
  /**
   * Start of Site ID migration
   * Once the migration is done, this code block can be removed or refactored for future use
   */
  const newSiteIdEnableStatus: {
    [k in Country['isoCode']]?: boolean;
  } = Object.keys(MIGRATION_SITE_INFO).reduce((acc, item) => {
    const { ffName } = MIGRATION_SITE_INFO[item];
    const ffValue = bucketTest.flags?.[ffName] || false;

    acc[item] = ffValue;
    return acc;
  }, {});
  /**
   * End of Site ID migration
   */

  return {
    title: i18n.dict?.country?.title,
    fullList: updateRegionIdIfNecessary(countryList.fullList || [], newSiteIdEnableStatus),
    shortLists: updateRegionIdIfNecessary(countryList.shortLists || [], newSiteIdEnableStatus),
  };
};
export const getListOfLanguages = ({ i18n }: RootState) => i18n.dict?.language;
export const getListOfCurrencies = ({ i18n }: RootState) => i18n.dict?.currency;

export const getCountryByIsoCode =
  (isoCode: string) =>
  ({ i18n }: RootState) =>
    i18n.dict?.country.fullList.find((x) => x.isoCode === isoCode);

export const i18nAsObject = ({ i18n, bucketTest }: RootState) => {
  const enabledSiteIds: {
    [k in Country['isoCode']]?: boolean;
  } = bucketTest.flags?.newSiteIdEnableStatus || {};

  const fullCountryList = updateRegionIdIfNecessary(i18n.dict?.country.fullList || [], enabledSiteIds);

  const country = fullCountryList.find((x) => x.isoCode === i18n.country);
  const language = i18n.dict?.language.list.find((x) => get2DigitLanguage(x.isoCode) === i18n.language);
  const currency = i18n.dict?.currency.list.find((x) => x.isoCode === i18n.currency);

  return { country, language, currency };
};
