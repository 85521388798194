import { Entities } from '@vestiaire/api-specification';
import cookie from 'react-cookies';
import environment from '../../environments';
import type { Dictionary, CC, GetLocalizationOptionsType } from './types';

const LANG_KEY_MAPPING = new Map([
  ['de', 'de-DE'],
  ['en', 'en-GB'],
  ['es', 'es-ES'],
  ['fr', 'fr-FR'],
  ['it', 'it-IT'],
  ['us', 'en-US'],
  ['ko', 'ko-KR'],
  ['hk', 'zh-HK'],
  ['nl', 'nl-NL'],
  ['sv', 'sv-SE'],
]);

export function getCookie(): string {
  const i18nCookie = cookie.load(environment.i18nCookie) || '6.us.USD';
  return i18nCookie;
}

export function get() {
  return getCookie().split('.') as [string, string, Entities.Currency];
}

export function getLanguage(): string {
  const [, lang] = get();
  return lang;
}

export function getIsoLanguage(): string {
  return LANG_KEY_MAPPING.get(getLanguage()) || '';
}

export function get2DigitLanguage(isoCode: string): string {
  let name = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of LANG_KEY_MAPPING.entries()) {
    if (value === isoCode) {
      name = key;
      break;
    }
  }
  return name;
}

export function getSiteId(): string {
  const [siteId] = get();
  return siteId;
}

export function getCurrency(): Entities.Currency {
  const [, , currency] = get();
  return currency;
}

export function getLocalisationOptions(options?: GetLocalizationOptionsType): Promise<Dictionary> {
  const url = `${environment.apiStaticBaseUrl}/${options?.siteId || getSiteId()}/${
    options?.language || getIsoLanguage()
  }/I18N.json`;
  return fetch(url)
    .then((res) => res.json())
    .catch((e) => {
      throw e;
    });
}

function b64ToUtf8(str: string) {
  return window.atob(decodeURIComponent(str));
}

export function getVcccCookie(): CC {
  const countryCookie: string = cookie.load('vc_cc');
  try {
    return JSON.parse(b64ToUtf8(countryCookie));
  } catch {
    return { CC: 'US', displayName: {} } as CC;
  }
}

export function getCountry(): string {
  const { CC } = getVcccCookie();
  return CC;
}

export function getCountryTranslations(): CC['displayName'] {
  const vcc = getVcccCookie();
  return vcc.displayName;
}

export function setVcckCookie(siteId: string, lang: string, currency: string): void {
  const value = `${siteId}.${lang}.${currency}`;
  cookie.save(environment.i18nCookie, value, environment.cookieOptions);
}

export function setVcccCookie(ccInfo: CC): void {
  const newCountryCookie = btoa(JSON.stringify(ccInfo));
  cookie.save('vc_cc', newCountryCookie, environment.cookieOptions);
}
