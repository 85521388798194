import { ErrorTypes } from '../types';

type ErrorOptions = {
  errorType: ErrorTypes;
};

const { debug, info } = console;

const error = (section: string, body?: any, opts?: ErrorOptions) => {
  try {
    const err =
      body && typeof body === 'object' && ('message' in body || 'stack' in body)
        ? body
        : new Error(JSON.stringify(body));
    const [module = '', operation = '', ...moreInfo] = section.split('::');
    if (window.DD_RUM?.addError) {
      window.DD_RUM.addError(err, {
        errorType: ErrorTypes.UNSPECIFIED,
        module,
        operation,
        moreInfo: moreInfo.join('::'),
        ...opts,
      });
    }
  } catch (e) {
    console.error('[app]', '[logger]', `[${section}]`, body, e);
  }
};

const logger = { error, debug, info };

export default logger;
