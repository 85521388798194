import type { LDUser, LDFlagSet, LDOptionsBase, LDClient } from 'launchdarkly-js-client-sdk';
import type { Action, InferAction, WithOptional, WithStatusType } from '../../types';

export enum StatusEnum {
  IDLE = 'IDLE',
  PEND_INIT_ITEM = 'PEND_INIT_ITEM',
  OK_INIT_ITEM = 'OK_INIT_ITEM',
  ERROR_INIT_ITEM = 'ERROR_INIT_ITEM',
}

export type State = WithStatusType & {
  clientId: string;
  flags: LDFlagSet;
  user: LDUser;
  client?: LDClient;
  options: LDOptionsBase;
  keyMaps?: Record<string, string>;
  errorMsg?: string;
};

export enum ActionTypes {
  INITIALIZE = 'bucketTest/initlaize',
  RESOLVE = 'bucketTest/resolve',
  REJECT = 'bucketTest/reject',
  RETRY = 'bucketTest/retry',
  IDENTIFY_USER = 'bucketTest/identifyUser',
}

export type InitializationPayload = undefined;
export type ResolvePayload = WithOptional<
  Omit<State, 'errorMsg' | 'status'>,
  'client' | 'clientId' | 'options' | 'user'
>;
export type RejectPayload = Pick<State, 'errorMsg'>;
export type IdentifyUser = undefined;

export type PayloadTypes = {
  [ActionTypes.INITIALIZE]: InitializationPayload;
  [ActionTypes.RESOLVE]: ResolvePayload;
  [ActionTypes.REJECT]: RejectPayload;
  [ActionTypes.IDENTIFY_USER]: IdentifyUser;
};

export type Actions = Action<PayloadTypes>[keyof Action<PayloadTypes>];
export type InferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T>;
