import environment from '../../environments';
import logger from '../../services/logger.service';
import { readLocalStorageJson } from '../../helpers/local-storage';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { PathScreenNameValues, PreSelectedVariables, ShippingMethod } from './types';
import { Entities } from '@vestiaire/api-specification';
import { AddressTypes } from '../address';
import { DeliveryMethods } from '../cart/types';
import { CartTypes } from '../cart';
import { OrderLinesTypes } from '../order-lines';
import { ROUTES, ValidRoute } from '../../helpers/routes';
import { PaymentTypes } from '../payment';
import { VoucherTypes } from '../voucher';
import { SubtotalsTypes } from '../subtotals';
import {
  TagContainer,
  AnalyticsEvent,
  FunctionTrackers,
  FunctionTrackersValueTypes,
  DataLayerInterface,
  DLInitialState,
  UserDataLayerState,
  UserAccountLevel,
  BooleanString,
  UserCategory,
  BuyerStatus,
  SellerStatus,
  UserBadge,
  SellerRating,
  IProductDataLayer,
  ICheckoutDataLayer,
  ScreenName,
  EnvTemplate,
  PageUrlsEnum,
} from './types';

export const screenNameMap = new Map<ValidRoute, ScreenName>([
  [ROUTES.CART, ScreenName.Cart],
  [ROUTES.CHECKOUT, ScreenName.Checkout],
  [ROUTES.PAYMENT_REDIRECT, ScreenName.PaymentRedirect],
]);

export const getScreenName = (pathname: ValidRoute): ScreenName | undefined => {
  const screenNameMapKeys = Array.from(screenNameMap.keys());
  const key = screenNameMapKeys.find((e) => pathname.startsWith(e)) as ValidRoute;
  if (key === undefined) {
    logger.error('tracking::screenNameMapper::wrongkey', pathname);
  }
  return screenNameMap.get(key);
};

export const getPathScreenName = (pathname: ValidRoute): PathScreenNameValues | ValidRoute => {
  const screenName = getScreenName(pathname);
  return screenName === undefined ? (pathname as ValidRoute) : `/${screenName}`;
};

export const envTemplateMapper = new Map<ValidRoute, EnvTemplate>([
  [ROUTES.CART, EnvTemplate.Cart],
  [ROUTES.CHECKOUT, EnvTemplate.Checkout],
  [ROUTES.ORDER_CONFIRMATION, EnvTemplate.Confirmation],
  [ROUTES.PAYMENT_REDIRECT, EnvTemplate.Checkout],
]);

export const getEnvTemplate = (pathname: ValidRoute): EnvTemplate | undefined => {
  const EnvMapKeys = Array.from(screenNameMap.keys());
  const key = EnvMapKeys.find((e) => pathname.startsWith(e)) as ValidRoute;
  if (key === undefined) {
    logger.error('tracking::getEnvTemplate::wrongkey', pathname);
  }
  return envTemplateMapper.get(key);
};

export const pageUrlsMapper = new Map<ValidRoute, PageUrlsEnum>([
  [ROUTES.CART, PageUrlsEnum.Cart],
  [ROUTES.CHECKOUT, PageUrlsEnum.Checkout],
  [ROUTES.ORDER_CONFIRMATION, PageUrlsEnum.Confirmation],
]);

export const getPageUrl = (pathname: ValidRoute): PageUrlsEnum | undefined => {
  const pageUrlKeys = Array.from(screenNameMap.keys());
  const key = pageUrlKeys.find((e) => pathname.startsWith(e)) as ValidRoute;
  if (key === undefined) {
    logger.error('tracking::getPageUrl::wrongkey', pathname);
  }
  return pageUrlsMapper.get(key);
};

/**
 * Helper functions to map user informations to userDatalayerState
 */

function getUserCategory(user: Entities.User): UserCategory {
  const nbSold = user.statistics?.productsSold || 0;
  const nbBought = user.statistics?.productsBought || 0;

  if (nbSold > 0 && nbBought > 0) return UserCategory.Mixed;
  if (nbSold > 0) return UserCategory.Seller;
  if (nbBought > 0) return UserCategory.Buyer;
  return UserCategory.Visitor;
}

function getBuyerStatus(user: Entities.User): BuyerStatus {
  const nbBought = user.statistics?.productsBought || 0;

  if (nbBought >= 10) return BuyerStatus.TopBuyer;
  if (nbBought >= 2) return BuyerStatus.RepeatedBuyer;
  if (nbBought === 1) return BuyerStatus.FirstBuyer;

  return BuyerStatus.Viewer;
}

function getSellerStatus(user: Entities.User): SellerStatus {
  const nbSold = user.statistics?.productsSold || 0;
  const nbListed = user.statistics?.productsListed || 0;

  if (nbSold >= 10) return SellerStatus.TopSeller;
  if (nbSold >= 2) return SellerStatus.RepeatedSeller;
  if (nbSold === 1) return SellerStatus.FirstSeller;
  if (nbListed >= 1) return SellerStatus.Depositor;

  return SellerStatus.Viewer;
}

function getSellerRating(userBadges: UserBadge[]): SellerRating {
  if (userBadges?.includes(UserBadge.ExpertSeller)) return SellerRating.Expert;
  if (userBadges?.includes(UserBadge.TrustedSeller)) return SellerRating.Trusted;

  return SellerRating.Common;
}

function diffMonths(startDate: string) {
  let months;
  const d1 = new Date(startDate);
  const d2 = new Date();
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function diffDays(startDate: string) {
  return Math.floor((new Date().getTime() - new Date(startDate).getTime()) / 86400000);
}

function getUserRelativeDate(userInscriptionDate: string | undefined) {
  if (!userInscriptionDate) return '';
  const days = diffDays(userInscriptionDate);
  return days > 30 ? `relativedate=m${diffMonths(userInscriptionDate)}` : `relativedate=d${days}`;
}

type MapUserDataLayerProps = {
  user: Entities.User;
  deliveryAddress?: AddressTypes.Entity;
  pageUrlBase?: string;
  bucketTestClient?: LDClient;
};

export function mapUserDataLayer(data: MapUserDataLayerProps): Partial<DataLayerInterface> {
  const userCategory = getUserCategory(data.user);
  const sellerStatus = getSellerStatus(data.user);
  const buyerStatus = getBuyerStatus(data.user);
  const relativeDate = getUserRelativeDate(data.user.inscriptionDate);
  const userStatusForPageUrl = `${userCategory}/${buyerStatus}/${relativeDate}`;
  const userState: UserDataLayerState = {
    authAttempted: BooleanString.True,
    user_loginStatus: 'logged',
    user_logged: BooleanString.True,
    user_id: data.user.id,
    user_email: data.user.email || '',
    order_email: data.user.email || '',
    cust_firstname: data.user.firstname.toLowerCase(),
    user_accountLevel: UserAccountLevel.Regular,
    user_category: userCategory,
    user_buyerStatus: buyerStatus,
    user_sellerStatus: sellerStatus,
    user_iphoneApp: BooleanString.False,
    user_androidApp: BooleanString.False,
    user_date_inscription: data.user.inscriptionDate || '',
    user_relativedate: relativeDate, // this one is to compare with legacy dataLayer
    user_seller_rating: getSellerRating(data.user.badges as UserBadge[]),
    user_fashion_activist: data.user.badges?.includes('fashion-activist') ? BooleanString.True : BooleanString.False,
    user_postalcode: data.user.address?.zipCode || '',
    user_gender: data.user.civility.name,
    user_country: data.deliveryAddress?.country.iso2 || '',
    pageUrl: `${data.pageUrlBase}/${userStatusForPageUrl}`,
    order_confirmation_pageUrl: `${pageUrlsMapper.get(ROUTES.ORDER_CONFIRMATION)}/${userStatusForPageUrl}`,
    anonymous_id: String(data.bucketTestClient?.getUser().custom?.anonymous_id) || '',
  };

  if (data.user.apps && data.user.apps.length) {
    data.user.apps.forEach((app) => {
      if (app.key === 'ios') {
        userState.user_iphoneApp = app.value !== '0' ? BooleanString.True : BooleanString.False;
      }

      if (app.key === 'android') {
        userState.user_androidApp = app.value !== '0' ? BooleanString.True : BooleanString.False;
      }
    });
  }

  return userState;
}

type MapProductDataLayerProps = {
  orderLines: OrderLinesTypes.State;
  deliveryAddress: AddressTypes.Entity | undefined;
  preSelectedVariables?: PreSelectedVariables;
};
/**
 * Removes currency signs and any non-numeric characters from a given amount string.
 *
 * @param {string} amount - The input string representing the amount with a possible currency sign.
 * @returns {string} - The amount without any currency sign or non-numeric characters.
 *
 * @example
 *   centsDivisionBy100(1556);  // returns "15.56"
 *   centsDivisionBy100(1556);  // returns "15.56"
 */
export function centsDivisionBy100(amount: number): string {
  return String(amount / 100);
}
export function mapProductDataLayer(data: MapProductDataLayerProps): IProductDataLayer {
  return <IProductDataLayer>{
    product_details: Object.values(data.orderLines)
      .filter((x) => x.type === 'orderLine')
      .map((ol) => ({
        product_id: String(ol?.product.id || ''),
        seller_id: String(ol?.product.seller?.id || ''),
        product_universe: String(ol?.product.universe?.name || ''),
        product_category: String(ol?.product.category?.name || ''),
        product_subcategory: String(ol?.product.subcategory?.name || ''),
        product_brand: String(ol?.product.brand?.name || ''),
        product_unit_price: String((ol?.product.price.cents || 0) / 100),
        product_country: String(ol?.product.seller?.country || ''),
        product_currency: String(ol?.product.price.currency || ''),
        product_crossborder_transaction: String(ol?.product.seller?.country === data.deliveryAddress?.country.name),
        product_ds_eligible: String(Boolean(ol?.plans?.filter((pl) => pl.code === 'direct-shipping').length)),
        product_shipping_method_selected: String(
          ol?.plans?.filter((pl) => pl.selected).map((pl) => ShippingMethod[pl.code])[0] || ''
        ),
        product_shipping_method_preselected: String(
          data.preSelectedVariables?.product_shipping_method_preselected
            ?.filter((x) => x.productId === ol.product.id)
            .map((x) => ShippingMethod[x.preSelected])[0] || ''
        ),
      })),
    order_products: Object.values(data.orderLines)
      .filter((x) => x.type === 'orderLine')
      .map((ol) => ({
        order_products_quantity: '1',
        // unit price
        // from val.product.regularPrice
        order_products_unitprice_ati: centsDivisionBy100(ol.product.price?.cents || 0),
        order_products_id: ol.product.id || '',
        order_products_name: ol.product.name || '',
        order_products_brand: ol.product.brand?.name || '',
        order_products_color: ol.product.color?.name || '',
      })),
  };
}

type MapCheckoutDataLayerProps = {
  orderLines: OrderLinesTypes.State;
  cart: CartTypes.State;
  subtotals: SubtotalsTypes.State;
  billingIsSameAsDelivery: boolean;
  billingAddress?: AddressTypes.Entity;
  preSelectedVariables?: PreSelectedVariables;
  orderPaymentMethods?: PaymentTypes.OrderPaymentMethods;
  selectedPaymentMethod?: PaymentTypes.OrderPaymentMethod;
  vouchers?: VoucherTypes.Entity;
};

export function mapCheckoutDataLayer(data: MapCheckoutDataLayerProps): Partial<ICheckoutDataLayer> {
  const isShoppingCart = window.location.pathname.startsWith(ROUTES.CART);
  const orderLineValues = Object.values(data.orderLines);
  const plansList = orderLineValues.map((o) => o.plans || []);
  const productsList = orderLineValues.map((o) => o.product).filter(Boolean);
  const totalAmount = data.subtotals.totalAmount?.cents || 0;
  const totalAmountShoppingCart = data.subtotals.totalAmountShoppingCart?.cents || 0;
  const itemsAmount = data.subtotals.itemsAmountCents || 0;
  const itemsDutiesTaxesAmountEuro = data.subtotals.itemsDutiesTaxesAmountEuro?.cents || 0; // note: this value is always in euro :-))
  const voucherAmount = data.subtotals.voucherAmount?.cents || 0;
  const dutiesAmount = data.subtotals.dutiesAmount?.cents || 0;
  const shippingAmount = data.subtotals.shippingAmount?.cents || 0;
  const buyerFeesAmount = data.subtotals.buyerFeesAmount?.cents || 0;
  const salesTax = totalAmount - (itemsAmount - voucherAmount);

  const deliveryMethodMapper = new Map([
    [CartTypes.DeliveryMethods.HOME, CartTypes.DeliveryMethods.HOME],
    [CartTypes.DeliveryMethods.COLLECTION_POINT, 'pick_up_point'],
  ]);

  const getDeliveryOptions = (isEligibleToCollectionPoint: boolean) => {
    const homeDelivery = deliveryMethodMapper.get(CartTypes.DeliveryMethods.HOME);
    const collectionPointDelivery = deliveryMethodMapper.get(CartTypes.DeliveryMethods.COLLECTION_POINT);
    return isEligibleToCollectionPoint ? `${homeDelivery}, ${collectionPointDelivery}` : homeDelivery;
  };

  return {
    basket_id: data.cart.id,
    order_id: data.cart.id,
    basket_nb_products: String(productsList.length),
    basket_total_value: isShoppingCart ? String(totalAmountShoppingCart / 100) : String(totalAmount / 100),
    basket_shipping_costs: String(shippingAmount / 100),
    basket_authentication_costs: String(buyerFeesAmount / 100),
    basket_import_duties_taxes: String((data.subtotals.dutiesAmount?.cents || 0) / 100),
    order_amount_ati_with_sf: (totalAmount / 100).toString(),
    order_amount_ati_without_sf: (itemsDutiesTaxesAmountEuro / 100).toString(),
    order_amount_with_taxes: String(totalAmount / 100),
    order_amount_without_taxes: String((itemsAmount - voucherAmount) / 100),
    order_discount: String(voucherAmount / 100),
    order_products_number: String(Object.keys(data.orderLines).length),
    order_shipping_costs: String(shippingAmount / 100),
    order_authentication_costs: String(buyerFeesAmount / 100),
    order_import_duties_taxes: String(dutiesAmount / 100),
    order_delivery_options_displayed: getDeliveryOptions(Boolean(data.cart.isElligibleToCollectionPoint)),
    /* the above one will be deprecated after tag co update in profite of order_delivery_option_selected */
    order_delivery_selected: String(
      deliveryMethodMapper.get(data.cart.deliverySelectedMethod as CartTypes.DeliveryMethods)
    ),
    order_delivery_option_selected: JSON.stringify({
      delivery_method: deliveryMethodMapper.get(data.cart.deliverySelectedMethod as CartTypes.DeliveryMethods),
    }),
    order_delivery_preselected:
      deliveryMethodMapper.get(data.preSelectedVariables?.order_delivery_preselected as DeliveryMethods) || '',
    basket_same_address: String(data.billingIsSameAsDelivery),
    basket_shipping_address_created: String(!!data.billingAddress),
    order_payment_method_displayed: data.orderPaymentMethods?.items.map((x) => String(x.internalRef)).join(),
    order_payment_method_selected: String(data.selectedPaymentMethod?.internalRef || ''),
    order_payment_method_preselected: String(data.preSelectedVariables?.order_payment_method_preselected || ''),
    order_promo_codes: data.vouchers?.code || '',
    order_salestax: String(salesTax / 100),
    basket_ds_eligible: String(
      Boolean(plansList.map((l) => l.some((x) => x.code === 'direct-shipping')).filter(Boolean).length)
    ),
    order_is_pickup_eligible: String(Boolean(data.cart.isElligibleToCollectionPoint)),
    failure_reason: 'null',
  };
}

export class Service {
  // Make sure each session load one time only
  static sessionId = new Date().getTime();

  static trackingScriptsSrc = {
    [TagContainer.Media]: `//cdn.tagcommander.com/310/tc_Vestiairecollective_1.js`,
    [TagContainer.Analytics]: `//cdn.tagcommander.com/310/tc_Vestiairecollective_7.js`,
  };

  static functions: Map<TagContainer, FunctionTrackers> = new Map([
    [TagContainer.Media, FunctionTrackers.TCE1],
    [TagContainer.Analytics, FunctionTrackers.TCE7],
  ]);

  static containerLoaded: { [key: string]: boolean } = {
    [TagContainer.Media]: false,
    [TagContainer.Analytics]: false,
  };

  static containerLoadPromise: { [key: string]: Promise<void> | null } = {
    [TagContainer.Media]: null,
    [TagContainer.Analytics]: null,
  };

  static defaultContainer = TagContainer.Analytics;

  static verbose = environment.analytics.verbose;

  static loadContainer(containerId: string, onLoaded = () => {}): Promise<void> {
    return new Promise((resolve) => {
      if (Service.containerLoadPromise[containerId] && !Service.containerLoaded[containerId]) {
        // Container is loading => Make promise chain
        Service.containerLoadPromise[containerId] =
          Service.containerLoadPromise[containerId]?.then(() => {
            if (onLoaded) onLoaded();
          }) || null;

        return;
      }

      const script = document.createElement('script');
      script.src = `${Service.trackingScriptsSrc[containerId]}?ts=${Service.sessionId}`;
      script.className = 'trackingScripts';
      script.async = true;
      script.onload = () => {
        Service.containerLoaded[containerId] = true;

        if (onLoaded) onLoaded();

        resolve();
      };
      script.onerror = (error) => {
        logger.error(`tracking::loadContainer::${containerId}`, error);
      };

      document.head.appendChild(script);
    });
  }

  static initAllContainers(callbacks: { [key: string]: () => void }): void {
    Object.keys(Service.trackingScriptsSrc).forEach((containerId) => {
      Service.loadContainer(containerId, callbacks[containerId]);
    });
  }

  static sendEvent(event: AnalyticsEvent): void {
    const containerId = event.container || Service.defaultContainer;

    if (!Service.containerLoaded[containerId]) {
      // Container not yet call load
      // In case call sendEvent without calling loadContainer
      if (!Service.containerLoadPromise[containerId]) {
        Service.containerLoadPromise[containerId] = Service.loadContainer(containerId).then(() => {
          Service.sendEvent(event);
        });
      } else {
        // container Loading
        Service.containerLoadPromise[containerId] =
          Service.containerLoadPromise[containerId]?.then(() => {
            Service.sendEvent(event);
          }) || null;
      }

      return;
    }

    const functionName: FunctionTrackersValueTypes | undefined = Service.functions.get(containerId);
    if (!functionName) {
      return;
    }
    if (Service.verbose) {
      logger.info(`[Tracking] Send "${event.type}" event`, event);
    }

    if (typeof window[functionName] === 'function') {
      try {
        window[functionName](Service, event.type, event.payload || {});
      } catch (err) {
        logger.error(`tracking::${event.type}`, err);
      }
    }
  }

  static updateDataLayer(state: Partial<DataLayerInterface>): DataLayerInterface {
    const dlKey = 'dataLayer';

    const dl = window.tc_vars || readLocalStorageJson<DataLayerInterface>(dlKey, DLInitialState);
    const updatedDL = { ...dl, ...state };
    window.tc_vars = updatedDL;
    try {
      localStorage.setItem(dlKey, JSON.stringify(updatedDL));
    } catch (error) {
      logger.error(`tracking::storage::${dlKey}`, error);
    }
    return updatedDL;
  }
}
