import environments from '../environments';
import { Country } from '../features/i18n/types';

export const camelize = (s: string) => s.replace(/[^a-zA-Z0-9]+(.)/g, (_, x: string) => x.toUpperCase());

const matchProductPage = (url: string) => {
  const regex = new RegExp('(/([a-z]|-)*){3,}[0-9]{5,}.shtml$');
  return regex.test(url);
};

const matchCatalogPage = (url: string) => {
  if (url.includes('.shtml')) {
    return false;
  }
  const regex = new RegExp('/((([a-z]|-)+)/)+');
  return regex.test(url);
};

export const getFenXOrigin = () => {
  return environments.env === 'dev' ? environments.mainDomain : window.location.origin;
};

export const previousShoppingPageHref = () => {
  const prevPage = document.referrer;
  const isProductPage = matchProductPage(prevPage);
  const isCatalogPage = matchCatalogPage(prevPage);
  const fenxOrigin = getFenXOrigin();
  return isProductPage || isCatalogPage ? document.referrer : fenxOrigin;
};

export const MIGRATION_SITE_INFO = {
  BE: {
    ffName: 'beSiteIdEnableStatus',
    oldSiteId: '1',
  },
  NL: {
    ffName: 'nlSiteIdEnableStatus',
    oldSiteId: '13',
  },
};

// Code for new site to fallback to old siteID
export const updateRegionIdIfNecessary = (
  countryList: Country[],
  newSiteIdEnableStatus: {
    [k in Country['isoCode']]?: boolean;
  }
) => {
  return countryList.map((item) => {
    const region = Object.assign({}, item);
    const regionCode = region.isoCode;

    // Be sure to use the original site id if the new site id is not enabled
    if (regionCode && newSiteIdEnableStatus[regionCode] !== true) {
      const siteInfo = MIGRATION_SITE_INFO[regionCode];

      if (siteInfo && siteInfo.oldSiteId) {
        region.idRegion = siteInfo.oldSiteId;
      }
    }

    return region;
  });
};
