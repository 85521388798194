import { call, takeLatest, ForkEffect } from 'redux-saga/effects';
import { ActionTypes, Actions } from './types';
import { Service } from './services';
import { AnalyticsEvent, DataLayerInterface, State } from './types';

function* setDataLayer({ payload }: Actions<Partial<DataLayerInterface>>) {
  yield call(Service.updateDataLayer, payload);
}

function* sendEvent({ payload }: Actions<AnalyticsEvent>) {
  yield call(Service.sendEvent, payload);
}

export function* sagas(): Generator<ForkEffect<Actions<State>>> {
  yield takeLatest(ActionTypes.SET_DL, setDataLayer);
  yield takeLatest(ActionTypes.SEND_EVENT, sendEvent);
}
