import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { camelize } from '../../helpers';

export const camelizeFlagsAndMap = (object: LDFlagSet) => {
  const flagsMaps: Record<string, string> = {};
  const camelizedObject = {};
  for (const kebabKey in object) {
    const camelizedKey = camelize(kebabKey);
    flagsMaps[camelizedKey] = kebabKey;
    camelizedObject[camelizedKey] = object[kebabKey];
  }
  return [camelizedObject, flagsMaps];
};

export const LDOptions = {
  evaluationReasons: true,
  autoAliasingOptOut: true,
  sendEventsOnlyForVariation: true,
  inlineUsersInEvents: true,
  sendLDHeaders: false,
};
