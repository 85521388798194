import {
  ApiResponse,
  RequestParams,
  RequestDraftListParams,
  SetItemParams,
  SetItemResponse,
  DraftListResponse,
} from './types';
import { Api } from '../../services/api';

class Service extends Api {
  getOrderList(params: RequestParams): Promise<ApiResponse> {
    return this.get<ApiResponse>('/orders/current/collection-points', {
      params,
    })
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  getDraftList(params: RequestDraftListParams): Promise<DraftListResponse> {
    return this.get<ApiResponse>('/collection-points?withSelected=true', {
      params,
    })
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  setItem(params: SetItemParams): Promise<SetItemResponse> {
    return this.post<SetItemResponse, Record<string, unknown>>(`/orders/current/collection-points`, {
      ...params,
      lastRelayUsedShouldBeReset: true,
    })
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }
}

export const api = new Service();
