import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';
import { getIsoLanguage } from './features/i18n/services';
import de from './i18n/de.json';
import en from './i18n/en.json';
import es from './i18n/es.json';
import fr from './i18n/fr.json';
import it from './i18n/it.json';
import us from './i18n/us.json';
import ko from './i18n/ko.json';
import hk from './i18n/hk.json';
import nl from './i18n/nl.json';
import sv from './i18n/sv.json';
import environments from './environments';

const LOCALES: { [key in string]: Resource } = {
  'de-DE': de,
  'en-GB': en,
  'es-ES': es,
  'fr-FR': fr,
  'it-IT': it,
  'en-US': us,
  'ko-KR': ko,
  'zh-HK': hk,
  'nl-NL': nl,
  'sv-SE': sv,
};

const resources: Resource = Object.keys(LOCALES).reduce(
  (prev: Resource, curr: string) => ({ ...prev, [curr]: { translation: LOCALES[curr] } }),
  {}
);

const i18nShopInstance = i18n.createInstance();

i18nShopInstance
  .use(initReactI18next)
  .use(ICU)
  .init({
    debug: environments.logLevel === 'debug' && process.env.NODE_ENV !== 'test',
    resources,
    lng: getIsoLanguage(),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18nShopInstance;
