import { IEnvironment } from './model';

const qaEnvironment: IEnvironment = {
  env: 'qa',
  logLevel: 'debug',
  apiBaseUrl: 'https://apiv2.qa.vestiairecollective.com',
  apiLegacyBaseUrl: 'https://api.qa.vestiairecollective.com',
  apiStaticBaseUrl: 'https://api-static-s3.qa.vestiairecollective.com',
  sessionCookie: 'EZBO_SESSION_vdclive',
  userIdCookie: 'vc_uid',
  cookieOptions: { domain: '.vestiairecollective.com', path: '/' },
  i18nCookie: 'vc_ck',
  imagesPath: 'https://images.qa.vestiairecollective.com/images/resized',
  assetsPath: 'https://assets.qa.vestiairecollective.com',
  mainDomain: 'https://www.qa.vestiairecollective.com',
  analytics: { envName: 'qa', verbose: true },
  cookieAnonymousUser: 'nl_user',
  cookieRandomId: 'random_range_id',
  launchDarkly: {
    sdkKey: 'sdk-eb1d3094-9f2c-46f4-8b62-5450089c4c8e',
    clientId: '612f6859878959262228ba9b',
  },
  google: {
    maps: 'AIzaSyBO4EOhk_89Qgy8XdfL_iWky8dLiI-4c7Y',
  },
  payment: {
    adyen: {
      environment: 'test',
      clientKey: 'test_2RZCD443UJELPB45WUIEVW3LS4YB6ZVT',
      analytics: false,
    },
    braintree: {
      clientId: 'AdXGmXPWJ-TWD8V-wLe_4qKuvnkPzuhGHJ0ACxZAcICjWHyzVCNLBLWJ10p_OGyjmPHbXTW4ksWufEux',
    },
  },
  svgSprite: '/assets/icons/sprite.svg',
  svgPath: '/assets/icons/svg-icons',
};

export default qaEnvironment;
