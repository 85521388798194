import { call, takeLatest, ForkEffect, put, CallEffect } from 'redux-saga/effects';
import { getListResolve, getListReject, selectReject } from './actions';
import { uiActions } from '../ui';
import { setVcckCookie, setVcccCookie, getLocalisationOptions, getIsoLanguage } from './services';
import logger from '../../services/logger.service';
import { Actions, ActionTypes, Dictionary, InferredAction } from './types';
import { authServices } from '../auth';
import i18n from '../../i18n';

function* set({ payload }: InferredAction<ActionTypes.SET_I18N>): Generator<CallEffect<void>> {
  try {
    const { siteId, language, currency, country } = payload || {};
    yield call<(...args: any) => void>(setVcckCookie, siteId, language, currency);
    yield call<(siteId: any) => void>(setVcccCookie, {
      CC: country,
      displayName: {},
    });
    i18n.changeLanguage(getIsoLanguage());
  } catch (e) {
    logger.error('i18n::set', e);
  }
}

function* getList({ payload, meta }: InferredAction<ActionTypes.GET_I18N_LIST_REQUEST>) {
  try {
    const i18nList: Dictionary = yield call(getLocalisationOptions, payload);
    if (i18nList) {
      yield put(getListResolve(i18nList, meta));
    }
  } catch (e) {
    logger.error('i18n::getList', e);
    yield put(getListReject(undefined, meta));
  }
}

function* selectRequest({ payload }: InferredAction<ActionTypes.SELECT_I18N_REQUEST>) {
  try {
    yield put(uiActions.setCheckoutOverlay(true));
    yield call([authServices, 'updateSettings'], payload);
    window.location.reload();
  } catch (error) {
    yield put(selectReject());
    yield put(uiActions.setCheckoutOverlay(false));
    logger.error('i18n::selectRequest', error);
  }
}

export function* sagas(): Generator<ForkEffect<Actions>> {
  yield takeLatest(ActionTypes.SET_I18N, set);
  yield takeLatest(ActionTypes.SELECT_I18N_REQUEST, selectRequest);
  yield takeLatest(ActionTypes.GET_I18N_LIST_REQUEST, getList);
}
