import { takeLatest, call, put, ForkEffect, select, CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import {
  adyenHandleResultCode,
  cancelPayment,
  initGenericGatewayReject,
  initGenericGatewayRequest,
  initNonGenericGatewayRequest,
  initGenericGatewayResolve,
  refusePayment,
  rejectOrderPaymentMethods,
  rejectPayment,
  rejectSelectPaymentMethod,
  resolveOrderPaymentMethods,
  resolvePayment,
  resolveGateway,
  resolveSelectPaymentMethod,
  selectPaymentMethodRequest,
  initNonGenericGatewayReject,
  initNonGenericGatewayResolve,
  resolveAdyenConfirmation,
  rejectAdyenConfirmation,
  resolveAdyenRedirectionData,
  rejectAdyenRedirectionData,
} from './actions';
import logger from '../../services/logger.service';
import {
  api,
  apiMonolith,
  generateOrderConfirmationUrl,
  getResultCodeForCheckoutPage,
  getReturnedUrlDetails,
} from './services';
import {
  AdyenCheckout,
  AdyenPayGenericResponse,
  AdyenResultCodes,
  GatewayEnums,
  GatewayInfo,
  OrderPaymentMethod,
  ActionTypes,
  ErrorPopinStatus,
  InferedActions,
  AdyenConfirmGenericResponse,
} from './types';
import { cartSelectors, CartTypes } from '../cart';
import { SubtotalsTypes, subtotalsSelectors } from '../subtotals';
import { getAdyenCheckoutInstance } from './selectors';
import toaster from '../../helpers/toaster';
import { TrackingTypes } from '../tracking';
import { trackingSelectors } from '../tracking';
import { paymentSelectors, PaymentTypes } from '.';
import { highlightPaymentMethodUpdate, setCheckoutOverlay, setRedirectToCheckoutError } from '../ui/actions';
import { voucherSelectors } from '../voucher';
import { VoucherOptionToCheck, VoucherOptionsState } from '../voucher/types';
import { unStackOptionToCheck } from '../voucher/actions';

export function* fetchOrderPaymentMethods(): Generator<CallEffect | PutEffect, void, OrderPaymentMethod[]> {
  try {
    const res = yield call([api, 'getOrderPaymentMethods']);
    yield put(resolveOrderPaymentMethods(res));
  } catch (err) {
    yield put(rejectOrderPaymentMethods());
    logger.error('payment::fetchOrderPaymentMethods', err);
  }
}

export function* setSelectedPaymentMethod(
  action: InferedActions<ActionTypes.SELECT_PAYMENT_METHOD_REQUEST>
): Generator<CallEffect | PutEffect, void, OrderPaymentMethod> {
  try {
    yield call([api, 'setSelectedPaymentMethod'], action.payload.internalRef, action.payload.userCard?.remoteRef);
    yield put(resolveSelectPaymentMethod(action.payload));
  } catch (err) {
    yield put(rejectSelectPaymentMethod());
    logger.error('payment::selectedPaymentMethod', err);
  }
}

export function* initGateway({ payload }: InferedActions<ActionTypes.SELECT_PAYMENT_METHOD_RESOLVE>) {
  // we use a generic process to handle braintree and adyen PSP
  if ([GatewayEnums.BRAINTREE, GatewayEnums.ADYEN].includes(payload.pspName as GatewayEnums)) {
    yield put(initGenericGatewayRequest(payload.pspName));
  } else if ([GatewayEnums.AFFIRM, GatewayEnums.SPLITIT].includes(payload.pspName as GatewayEnums)) {
    yield put(initNonGenericGatewayRequest(payload.pspName));
  }
}

export function* initGenericGateway({
  payload: paymentGateway,
}: InferedActions<ActionTypes.INIT_GENERIC_GATEWAY_REQUEST>) {
  let res: GatewayInfo = { test: 'initialData' } as unknown as GatewayInfo; // for logging purpose
  try {
    res = yield call([api, 'initPaymentMethod'], paymentGateway);
    yield put(initGenericGatewayResolve(res));
  } catch (e) {
    yield put(initGenericGatewayReject());
    logger.error(`payment::initGenericGateway::${paymentGateway}`, JSON.stringify({ error: e, response: res }));
    toaster.error(`failed to init payment method with ${paymentGateway}`);
  }
}

export function* initNonGenericGateway({
  payload: paymentGateway,
}: InferedActions<ActionTypes.INIT_NON_GENERIC_GATEWAY_REQUEST>): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  TrackingTypes.DataLayerInterface | CartTypes.State | string
> {
  try {
    const dataLayer = (yield select(trackingSelectors.dataLayer)) as TrackingTypes.DataLayerInterface;
    const cart = (yield select(cartSelectors.getRoot)) as CartTypes.State;
    const res: string = (yield call([apiMonolith, 'initNonGenericGateway'], {
      id_c: cart.id,
      orderId: cart.id,
      orderAmount: btoa(encodeURIComponent(cart.totalAmount.formatted)),
      dl: btoa(
        JSON.stringify({
          order_amount_ati_with_sf: dataLayer.order_amount_ati_with_sf,
          order_amount_ati_without_sf: dataLayer.order_amount_ati_without_sf,
          pageUrl: dataLayer.order_confirmation_pageUrl,
          basket_total_value: dataLayer.basket_total_value,
        })
      ),
    })) as string;
    yield put(initNonGenericGatewayResolve(res));
  } catch (e) {
    yield put(initNonGenericGatewayReject());
    logger.error(`payment::initNonGenericGateway::${paymentGateway}`, e);
  }
}

export function* resolveGatewaySaga() {
  yield put(resolveGateway());
}

export function* payAdyenGenericRequest({
  payload,
}: InferedActions<ActionTypes.PAY_ADYEN_GENERIC_REQUEST>): Generator<
  CallEffect | SelectEffect | PutEffect,
  void,
  AdyenPayGenericResponse | AdyenCheckout
> {
  try {
    const res = (yield call([api, 'processAdyenGenericPayment'], payload)) as AdyenPayGenericResponse;
    if (res.meta.action) {
      // execute the redirection
      const adyenCheckout = (yield select(getAdyenCheckoutInstance)) as AdyenCheckout;
      adyenCheckout.createFromAction(res.meta.action).mount('#adyenRoot');
    } else {
      yield put(adyenHandleResultCode({ adyenResponse: res }));
    }
  } catch (error) {
    yield put(rejectPayment({ message: ErrorPopinStatus.ERROR }));
    logger.error('payment::processAdyenGenericPayment', error);
  }
}

export function* payNonGenericRequest({ payload }: InferedActions<ActionTypes.PAY_NON_GENERIC_REQUEST>) {
  window.location.assign(payload);
}

export function* payAdyenCreditCardRequest({ payload }: InferedActions<ActionTypes.PAY_ADYEN_CREDIT_CARD_REQUEST>) {
  try {
    const res: AdyenPayGenericResponse = yield call([api, 'processAdyenCreditCardPayment'], payload);
    yield put(adyenHandleResultCode({ adyenResponse: res }));
  } catch (error) {
    yield put(rejectPayment({ message: null }));
    logger.error('payment::processAdyenCreditCardPayment', error);
  }
}

export function* handleAdyenPaymentResultCode({
  payload,
}: InferedActions<ActionTypes.PAY_ADYEN_HANDLE_RESULT_CODE>): Generator<
  SelectEffect | PutEffect,
  void,
  AdyenCheckout | SubtotalsTypes.State | CartTypes.State
> {
  switch (payload.adyenResponse.meta.resultCode) {
    case AdyenResultCodes.REDIRECT_SHOPPER:
      const adyenCheckout = (yield select(getAdyenCheckoutInstance)) as AdyenCheckout;
      adyenCheckout.create('redirect', payload.adyenResponse.meta.redirect).mount('#adyenRoot');
      break;
    case AdyenResultCodes.CANCELLED:
      yield put(cancelPayment());
      break;
    case AdyenResultCodes.RECEIVED:
    case AdyenResultCodes.PENDING:
    case AdyenResultCodes.AUTHORIZED:
      let orderId = payload.orderId;
      if (!orderId) {
        const cart = (yield select(cartSelectors.getRoot)) as CartTypes.State;
        orderId = cart.id;
      }
      let formattedAmount = payload.orderAmount;
      if (!formattedAmount) {
        const subtotals = (yield select(subtotalsSelectors.getRoot)) as SubtotalsTypes.State;
        formattedAmount = subtotals.totalAmount.formatted;
      }
      yield put(
        resolvePayment({
          formattedAmount,
          orderId,
        })
      );
      break;
    case AdyenResultCodes.REFUSED:
      yield put(refusePayment({ message: payload.adyenResponse.meta.paymentErrorMessage }));
      break;
  }
}

export function* resolveSagaPayment({ payload }: InferedActions<ActionTypes.RESOLVE_PAYMENT>) {
  const dataLayer: TrackingTypes.DataLayerInterface = yield select(trackingSelectors.dataLayer);
  window.location.assign(
    generateOrderConfirmationUrl(payload.orderId, payload.formattedAmount, {
      order_amount_ati_with_sf: dataLayer.order_amount_ati_with_sf,
      order_amount_ati_without_sf: dataLayer.order_amount_ati_without_sf,
      pageUrl: dataLayer.order_confirmation_pageUrl,
      basket_total_value: dataLayer.basket_total_value,
    })
  );
}

export function* setOrderPaymentMethodAfterFetchPaymentMethods({
  payload,
}: InferedActions<ActionTypes.FETCH_ORDER_PAYMENT_METHODS_RESOLVE>) {
  const selectedPaymentMethod = payload.filter((x) => x.isSelected);
  const updateToThisPaymentMethod = selectedPaymentMethod.length ? selectedPaymentMethod[0] : payload[0];
  const voucherCheckOptions = (yield select(voucherSelectors.getCheckOptionsStatus)) as VoucherOptionsState | undefined;
  const needPaymentMethodUpdateAlert =
    voucherCheckOptions?.status === 'PEND_CHECK_LIST' &&
    voucherCheckOptions.stack.includes(VoucherOptionToCheck.PAYMENT_METHOD) &&
    voucherCheckOptions[VoucherOptionToCheck.PAYMENT_METHOD] !== updateToThisPaymentMethod.mnemonic;
  // show alert
  yield put(highlightPaymentMethodUpdate(needPaymentMethodUpdateAlert));
  // update voucher check stack
  yield put(unStackOptionToCheck(VoucherOptionToCheck.PAYMENT_METHOD));
  yield put(selectPaymentMethodRequest(updateToThisPaymentMethod));
}

export function* getAdyenRedirectionData({ payload }: InferedActions<ActionTypes.GET_ADYEN_REDIRECTION_DATA>) {
  const paymentId = payload.get('paymentId') || '';
  const orderId = payload.get('orderId') || '';
  const rawOrderAmount = payload.get('orderAmount') || '';
  const orderAmount = rawOrderAmount ? decodeURIComponent(atob(rawOrderAmount)) : '';
  const formattedDetails = getReturnedUrlDetails(payload);

  if (!paymentId || !orderId || !orderAmount || !formattedDetails) {
    logger.error('payment::confirmation::redirectionData', {
      urlSearchParams: payload,
      paymentId,
      orderId,
      orderAmount,
      formattedDetails,
    });
    yield put(rejectAdyenRedirectionData());
  } else {
    yield put(
      resolveAdyenRedirectionData({
        paymentId,
        orderId,
        orderAmount,
        formattedDetails,
      })
    );
  }
}

export function* initAdyenConfirmationSaga() {
  const redirectionData = (yield select(paymentSelectors.getAdyenRedirectionData)) as PaymentTypes.AdyenRedirectionData;
  try {
    const res: AdyenConfirmGenericResponse = yield call([api, 'processAdyenGenericConfirm'], {
      paymentId: redirectionData.paymentId,
      details: redirectionData.formattedDetails,
    });
    yield put(resolveAdyenConfirmation(res));
  } catch (error) {
    logger.error(`payment::confirmation::adyen`, {
      error: error,
      redirectionData: redirectionData,
    });
    yield put(rejectAdyenConfirmation());
    yield put(setCheckoutOverlay(false));
    yield put(setRedirectToCheckoutError(PaymentTypes.CheckoutPathResultCode.ERROR));
  }
}

export function* resolveAdyenConfirmationSaga({
  payload,
}: InferedActions<ActionTypes.INIT_ADYEN_CONFIRMATION_RESOLVE>) {
  const redirectionData = (yield select(paymentSelectors.getAdyenRedirectionData)) as PaymentTypes.AdyenRedirectionData;

  if (!payload.meta.resultCode) {
    logger.error(`payment::confirmation::noResultCode`, {
      payload: payload,
    });
  }

  // handle the success result code
  if (
    [
      PaymentTypes.AdyenResultCodes.AUTHORIZED,
      PaymentTypes.AdyenResultCodes.RECEIVED,
      PaymentTypes.AdyenResultCodes.PENDING,
    ].includes(payload.meta.resultCode)
  ) {
    yield put(
      adyenHandleResultCode({
        adyenResponse: payload,
        orderId: redirectionData.orderId,
        orderAmount: redirectionData.orderAmount,
      })
    );
  }

  // handle the other results code such as cancel and error
  // in that case go back to checkout to allow our user to make a new attempt
  else {
    yield put(rejectAdyenConfirmation());
    yield put(setRedirectToCheckoutError(getResultCodeForCheckoutPage(payload.meta.resultCode)));
    yield put(setCheckoutOverlay(false));
  }
}

export function* sagas(): Generator<ForkEffect<ActionTypes>> {
  yield takeLatest(ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REQUEST, fetchOrderPaymentMethods);
  yield takeLatest(ActionTypes.SELECT_PAYMENT_METHOD_REQUEST, setSelectedPaymentMethod);
  yield takeLatest(ActionTypes.INIT_GENERIC_GATEWAY_REQUEST, initGenericGateway);
  yield takeLatest(ActionTypes.INIT_GENERIC_GATEWAY_RESOLVE, resolveGatewaySaga);
  yield takeLatest(ActionTypes.PAY_ADYEN_GENERIC_REQUEST, payAdyenGenericRequest);
  yield takeLatest(ActionTypes.PAY_ADYEN_CREDIT_CARD_REQUEST, payAdyenCreditCardRequest);
  yield takeLatest(ActionTypes.PAY_ADYEN_HANDLE_RESULT_CODE, handleAdyenPaymentResultCode);
  yield takeLatest(ActionTypes.RESOLVE_PAYMENT, resolveSagaPayment);
  yield takeLatest(ActionTypes.FETCH_ORDER_PAYMENT_METHODS_RESOLVE, setOrderPaymentMethodAfterFetchPaymentMethods);
  yield takeLatest(ActionTypes.SELECT_PAYMENT_METHOD_RESOLVE, initGateway);
  yield takeLatest(ActionTypes.INIT_NON_GENERIC_GATEWAY_REQUEST, initNonGenericGateway);
  yield takeLatest(ActionTypes.PAY_NON_GENERIC_REQUEST, payNonGenericRequest);
  yield takeLatest(ActionTypes.GET_ADYEN_REDIRECTION_DATA, getAdyenRedirectionData);
  yield takeLatest(ActionTypes.INIT_ADYEN_CONFIRMATION_REQUEST, initAdyenConfirmationSaga);
  yield takeLatest(ActionTypes.INIT_ADYEN_CONFIRMATION_RESOLVE, resolveAdyenConfirmationSaga);
}
