import { CartTypes } from '.';
import { Price } from '../../helpers/money';
import type { RootState } from '../../store';
import { State, Errors, StatusEnum, DeliveryMethods } from './types';

export const getRoot = (state: RootState): State => state.cart;
export const getId = (state: RootState): string => state.cart.id;
export const getError = (state: RootState): Errors => state.cart.errors;
export const getOrderLineIds = (state: RootState): string[] | undefined => state.cart.orderLines;
export const getStatus = (state: RootState) => state.cart.status as StatusEnum;
export const isFetchCollectionPointsEligible = ({ cart }: RootState): boolean => !!cart.isElligibleToCollectionPoint;
export const getDeliveryMethod = (state: RootState): DeliveryMethods =>
  state.cart.deliverySelectedMethod as DeliveryMethods;
export const getTotalAmount = (state: RootState) => state.cart.totalAmount || new Price();
export const getIsCartEmpty = (state: RootState) =>
  state.cart.status !== CartTypes.StatusEnum.IDLE &&
  state.cart.status !== CartTypes.StatusEnum.PEND_GET_LIST &&
  !state.cart.orderLines?.length;
