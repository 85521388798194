import React, { ComponentProps, forwardRef, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { PopupActions, PopupProps } from 'reactjs-popup/dist/types';
import { useTranslation } from 'react-i18next';

import 'reactjs-popup/dist/index.css';
import './Modal.scss';

import Icon from '../Icons/Icons';

type PropsTypes = ComponentProps<'div'> & {
  trigger?: PopupProps['trigger'];
  children: React.ReactNode;
  open?: boolean;
  onModalClose?: (e: CustomEvent) => void;
  onModalOpen?: (e: CustomEvent) => void;
  customClass?: string;
  mobileFullScreen?: boolean;
};

const Modal = forwardRef<PopupActions, PropsTypes>(
  (
    { trigger, children, open, onModalClose, onModalOpen, customClass, mobileFullScreen = true, ...props }: PropsTypes,
    ref?
  ): JSX.Element => {
    const { t } = useTranslation();
    const modal = useRef({} as PopupActions);
    const outerReference = ref as MutableRefObject<PopupActions>;

    const closeModal = () => {
      if (outerReference) {
        outerReference.current.close();
      } else {
        modal.current.close();
      }
    };

    const handleUserKeyPress = useCallback((ev) => {
      // only execute if tab is pressed
      if (ev.key !== 'Tab') return;
      const vcModal = document.querySelector('.vc-modal');
      // here we query all focusable elements in the modal -> customised as our own need ['a[href], button:not([disabled]), textarea, input, select']
      const focusableElements: NodeListOf<HTMLElement> = document.querySelectorAll(
        `.vc-modal button:not([disabled]), .vc-modal a[href]:not([disabled])`
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];
      const { activeElement } = document;

      if (!vcModal?.contains(activeElement)) {
        ev.preventDefault();
        lastElement.focus();
      }
      if (activeElement === lastElement) {
        ev.preventDefault();
        firstElement.focus();
      }
      // if going backward by pressing tab and firstElement is active shift focus to last focusable element
      if (ev.shiftKey && activeElement === firstElement) {
        lastElement.focus();
        ev.preventDefault();
      }
    }, []);

    const onClose = () => {
      window.removeEventListener('keydown', handleUserKeyPress);
      onModalClose?.({} as CustomEvent);
    };

    const onOpen = () => {
      window.addEventListener('keydown', handleUserKeyPress);
      onModalOpen?.({} as CustomEvent);
    };

    // sometimes we need to remove the popup from React render tree so it has not been well handled in the react-popup library.
    // https://github.com/yjose/reactjs-popup/blob/master/src/index.tsx#L85
    useEffect(() => {
      return () => {
        document.body.style.overflow = '';
      };
    });

    return (
      <Popup
        ref={outerReference || modal}
        className={`${customClass}${mobileFullScreen ? ' mobileFullScreen' : ''}`}
        trigger={trigger}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        modal
        lockScroll
        closeOnDocumentClick
      >
        <div className="vc-modal">
          <button
            type="button"
            aria-label="close dialog modal"
            className="vc-dialog__closeBtn"
            onClick={closeModal}
            data-testid={`modal_${props.id}_close`}
          >
            <span className="vc-dialog__closeBtn__text visuallyhidden">{t('MODAL.CLOSE')}</span>
            <Icon name="cross-x" classes="vc-dialog__closeBtn__icon" />
          </button>
          {children}
        </div>
      </Popup>
    );
  }
);

Modal.defaultProps = {
  trigger: <></>,
  open: false,
  onModalClose: () => {},
  onModalOpen: () => {},
  customClass: '',
};

export default Modal;
