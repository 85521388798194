import { SetAddRequest, SetRemoveRequest } from './types';
import { Api } from '../../services/api';

class Service extends Api {
  set(data: SetAddRequest): Promise<null> {
    return this.post('/orders/current/voucher', data)
      .then(() => null)
      .catch((e) => {
        throw e;
      });
  }

  remove(data: SetRemoveRequest): Promise<null> {
    return this.delete('/orders/current/voucher', data)
      .then(() => null)
      .catch((e) => {
        throw e;
      });
  }
}

export default new Service();
