import { ActionTypes, Actions, OptionsStatusEnum, State, StatusEnum, VoucherOptionToCheck } from './types';

export const initialState: State = {
  status: StatusEnum.IDLE,
};

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_VOUCHER_REQUEST: {
      return {
        ...state,
        code: action.payload.code,
        error: undefined,
        status: StatusEnum.PEND_SET_ITEM,
      };
    }
    case ActionTypes.SET_VOUCHER_RESOLVE: {
      return {
        ...state,
        error: undefined,
        code: action.payload.code,
        status: StatusEnum.OK_SET_ITEM,
        checkCartOptions: state.checkCartOptions,
      };
    }
    case ActionTypes.SET_VOUCHER_REJECT: {
      return {
        ...state,
        error: action.payload,
        status: StatusEnum.ERROR_SET_ITEM,
      };
    }
    case ActionTypes.REMOVE_VOUCHER_REQUEST: {
      return {
        ...state,
        status: StatusEnum.PEND_DELETE_ITEM,
      };
    }
    case ActionTypes.REMOVE_VOUCHER_RESOLVE: {
      return {
        ...state,
        code: undefined,
        error: undefined,
        status: StatusEnum.OK_DELETE_ITEM,
        checkCartOptions: state.checkCartOptions,
      };
    }
    case ActionTypes.REMOVE_VOUCHER_REJECT: {
      return {
        ...state,
        status: StatusEnum.ERROR_DELETE_ITEM,
      };
    }
    case ActionTypes.STACK_CHECK_LIST_OPTIONS: {
      return {
        ...state,
        status: state.status,
        checkCartOptions: {
          ...action.payload,
          status: OptionsStatusEnum.PEND_CHECK_LIST,
          stack: Object.keys(action.payload) as VoucherOptionToCheck[],
        },
      };
    }
    case ActionTypes.UNSTACK_CHECK_LIST_OPTION: {
      const stack = state.checkCartOptions?.stack.filter((e) => e !== action.payload);
      return {
        ...state,
        status: state.status,
        checkCartOptions:
          state.checkCartOptions && stack?.length
            ? {
                ...state.checkCartOptions,
                status: OptionsStatusEnum.PEND_CHECK_LIST,
                stack: stack,
                [action.payload]: undefined,
              }
            : undefined,
      };
    }
    default:
      return state;
  }
};
