import {
  SetRemoveRequest,
  Actions,
  ActionTypes,
  VoucherCurrentCartOptions,
  VoucherOptionToCheck,
  SetResolveRequest,
} from './types';

export const setRequest = (payload: SetRemoveRequest): Actions => ({
  type: ActionTypes.SET_VOUCHER_REQUEST,
  payload,
});

export const setResolve = (payload: SetResolveRequest): Actions => ({
  type: ActionTypes.SET_VOUCHER_RESOLVE,
  payload,
});

export const setReject = (payload: string): Actions => ({
  type: ActionTypes.SET_VOUCHER_REJECT,
  payload,
});

export const removeRequest = (payload: SetRemoveRequest): Actions => ({
  type: ActionTypes.REMOVE_VOUCHER_REQUEST,
  payload,
});

export const removeResolve = (): Actions => ({
  type: ActionTypes.REMOVE_VOUCHER_RESOLVE,
});

export const removeReject = (): Actions => ({
  type: ActionTypes.REMOVE_VOUCHER_REJECT,
});

export const stackOptionsToCheck = (payload: VoucherCurrentCartOptions): Actions => ({
  type: ActionTypes.STACK_CHECK_LIST_OPTIONS,
  payload,
});

export const unStackOptionToCheck = (payload: VoucherOptionToCheck): Actions => ({
  type: ActionTypes.UNSTACK_CHECK_LIST_OPTION,
  payload,
});
