import {
  Actions,
  ActionTypes,
  ApiResponse,
  RequestParams,
  DraftSelected,
  DraftAddress,
  MetaData,
  SetItemParams,
  SetItemResponse,
  RequestDraftListParams,
  DraftListResponse,
} from './types';

export const fetchListRequest = (payload: RequestParams, meta?: MetaData): Actions => ({
  type: ActionTypes.FETCH,
  meta,
  payload,
});
export const fetchListResolve = (payload: ApiResponse, meta?: MetaData): Actions => ({
  type: ActionTypes.RESOLVE,
  meta,
  payload,
});
export const fetchListReject = (payload: string, meta?: MetaData): Actions => ({
  type: ActionTypes.REJECT,
  meta,
  payload,
});

export const setItemDraftSelected = (payload: DraftSelected): Actions => ({
  type: ActionTypes.SET_DRAFT_SELECTED,
  payload,
});

export const setDraftAddress = (payload: DraftAddress): Actions => ({
  type: ActionTypes.SET_DRAFT_ADDRESS,
  payload,
});

export const fetchDraftListRequest = (payload: RequestDraftListParams, meta?: MetaData): Actions => ({
  type: ActionTypes.FETCH_DRAFT,
  meta,
  payload,
});
export const fetchDraftListResolve = (payload: DraftListResponse, meta?: MetaData): Actions => ({
  type: ActionTypes.RESOLVE_DRAFT,
  meta,
  payload,
});
export const fetchDraftListReject = (payload: string, meta?: MetaData): Actions => ({
  type: ActionTypes.REJECT_DRAFT,
  meta,
  payload,
});

export const setItemRequest = (payload: SetItemParams, meta?: MetaData): Actions => ({
  type: ActionTypes.SET_COLLECTION_POINT_REQUEST,
  payload,
  meta,
});
export const setItemResolve = (payload: SetItemResponse): Actions => ({
  type: ActionTypes.SET_COLLECTION_POINT_RESOLVE,
  payload,
});
export const setItemReject = (payload: string): Actions => ({
  type: ActionTypes.SET_COLLECTION_POINT_REJECT,
  payload,
});
