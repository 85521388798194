import { Entities } from '@vestiaire/api-specification';
import { Action, InferAction, WithStatusType } from '../../types/index';
import { OrderLinesTypes } from '../order-lines';
import { SubtotalsTypes } from '../subtotals';

export type Errors = Partial<{
  soldItems: string[];
  billingAddress: boolean;
  prohibitedItems: string[];
}>;

export type RemoveOrderLinePayloadType = { productIds: string; orderLineIds: string };

export type Response = Entities.Order;
export type CheckResponse = Entities.OrderCheckCart;

export enum DeliveryMethods {
  HOME = 'home',
  COLLECTION_POINT = 'collection-point',
}

export type TransformOrder = {
  orderLines: OrderLinesTypes.State;
  subTotals: SubtotalsTypes.State;
  cart: State;
};

export enum StatusEnum {
  IDLE = 'IDLE',
  OK_GET_LIST = 'OK_GET_LIST',
  PEND_GET_LIST = 'PEND_GET_LIST',
  ERROR_GET_LIST = 'ERROR_GET_LIST',
  PEND_CHECK_LIST = 'PEND_CHECK_LIST',
  OK_CHECK_LIST = 'OK_CHECK_LIST',
  ERROR_CHECK_LIST = 'ERROR_CHECK_LIST',
  ERROR_REASON_NO_BILLING_ADDRESS = 'ERROR_REASON_NO_BILLING_ADDRESS',
  ERROR_REASON_SOLD_PRODUCTS_IN_BASKET = 'ERROR_REASON_SOLD_PRODUCTS_IN_BASKET',
  ERROR_REASON_PROHIBITED_PRODUCTS_IN_BASKET = 'ERROR_REASON_PROHIBITED_PRODUCTS_IN_BASKET',
}

export type State = Omit<Response, 'orderLines'> &
  WithStatusType & {
    orderLines: string[];
    errors: Errors;
  };

export enum ActionTypes {
  FETCH = 'cart/fetch',
  REJECT = 'cart/rejectFetch',
  RESOLVE = 'cart/resolveFetch',
  RETRY = 'cart/retryFetch',
  SET = 'cart/set',
  SET_ERROR = 'cart/setError',
  REMOVE_LINE_ITEM = 'cart/removeLineItem',
  ADD_LINE_ITEM = 'cart/addLineItem',
  SET_DELIVERY_METHOD = 'cart/setDeliveryMethod',
  CHECK_CART_REQUEST = 'cart/check/request',
  CHECK_CART_RESOLVE = 'cart/check/resolve',
  CHECK_CART_REJECT = 'cart/check/reject',
  CHECK_CART_RESET = 'cart/check/reset',
  SET_NO_BILLING_ADDRESS = 'cart/check/noBillingAddress',
  SET_PROHIBITED_PRODUCTS_IN_BASKET = 'cart/check/prohibitedProductInBasket',
  SET_SOLD_PRODUCTS_IN_BASKET = 'cart/check/soldProductInBasket',
}

export type PayloadTypes = {
  [ActionTypes.FETCH]: undefined;
  [ActionTypes.RESOLVE]: Response;
  [ActionTypes.REJECT]: string;
  [ActionTypes.SET]: State;
  [ActionTypes.REMOVE_LINE_ITEM]: RemoveOrderLinePayloadType;
  [ActionTypes.ADD_LINE_ITEM]: string;
  [ActionTypes.SET_ERROR]: Errors;
  [ActionTypes.SET_DELIVERY_METHOD]: DeliveryMethods;
  [ActionTypes.CHECK_CART_REQUEST]: undefined;
  [ActionTypes.CHECK_CART_RESOLVE]: undefined;
  [ActionTypes.CHECK_CART_REJECT]: undefined;
  [ActionTypes.CHECK_CART_RESET]: undefined;
  [ActionTypes.SET_NO_BILLING_ADDRESS]: boolean;
  [ActionTypes.SET_PROHIBITED_PRODUCTS_IN_BASKET]: string[];
  [ActionTypes.SET_SOLD_PRODUCTS_IN_BASKET]: string[];
};

export type Actions = Action<PayloadTypes>[keyof Action<PayloadTypes>];
export type InferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T>;
