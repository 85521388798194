import { useAppSelector } from '../../app/hooks';
import { bucketTestSelectors } from '.';
import { LDEvaluationDetail, LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BucketTestTypes } from '.';
import { useEffect, useRef } from 'react';
import logger from '../../services/logger.service';
import environments from '../../environments';

type FeatureSelector = (x: LDFlagSet) => boolean;
type GetFeatureSelector = (flagName: string, defaultValue?: LDFlagValue) => LDEvaluationDetail;

export const useBucket = () => {
  const status = useAppSelector(bucketTestSelectors.getStatus);
  const client = useAppSelector(bucketTestSelectors.getClient);
  const keyMaps = useAppSelector(bucketTestSelectors.getKeyMaps);
  const user = useAppSelector(bucketTestSelectors.getUser);
  const touchedFlags = useRef({});
  const allFlagsProxy = new Proxy(
    {},
    {
      get: (_, prop) => {
        if (!keyMaps || !client) return undefined;
        if (status !== BucketTestTypes.StatusEnum.OK_INIT_ITEM) return undefined;
        const value = client?.allFlags()[keyMaps[String(prop)]];
        touchedFlags.current = { ...touchedFlags.current, [prop]: value };
        return value;
      },
    }
  );

  const variationsProxy = new Proxy(
    {},
    {
      get: (_, prop) => {
        if (!keyMaps || !client) return undefined;
        if (status !== BucketTestTypes.StatusEnum.OK_INIT_ITEM) return undefined;
        const value = client?.variationDetail(keyMaps[String(prop)]).value;
        touchedFlags.current = { ...touchedFlags.current, [prop]: value };
        return value;
      },
    }
  );

  const isFeatureEnabled = (fs: FeatureSelector, sendEvent = false) =>
    keyMaps ? (sendEvent ? fs(variationsProxy) : fs(allFlagsProxy)) : undefined;

  const getFeature: GetFeatureSelector = (flagName, sendEvent = false) =>
    (keyMaps && client
      ? sendEvent
        ? client.variationDetail(keyMaps[flagName], undefined)
        : { value: allFlagsProxy[flagName], reason: { kind: undefined } }
      : { value: undefined, reason: { kind: undefined } }) as LDEvaluationDetail;

  useEffect(() => {
    if (status !== BucketTestTypes.StatusEnum.OK_INIT_ITEM || !user.key) return;
    try {
      window.DD_RUM.setGlobalContextProperty('anonymous_id', user.custom?.anonymous_id);
      window.DD_RUM.setGlobalContextProperty('user_id', user.key);
      window.DD_RUM.setGlobalContextProperty('ld_feature_flags', {
        ...window.DD_RUM.touchedFlags,
        ...touchedFlags.current,
      });
    } catch {
      if (environments.env !== 'dev') logger.error('datadog::addRumGlobalContextError');
    }
    // eslint-disable-next-line
  }, [status, user, touchedFlags.current]);

  return {
    status,
    isFeatureEnabled,
    getFeature,
  };
};
