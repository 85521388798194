import { State, Actions, ActionTypes, GatewayEnums, StatusEnums, AdyenRedirectionStatus } from './types';

export const initialState: State = {
  orderPaymentMethods: {
    status: StatusEnums.IDLE,
    items: [],
  },
  selectedPayment: {
    status: StatusEnums.IDLE,
    current: 0,
    previous: 0,
  },
  gatewayService: {
    status: StatusEnums.IDLE,
    name: undefined,
    genericGateway: {
      status: StatusEnums.IDLE,
    },
    nonGenericGateway: {
      status: StatusEnums.IDLE,
    },
    adyenCheckout: undefined,
  },
  paymentStatus: StatusEnums.IDLE,
  adyenRedirection: {
    status: AdyenRedirectionStatus.IDLE,
  },
};

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        orderPaymentMethods: {
          ...state.orderPaymentMethods,
          status: StatusEnums.PEND_GET_LIST,
        },
      };
    case ActionTypes.FETCH_ORDER_PAYMENT_METHODS_RESOLVE:
      return {
        ...state,
        orderPaymentMethods: {
          status: StatusEnums.OK_GET_LIST,
          items: action.payload,
        },
      };
    case ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REJECT:
      return {
        ...state,
        orderPaymentMethods: {
          ...state.orderPaymentMethods,
          status: StatusEnums.ERROR_GET_LIST,
        },
      };
    case ActionTypes.RESET_ORDER_PAYMENT_METHODS:
      return {
        ...state,
        orderPaymentMethods: {
          ...state.orderPaymentMethods,
          status: StatusEnums.IDLE,
          items: [],
        },
      };
    case ActionTypes.SELECT_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        orderPaymentMethods: {
          status: state.orderPaymentMethods.status,
          items: state.orderPaymentMethods.items.map((e) => {
            e.isSelected =
              e.internalRef === action.payload.internalRef &&
              e.userCard?.remoteRef === action.payload.userCard?.remoteRef;
            return e;
          }),
        },
        selectedPayment: {
          status: StatusEnums.PEND_SET_ITEM,
          current: action.payload.internalRef,
          previous: state.selectedPayment.current,
        },
        gatewayService: {
          ...state.gatewayService,
          status: StatusEnums.PEND_SET_ITEM,
          name: action.payload.pspName as GatewayEnums,
          genericGateway: {
            ...state.gatewayService.genericGateway,
            status: StatusEnums.PEND_SET_ITEM,
          },
        },
      };
    case ActionTypes.SELECT_PAYMENT_METHOD_RESOLVE:
      return {
        ...state,
        selectedPayment: {
          status: StatusEnums.OK_SET_ITEM,
          current: action.payload.internalRef,
          previous: state.selectedPayment.previous,
        },
      };
    case ActionTypes.SELECT_PAYMENT_METHOD_REJECT:
      return {
        ...state,
        selectedPayment: {
          status: StatusEnums.ERROR_SET_ITEM,
          current: state.selectedPayment.current,
          previous: state.selectedPayment.previous,
        },
      };
    case ActionTypes.INIT_GENERIC_GATEWAY_REQUEST:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          genericGateway: {
            status: StatusEnums.PEND_INIT_ITEM,
            data: undefined,
          },
          adyenCheckout: undefined,
          nonGenericGateway: {
            status: StatusEnums.IDLE,
            data: undefined,
          },
        },
      };
    case ActionTypes.INIT_GENERIC_GATEWAY_RESOLVE:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          status: StatusEnums.OK_INIT_ITEM,
          genericGateway: {
            status: StatusEnums.OK_INIT_ITEM,
            data: action.payload,
          },
          nonGenericGateway: {
            status: StatusEnums.IDLE,
            data: undefined,
          },
        },
      };
    case ActionTypes.INIT_GENERIC_GATEWAY_REJECT:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          genericGateway: {
            status: StatusEnums.ERROR_INIT_ITEM,
            data: undefined,
          },
          nonGenericGateway: {
            status: StatusEnums.IDLE,
            data: undefined,
          },
          adyenCheckout: undefined,
        },
      };
    case ActionTypes.INIT_NON_GENERIC_GATEWAY_REQUEST:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          genericGateway: {
            status: StatusEnums.IDLE,
            data: undefined,
          },
          nonGenericGateway: {
            status: StatusEnums.PEND_INIT_ITEM,
            data: undefined,
          },
          adyenCheckout: undefined,
        },
      };
    case ActionTypes.INIT_NON_GENERIC_GATEWAY_RESOLVE:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          status: StatusEnums.OK_INIT_ITEM,
          genericGateway: {
            status: StatusEnums.IDLE,
            data: undefined,
          },
          nonGenericGateway: {
            status: StatusEnums.OK_INIT_ITEM,
            data: action.payload,
          },
          adyenCheckout: undefined,
        },
      };
    case ActionTypes.INIT_NON_GENERIC_GATEWAY_REJECT:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          genericGateway: {
            status: StatusEnums.IDLE,
            data: undefined,
          },
          nonGenericGateway: {
            status: StatusEnums.ERROR_INIT_ITEM,
            data: undefined,
          },
          adyenCheckout: undefined,
        },
      };
    case ActionTypes.RESOLVE_GATEWAY:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          status: StatusEnums.OK_INIT_ITEM,
        },
      };
    case ActionTypes.INITIALIZE_ADYEN_CHECKOUT_REQUEST:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          adyenCheckout: {
            status: StatusEnums.PEND_INIT_ITEM,
            data: undefined,
          },
        },
      };
    case ActionTypes.INITIALIZE_ADYEN_CHECKOUT_RESOLVE:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          adyenCheckout: {
            status: StatusEnums.OK_INIT_ITEM,
            data: action.payload,
          },
        },
      };
    case ActionTypes.INITIALIZE_ADYEN_CHECKOUT_REJECT:
      return {
        ...state,
        gatewayService: {
          ...state.gatewayService,
          adyenCheckout: {
            status: StatusEnums.ERROR_INIT_ITEM,
            data: undefined,
          },
        },
      };
    case ActionTypes.GET_ADYEN_REDIRECTION_DATA:
      return {
        ...state,
        adyenRedirection: {
          status: AdyenRedirectionStatus.PEND_GET_DETAILS,
          data: undefined,
        },
      };
    case ActionTypes.GET_ADYEN_REDIRECTION_DATA_RESOLVE:
      return {
        ...state,
        adyenRedirection: {
          status: AdyenRedirectionStatus.OK_GET_DETAILS,
          data: action.payload,
        },
      };
    case ActionTypes.GET_ADYEN_REDIRECTION_DATA_REJECT:
      return {
        ...state,
        adyenRedirection: {
          status: AdyenRedirectionStatus.ERROR_GET_DETAILS,
        },
      };
    case ActionTypes.INIT_ADYEN_CONFIRMATION_REQUEST:
      return {
        ...state,
        adyenRedirection: {
          status: AdyenRedirectionStatus.PEND_CONFIRM,
          data: state.adyenRedirection.data,
        },
      };
    case ActionTypes.INIT_ADYEN_CONFIRMATION_RESOLVE:
      return {
        ...state,
        adyenRedirection: {
          status: AdyenRedirectionStatus.OK_CONFIRM,
          data: state.adyenRedirection.data,
        },
      };
    case ActionTypes.INIT_ADYEN_CONFIRMATION_REJECT:
      return {
        ...state,
        adyenRedirection: {
          status: AdyenRedirectionStatus.ERROR_CONFIRM,
          data: state.adyenRedirection.data,
        },
      };
    case ActionTypes.INIT_PAYMENT:
      return {
        ...state,
        paymentStatus: StatusEnums.PEND_PROCESS_ITEM,
      };
    case ActionTypes.RESOLVE_PAYMENT:
      return {
        ...state,
        paymentStatus: StatusEnums.OK_PROCESS_ITEM,
      };
    case ActionTypes.REJECT_PAYMENT:
      return {
        ...state,
        paymentStatus: StatusEnums.ERROR_PROCESS_ITEM,
        errorMessage: action.payload.message || null,
      };
    case ActionTypes.REFUSE_PAYMENT:
      return {
        ...state,
        paymentStatus: StatusEnums.REFUSE_PROCESS_ITEM,
        errorMessage: action.payload.message || null,
      };
    case ActionTypes.CANCEL_PAYMENT:
      return {
        ...state,
        paymentStatus: StatusEnums.CANCEL_PROCESS_ITEM,
      };
    case ActionTypes.RESET_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        paymentStatus: StatusEnums.RESET_PROCESS_ITEM,
        errorMessage: null,
      };
    case ActionTypes.RESET_PAYMENT_STATUS_RESOLVE:
      return {
        ...state,
        paymentStatus: StatusEnums.OK_RESET_PROCESS_ITEM,
        errorMessage: null,
      };
    default:
      return state;
  }
};
