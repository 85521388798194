import {
  AdyenConfirmGenericResponse,
  AdyenPayGenericRequest,
  AdyenRedirectionData,
  GatewayInfo,
  OrderPaymentMethod,
  PayloadAdyenHandleResultCode,
} from './types';
import { AdyenCheckout, AdyenConfig, AdyenPayCreditCardRequest, Actions, ActionTypes } from './types';

export const fetchOrderPaymentMethods = (): Actions => ({
  type: ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REQUEST,
});

export const resolveOrderPaymentMethods = (paymentMethods: OrderPaymentMethod[]): Actions => ({
  type: ActionTypes.FETCH_ORDER_PAYMENT_METHODS_RESOLVE,
  payload: paymentMethods,
});

export const rejectOrderPaymentMethods = (): Actions => ({
  type: ActionTypes.FETCH_ORDER_PAYMENT_METHODS_REJECT,
});

export const resetOrderPaymentMethods = (): Actions => ({
  type: ActionTypes.RESET_ORDER_PAYMENT_METHODS,
});

export const selectPaymentMethodRequest = (payment: OrderPaymentMethod): Actions => ({
  type: ActionTypes.SELECT_PAYMENT_METHOD_REQUEST,
  payload: payment,
});

export const resolveSelectPaymentMethod = (payload: OrderPaymentMethod): Actions => ({
  type: ActionTypes.SELECT_PAYMENT_METHOD_RESOLVE,
  payload,
});

export const rejectSelectPaymentMethod = (): Actions => ({
  type: ActionTypes.SELECT_PAYMENT_METHOD_REJECT,
});

export const initGenericGatewayRequest = (payload: string): Actions => ({
  type: ActionTypes.INIT_GENERIC_GATEWAY_REQUEST,
  payload,
});

export const initGenericGatewayResolve = (payload: GatewayInfo): Actions => ({
  type: ActionTypes.INIT_GENERIC_GATEWAY_RESOLVE,
  payload,
});

export const initGenericGatewayReject = (): Actions => ({
  type: ActionTypes.INIT_GENERIC_GATEWAY_REJECT,
});

export const initNonGenericGatewayRequest = (payload: string): Actions => ({
  type: ActionTypes.INIT_NON_GENERIC_GATEWAY_REQUEST,
  payload,
});

export const initNonGenericGatewayResolve = (payload: string): Actions => ({
  type: ActionTypes.INIT_NON_GENERIC_GATEWAY_RESOLVE,
  payload,
});

export const initNonGenericGatewayReject = (): Actions => ({
  type: ActionTypes.INIT_NON_GENERIC_GATEWAY_REJECT,
});

export const resolveGateway = (): Actions => ({
  type: ActionTypes.RESOLVE_GATEWAY,
});

export const adyenInitializeCheckoutRequest = (payload: AdyenConfig): Actions => ({
  type: ActionTypes.INITIALIZE_ADYEN_CHECKOUT_REQUEST,
  payload,
});

export const adyenInitializeCheckoutResolve = (payload: AdyenCheckout): Actions => ({
  type: ActionTypes.INITIALIZE_ADYEN_CHECKOUT_RESOLVE,
  payload,
});

export const adyenInitializeCheckoutReject = (): Actions => ({
  type: ActionTypes.INITIALIZE_ADYEN_CHECKOUT_REJECT,
});

export const clickOnPay = (): Actions => ({
  type: ActionTypes.INIT_PAYMENT,
});

export const adyenPayGenericRequest = (payload: AdyenPayGenericRequest): Actions => ({
  type: ActionTypes.PAY_ADYEN_GENERIC_REQUEST,
  payload,
});

export const payNonGenericRequest = (payload: string): Actions => ({
  type: ActionTypes.PAY_NON_GENERIC_REQUEST,
  payload,
});

export const adyenPayCreditCardRequest = (payload: AdyenPayCreditCardRequest): Actions => ({
  type: ActionTypes.PAY_ADYEN_CREDIT_CARD_REQUEST,
  payload,
});

export const getAdyenRedirectionData = (payload: URLSearchParams): Actions => ({
  type: ActionTypes.GET_ADYEN_REDIRECTION_DATA,
  payload,
});

export const resolveAdyenRedirectionData = (payload: AdyenRedirectionData): Actions => ({
  type: ActionTypes.GET_ADYEN_REDIRECTION_DATA_RESOLVE,
  payload,
});

export const rejectAdyenRedirectionData = (): Actions => ({
  type: ActionTypes.GET_ADYEN_REDIRECTION_DATA_REJECT,
});

export const requestAdyenConfirmation = (): Actions => ({
  type: ActionTypes.INIT_ADYEN_CONFIRMATION_REQUEST,
});

export const resolveAdyenConfirmation = (payload: AdyenConfirmGenericResponse): Actions => ({
  type: ActionTypes.INIT_ADYEN_CONFIRMATION_RESOLVE,
  payload,
});

export const rejectAdyenConfirmation = (): Actions => ({
  type: ActionTypes.INIT_ADYEN_CONFIRMATION_REJECT,
});

export const adyenHandleResultCode = (payload: PayloadAdyenHandleResultCode): Actions => ({
  type: ActionTypes.PAY_ADYEN_HANDLE_RESULT_CODE,
  payload,
});

export const resolvePayment = (payload: { orderId: string; formattedAmount: string }): Actions => ({
  type: ActionTypes.RESOLVE_PAYMENT,
  payload,
});

export const rejectPayment = (payload: { message: string | null }): Actions => ({
  type: ActionTypes.REJECT_PAYMENT,
  payload,
});

export const refusePayment = (payload: { message: string | null }): Actions => ({
  type: ActionTypes.REFUSE_PAYMENT,
  payload,
});

export const cancelPayment = (): Actions => ({
  type: ActionTypes.CANCEL_PAYMENT,
});

export const resetPaymentStatus = (): Actions => ({
  type: ActionTypes.RESET_PAYMENT_STATUS_REQUEST,
});

export const resetPaymentStatusResolve = (): Actions => ({
  type: ActionTypes.RESET_PAYMENT_STATUS_RESOLVE,
});
