import { BraintreeLineItem } from '@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes';
import { Entities } from '@vestiaire/api-specification';
import { TFunction } from 'i18next';
import { OrderLine } from '../../types';
import {
  AdyenConfirmGenericRequest,
  AdyenConfirmGenericResponse,
  AdyenPayCreditCardRequest,
  AdyenPayGenericRequest,
  AdyenPayGenericResponse,
  GatewayInfo,
  OrderPaymentMethod,
  ErrorPopinStatus,
  StatusEnums,
  SetSelectedPaymentMethodRequestType,
  InitNonGenericGatewayRequest,
  AdyenPaymentMnemonic,
  AdyenRedirectionDetails,
} from './types';
import { SubtotalsTypes } from '../subtotals';
import { TrackingTypes } from '../tracking';
import { Api } from '../../services/api';
import ApiMonolith from '../../services/apiMonolith';
import { ROUTES } from '../../helpers/routes';
import { PaymentTypes } from '.';
import logger from '../../services/logger.service';
import { getFenXOrigin } from '../../helpers';

/* istanbul ignore next */
class PaymentService extends Api {
  getOrderPaymentMethods(): Promise<OrderPaymentMethod[]> {
    return this.get<OrderPaymentMethod[]>('/pafs/payment-methods').then(
      ({ data }) => data || Promise.reject(new Error('Error: empty object for getOrderPaymentMethods'))
    );
  }

  setSelectedPaymentMethod(paymentMethodId: number, storedCardId?: string): Promise<OrderPaymentMethod> {
    return this.patch<OrderPaymentMethod, SetSelectedPaymentMethodRequestType>('/orders/current/payment-method', {
      paymentMethodId: paymentMethodId.toString(),
      storedCardId,
    }).then(({ data }) => data || Promise.reject(new Error('Error: empty object for setSelectedPaymentMethod')));
  }

  initPaymentMethod(method: string): Promise<GatewayInfo> {
    return this.post<any>('/payments/init', { method })
      .then(({ data }) => {
        return data || Promise.reject(new Error('Error: empty object for /payments/init'));
      })
      .catch((e) => {
        throw e;
      });
  }

  confirmPaypalPayment(paymentId: string, nonce: string): Promise<GatewayInfo> {
    return this.post<any>('/payments/braintree/paypal/confirm', {
      paymentId,
      nonce,
    })
      .then(({ data }) => {
        return data || Promise.reject(new Error('Error: empty object for /payments/braintree/paypal/confirm'));
      })
      .catch((e) => {
        throw e;
      });
  }

  processAdyenGenericPayment(params: AdyenPayGenericRequest) {
    return this.post<AdyenPayGenericResponse, AdyenPayGenericRequest>('payments/adyen/generic/process', params)
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  processAdyenCreditCardPayment(params: AdyenPayCreditCardRequest) {
    return this.post<AdyenPayGenericResponse, AdyenPayCreditCardRequest>('payments/adyen/card/process', params)
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  processAdyenGenericConfirm(params: AdyenConfirmGenericRequest) {
    return this.post<AdyenConfirmGenericResponse, AdyenConfirmGenericRequest>('payments/adyen/generic/confirm', params)
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }
}

export function generateOrderConfirmationQueryParams(
  orderId: string,
  orderAmount: string,
  datalayer?: Partial<TrackingTypes.DataLayerInterface>
) {
  return `?orderId=${orderId}&orderAmount=${btoa(encodeURIComponent(orderAmount))}&dl=${btoa(
    JSON.stringify(datalayer)
  )}`;
}

// this url redirects directly to confirmation page on fenx
// without any treatment
export function generateOrderConfirmationUrl(
  orderId: string,
  orderAmount: string,
  datalayer?: Partial<TrackingTypes.DataLayerInterface>
) {
  const queryParams = generateOrderConfirmationQueryParams(orderId, orderAmount, datalayer);
  const fenxOrigin = getFenXOrigin();
  return `${fenxOrigin}${ROUTES.ORDER_CONFIRMATION}${queryParams}`;
}

// this url redirects first to fenx server side
// to deal with confirmation of credit card payments
// that requires to receive POST REQUEST SERVER SIDE
// on fenx, we then confirm the payment calling confirmation api
// then we redirects to the order confirmation page
export const generatePaymentConfirmationUrl = (
  gatewayName: string,
  orderId: string,
  orderAmount: string,
  paymentId: string,
  i18n: string,
  datalayer?: Partial<TrackingTypes.DataLayerInterface>
) => {
  const fenXOrigin = getFenXOrigin();
  const queryParams = generateOrderConfirmationQueryParams(orderId, orderAmount, datalayer);
  return `${fenXOrigin}${
    ROUTES.PAYMENT_CONFIRMATION
  }/${queryParams}&psp=${gatewayName}&paymentId=${paymentId}&i18n=${btoa(i18n)}`;
};

export const formatAmountForPaypal = (price: Entities.Price): string => {
  const amount = price.cents / 100;
  return amount.toString();
};

export const checkPaypalAmountsMatch = (lineItems: BraintreeLineItem[], totalAmount: number): boolean => {
  const sum = lineItems.reduce((accumulator, currentValue) => {
    const value =
      currentValue.kind === 'credit' ? -parseFloat(currentValue.unitAmount) : +parseFloat(currentValue.unitAmount);
    return accumulator + value;
  }, 0);
  const match = parseFloat(sum.toFixed(2)) === totalAmount;
  if (!match) {
    logger.error(`payment::priceCheck::paypal::${sum} ${totalAmount}`, lineItems);
  }
  return match;
};

export const getPaypalLineItems = (
  orderlines: OrderLine[],
  subtotals: SubtotalsTypes.State,
  t: TFunction
): BraintreeLineItem[] => {
  const lineItems = orderlines.map((e) => {
    return {
      quantity: '1', // string
      unitAmount: formatAmountForPaypal(e.product.price), // string
      unitTaxAmount: undefined,
      name: e.product.name, // string
      kind: 'debit' as 'debit' | 'credit', // string
      description: `${t('COMMON.SELLER', 'Seller')} ${e.product.seller?.id}`,
      productCode: e.product.id,
      url: undefined,
    };
  });

  // + shipping cost
  if (subtotals.shippingAmount.cents) {
    lineItems.push({
      quantity: '1',
      unitAmount: formatAmountForPaypal(subtotals.shippingAmount), // string
      unitTaxAmount: undefined,
      name: t('SUMMARY.SHIPPING_FEES', 'Shipping fees'),
      kind: 'debit',
      description: '',
      productCode: '',
      url: undefined,
    });
  }

  // + Authentication & control
  if (subtotals.buyerFeesAmount.cents) {
    lineItems.push({
      quantity: '1',
      unitAmount: formatAmountForPaypal(subtotals.buyerFeesAmount), // string
      unitTaxAmount: undefined,
      name: t('SUMMARY.CONTROL_AND_AUTHENTICATION', 'Control and authentication'),
      kind: 'debit',
      description: '',
      productCode: '',
      url: undefined,
    });
  }

  // - voucher
  if (subtotals.voucherAmount.cents) {
    lineItems.push({
      quantity: '1',
      unitAmount: formatAmountForPaypal(subtotals.voucherAmount), //string
      unitTaxAmount: undefined,
      name: t('VOUCHER.LIST.DISCOUNT', 'Discount'),
      kind: 'credit',
      description: '',
      productCode: '',
      url: undefined,
    });
  }

  // + duties
  if (subtotals.dutiesAmount.cents) {
    lineItems.push({
      quantity: '1',
      unitAmount: formatAmountForPaypal(subtotals.dutiesAmount), // string
      unitTaxAmount: undefined,
      name: t('SUMMARY.DUTIES.TITLE', 'Import Duties and Taxes'),
      kind: 'debit',
      description: '',
      productCode: '',
      url: undefined,
    });
  }

  // + salesTax -> checking fomatted value, beacuse if it comes from api, formatted value is fullfield
  if (subtotals.salesTaxAmount.formatted) {
    // show if returned from api even if equal to zero
    lineItems.push({
      quantity: '1',
      unitAmount: subtotals.salesTaxAmount.cents ? formatAmountForPaypal(subtotals.salesTaxAmount) : '0', // string
      unitTaxAmount: undefined,
      name: t('SUMMARY.SALES_TAX', 'Sales tax'),
      kind: 'debit',
      description: '',
      productCode: '',
      url: undefined,
    });
  }

  const totalAmount = subtotals.totalAmount.cents / 100;
  checkPaypalAmountsMatch(lineItems, totalAmount);

  return lineItems;
};

export const mapPaymtStatusToPopinStatus = new Map<StatusEnums, ErrorPopinStatus>([
  [StatusEnums.ERROR_PROCESS_ITEM, ErrorPopinStatus.ERROR],
  [StatusEnums.CANCEL_PROCESS_ITEM, ErrorPopinStatus.CANCELLED],
  [StatusEnums.REFUSE_PROCESS_ITEM, ErrorPopinStatus.REFUSED],
]);

export const mapPaymentMethodToAdyenComponent = new Map<AdyenPaymentMnemonic, string>([
  [AdyenPaymentMnemonic.BANCONTACT, AdyenPaymentMnemonic.BANCONTACT],
  [AdyenPaymentMnemonic.CREDIT_CARD, 'card'],
  [AdyenPaymentMnemonic.STORED_CARD, 'card'],
]);

export class PaymentMonolithService extends ApiMonolith {
  initNonGenericGateway(params: InitNonGenericGatewayRequest): Promise<string> {
    return this.getMonolith<{ url: string }>('', {
      m: 'getUrl',
      checkout_revamp: '1',
      mnemonic: 'paiement',
      ...params,
    })
      .then((x) => {
        return x.result?.url || '';
      })
      .catch((e) => {
        throw e;
      });
  }
}

export const api = new PaymentService();
export const apiMonolith = new PaymentMonolithService();

export const getPaymentMethodSettings = (paymentName: AdyenPaymentMnemonic) => {
  const isGenericMethod = ![
    AdyenPaymentMnemonic.BANCONTACT,
    AdyenPaymentMnemonic.CREDIT_CARD,
    AdyenPaymentMnemonic.STORED_CARD,
  ].includes(paymentName);

  return {
    isGenericMethod,
    isPopupRequired: !isGenericMethod,
  };
};

/**
 * translate adyen result code to VC result code
 */
export const getResultCodeForCheckoutPage = (
  adyenCode: PaymentTypes.AdyenResultCodes
): PaymentTypes.CheckoutPathResultCode => {
  const adyenCodeToCheckoutCode = {
    [PaymentTypes.AdyenResultCodes.CANCELLED]: PaymentTypes.CheckoutPathResultCode.CANCEL,
    [PaymentTypes.AdyenResultCodes.REFUSED]: PaymentTypes.CheckoutPathResultCode.REFUSE,
    default: PaymentTypes.CheckoutPathResultCode.ERROR,
  };
  return adyenCodeToCheckoutCode[adyenCode] || adyenCodeToCheckoutCode.default;
};

export const getReturnedUrlDetails = (searchParams: URLSearchParams): AdyenRedirectionDetails => {
  const details: AdyenRedirectionDetails = {};
  const excludedParams = ['paymentId', 'orderId', 'orderAmount'];

  searchParams.forEach((value, key) => {
    if (excludedParams.indexOf(key) === -1) details[key] = value;
  });

  return details;
};
