import { Entities } from '@vestiaire/api-specification';
import type { RootState } from '../../store';
import { OrderLine } from '../../types';
import { State } from './types';

export const getRoot = (state: RootState): State => state.orderLines;

export const getValues = (state: RootState): OrderLine[] => Object.values(getRoot(state));

export const getReservedItems = (state: RootState): string[] =>
  getValues(state)
    .filter(({ product }) => product.reserved)
    .map(({ id }) => id);

export const getSoldItems = (state: RootState): string[] =>
  getValues(state)
    .filter(({ product }) => product.sold)
    .map(({ id }) => id);

export const getProhibitedItems = (state: RootState): string[] =>
  getValues(state)
    .filter(({ product }) => product.prohibited)
    .map(({ id }) => id);

export const getProductsWithOrderLineIds =
  (orderLineIds: string[]) =>
  (state: RootState): Entities.Product[] =>
    getValues(state)
      .filter(({ id }) => orderLineIds.includes(id))
      .map(({ product }) => product);

export const getIsMandatoryAuthFromProductId =
  (productId: string) =>
  (state: RootState): boolean =>
    !!getValues(state).find(({ product }) => productId === product?.id)?.hasAuthenticationMandatory;

export const getFromProductIds = (productIds: string[]) => {
  const productList = (state: RootState): string[] => {
    return getValues(state)
      .filter(({ product }) => {
        return productIds.includes(product.id);
      })
      .map((orderline) => orderline.id);
  };
  return productList;
};

export const isLastProductBeingDeleted = (state: RootState): boolean => {
  const orderLines = getValues(state);
  return orderLines.length === 1 && orderLines[0].status === 'deleted';
};
