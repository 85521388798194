import { SVGProps } from 'react';
import environments from '../../environments';

type IProps = {
  name: string;
  classes?: string;
  ariaHidden?: boolean | undefined;
  ariaLabel?: string | undefined;
  size?: 'default' | 'large';
} & SVGProps<SVGElement>;

const Icon: React.FC<IProps> = (props) => {
  const { name, classes, ariaHidden, ariaLabel, size = 'default', ...rest } = props;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <svg
      version="1.1"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={`vc-icon vc-icon-${name} ${classes}`}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      {...rest}
    >
      <use href={`${environments.svgPath}/${name}-${size}.svg#${name}-${size}`} />
    </svg>
  );
};

Icon.defaultProps = {
  classes: '',
  ariaHidden: undefined,
  ariaLabel: undefined,
  size: 'default',
};

export default Icon;
