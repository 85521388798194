import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getRoot } from './selectors';
import { IGetLineItem } from './types';
import { orderLinesActions, orderLinesSelectors } from './index';
import { CartTypes, cartSelectors } from '../cart';
import { uiActions, uiSelectors } from '../ui';

export const useGetLineItem = (id: string): IGetLineItem => {
  const orderLines = useAppSelector(getRoot);
  const lineItem = orderLines[id];

  return { lineItem };
};

export const useGetOrderlines = () => {
  const cartStatus = useAppSelector(cartSelectors.getStatus);
  const orderLines = useAppSelector(orderLinesSelectors.getRoot);
  const orderLineIds = Object.keys(orderLines);
  const isLoading = useAppSelector(uiSelectors.getOrderLineLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cartStatus === CartTypes.StatusEnum.PEND_GET_LIST && !orderLineIds.length) {
      dispatch(uiActions.setOrderLineLoading(true));
      return;
    }
    dispatch(uiActions.setOrderLineLoading(false));
  }, [dispatch, cartStatus, orderLineIds]);

  useEffect(() => {
    if (cartStatus === CartTypes.StatusEnum.OK_GET_LIST) {
      dispatch(orderLinesActions.enquiry());
    }
  }, [dispatch, cartStatus]);

  return { orderLines, orderLineIds, cartStatus, isLoading };
};
