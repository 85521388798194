import { collectionPointsActions } from '../collection-points';
import {
  takeLatest,
  call,
  put,
  ForkEffect,
  SagaReturnType,
  select,
  SelectEffect,
  PutEffect,
  CallEffect,
} from 'redux-saga/effects';
import { Actions, ActionTypes, InferredAction, Type, Entity, ApiResponse } from './types';
import logger from '../../services/logger.service';
import { api } from './services';
import { pluckDelivery, getBilling, getDelivery } from './selectors';
import { cartActions, CartTypes } from '../cart';
import { fetchReject, fetchResolve, setBillingRequest, setBillingResolve, setBillingReject } from './actions';
import { paymentActions } from '../payment';

export function* getList() {
  try {
    const res: ApiResponse = yield call([api, 'getList']);
    yield put(fetchResolve(res));
    if (!res.length) {
      return;
    }
    const delivery = pluckDelivery(res);

    yield put(
      collectionPointsActions.setDraftAddress({
        address: String(delivery?.line1),
        city: String(delivery?.city),
        countryCode: String(delivery?.country.iso2),
        postCode: String(delivery?.zipCode),
      })
    );
  } catch (err) {
    logger.error('address::getList', err);
    yield put(fetchReject(JSON.stringify(err)));
  }
}

export function* checkAvailabilitySaga(): Generator<SelectEffect | PutEffect, void, ReturnType<typeof getBilling>> {
  const billing = yield select(getBilling);
  yield put(cartActions.setNoBillingAddress(!billing));
}

export function* setBilling({
  payload: addressId,
}: InferredAction<ActionTypes.SET_BILLING_ADDRESS_REQUEST>): Generator<CallEffect<Entity> | PutEffect> {
  try {
    yield call([api, 'setType'], addressId, Type.BILLING);
    yield put(setBillingResolve());
    yield put(paymentActions.fetchOrderPaymentMethods());
  } catch (e) {
    logger.error('address::setBillingRejected', e);
    yield put(setBillingReject());
  }
}

export function* setBillingAsDelivery(): Generator<SelectEffect | PutEffect, void, Entity> {
  const delivery = yield select(getDelivery);
  const billing = yield select(getBilling);
  if (delivery?.addressId && delivery?.addressId !== billing?.addressId) {
    yield put(setBillingRequest(delivery.addressId));
  }
}

export function* setDeliveryMethodShippingSaga({
  payload,
}: InferredAction<ActionTypes.SET_DELIVERY_METHOD_SHIPPING_ADDRESS>): Generator<
  CallEffect<SagaReturnType<typeof api.setDeliveryMethodShipping>> | PutEffect
> {
  try {
    yield call([api, 'setDeliveryMethodShipping'], payload);
    yield put(cartActions.fetch());
  } catch (e) {
    logger.error('address::setDeliveryMethodShipping', e);
    yield put(cartActions.setDeliveryMethod(CartTypes.DeliveryMethods.COLLECTION_POINT));
  }
}

export function* sagas(): Generator<ForkEffect<Actions>> {
  yield takeLatest(ActionTypes.FETCH, getList);
  yield takeLatest(ActionTypes.SET_BILLING_ADDRESS_REQUEST, setBilling);
  yield takeLatest(ActionTypes.SET_BILLING_SAME_AS_DELIVERY, setBillingAsDelivery);
  yield takeLatest(ActionTypes.CHECK_AVAILABILITY, checkAvailabilitySaga);
  yield takeLatest(ActionTypes.SET_DELIVERY_METHOD_SHIPPING_ADDRESS, setDeliveryMethodShippingSaga);
}
