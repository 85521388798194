import { State, Actions, ActionTypes, Response, DeliveryMethods, RemoveOrderLinePayloadType } from './types';

export const fetch = (): Actions => ({
  type: ActionTypes.FETCH,
});

export const fetchResolve = (payload: Response): Actions => ({
  type: ActionTypes.RESOLVE,
  payload,
});

export const fetchReject = (payload: string): Actions => ({
  type: ActionTypes.REJECT,
  payload,
});

export const set = (cart: State): Actions => ({
  type: ActionTypes.SET,
  payload: cart,
});

export const removeLineItem = (payload: RemoveOrderLinePayloadType): Actions => ({
  type: ActionTypes.REMOVE_LINE_ITEM,
  payload,
});

export const addLineItemTo = (id: string): Actions => ({
  type: ActionTypes.ADD_LINE_ITEM,
  payload: id,
});

export const setDeliveryMethod = (payload: DeliveryMethods): Actions => ({
  type: ActionTypes.SET_DELIVERY_METHOD,
  payload,
});

export const checkRequest = (): Actions => ({
  type: ActionTypes.CHECK_CART_REQUEST,
});

export const checkResolve = (): Actions => ({
  type: ActionTypes.CHECK_CART_RESOLVE,
});

export const checkReject = (): Actions => ({
  type: ActionTypes.CHECK_CART_REJECT,
});

export const checkReset = (): Actions => ({
  type: ActionTypes.CHECK_CART_RESET,
});

export const setNoBillingAddress = (payload: boolean): Actions => ({
  type: ActionTypes.SET_NO_BILLING_ADDRESS,
  payload,
});

export const setSoldProductsInBasket = (payload: string[]): Actions => ({
  type: ActionTypes.SET_SOLD_PRODUCTS_IN_BASKET,
  payload,
});

export const setProhibitedProductsInBasket = (payload: string[]): Actions => ({
  type: ActionTypes.SET_PROHIBITED_PRODUCTS_IN_BASKET,
  payload,
});
