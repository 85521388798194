import { initialize as initializeSDK, LDClient, LDUser } from 'launchdarkly-js-client-sdk';
import { call, ForkEffect, put, takeLatest, select, SelectEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { ActionTypes, Actions } from './types';
import { initializeResolve, initializeReject } from './actions';
import { getClient } from './selectors';
import { authServices, authSelectors, AuthTypes } from '../auth';
import { i18nServices } from '../i18n';
import environments from '../../environments';
import { camelizeFlagsAndMap, LDOptions } from './services';
import logger from '../../services/logger.service';

const clientId = environments.launchDarkly.clientId;

export function* initialize() {
  const userIdCookie = authServices.getUserIdFromAuthToken();
  const anonymousId = authServices.getOrGenerateAnonymousId();
  const randomId = authServices.getOrGenerateRandomId();
  const country = i18nServices.getCountry();
  const language = i18nServices.getLanguage();
  const user: AuthTypes.User | null = yield select(authSelectors.userSelector);
  const ldUser = {
    key: userIdCookie || randomId || 'anonymous',
    anonymous: !userIdCookie,
    country,
    email: user?.email,
    name: user?.username,
    custom: {
      platform: 'web',
      env: 'checkout',
      logged: !!userIdCookie,
      language,
      anonymous_id: anonymousId,
      ruleContext: [country],
    },
  };
  const client = initializeSDK(clientId, ldUser, LDOptions);
  try {
    yield call([client, 'waitForInitialization']);
    const [flags, keyMaps] = camelizeFlagsAndMap(client.allFlags());
    yield put(initializeResolve({ client, flags, keyMaps, user: ldUser, clientId, options: LDOptions }));
  } catch (e) {
    yield put(
      initializeReject({
        errorMsg: 'LDInitialization',
      })
    );
    logger.error('bucket-test::initialization', e);
  }
}

export function* identifyUser(): Generator<SelectEffect | CallEffect | PutEffect, void, LDClient | AuthTypes.User> {
  try {
    const client = (yield select(getClient)) as LDClient;
    const currentLDUser = client?.getUser();
    const user = (yield select(authSelectors.userSelector)) as AuthTypes.User;
    const newUser: LDUser = {
      ...currentLDUser,
      key: user.id,
      anonymous: false,
      email: user.email,
      name: user.username,
      custom: {
        ...currentLDUser.custom,
        logged: true,
      },
    };
    yield call([client, 'identify'], newUser);
    yield call([client, 'alias'], newUser, currentLDUser);
    const [flags, keyMaps] = camelizeFlagsAndMap(client.allFlags());
    yield put(initializeResolve({ flags, keyMaps }));
  } catch (e) {
    logger.error('bucket-test::identifyUser', e);
  }
}

export function* sagas(): Generator<ForkEffect<Actions>> {
  yield takeLatest(ActionTypes.INITIALIZE, initialize);
  yield takeLatest(ActionTypes.IDENTIFY_USER, identifyUser);
}
