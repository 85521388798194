import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { authSelectors } from '../../features/auth';
import { bucketTestActions, bucketTestSelectors } from '../../features/bucket-test';

export const BucketTest = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(authSelectors.userSelector);
  const client = useAppSelector(bucketTestSelectors.getClient);

  useEffect(() => {
    dispatch(bucketTestActions.initialize());
  }, [dispatch]);

  useEffect(() => {
    const launchDarklyUser = client?.getUser?.();
    if (!launchDarklyUser) return;
    // wanna make sure that user is exist, Dont want to check with undefined value
    if (user && user.id !== launchDarklyUser?.key) {
      dispatch(bucketTestActions.identifyUser());
    }
  }, [dispatch, user, client]);

  return null;
};
