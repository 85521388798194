import React from 'react';
import './LineItemLoader.scss';
import './PlanServices/PlanServices.scss';
import './PlanItemList/PlanItemList.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../app/hooks';
import { bucketTestSelectors } from '../../features/bucket-test';

const LineItemLoader: React.FC = () => {
  const { smoothCheckoutCollapsed } = useAppSelector(bucketTestSelectors.getFlags);
  return (
    <div
      className={classNames('lineItem lineItem--loader ssc', {
        'lineItem--currentVersion': !smoothCheckoutCollapsed,
      })}
      data-testid="lineitem_loading"
    >
      <div className="lineItem__container">
        <div className="lineItem__seller">
          <div className="lineItem__seller__header">
            <div className="lineItem__seller__header__picture ssc-circle" />
            <div className="lineItem__seller__header__description">
              <div className="w-40">
                <div className="vc-text-s ssc-line w-60">&nbsp;</div>
                <div className="lineItem__seller__header__name ssc-line">&nbsp;</div>
              </div>
              <button type="button" className="lineItem__seller__header__seemore vc-text-s ssc-line w-20">
                &nbsp;
              </button>
            </div>
          </div>
        </div>

        <div className="productItem">
          <div className="productItem__linkWrapper w-70">
            <div className="productItem__picture ssc-square" />
            <div className="productItem__description w-70">
              <div className="productItem__brand ssc-line w-70">&nbsp;</div>
              <div className="ssc-line">&nbsp;</div>
            </div>
          </div>
          <div className="productItem__price vc-text-l ssc-line w-20">
            <p>&nbsp;</p>
          </div>
        </div>

        <div className="planOption">
          <div className="planOption__item__content" data-testid="planOption__testId">
            <div className="planOption__item__title ssc" id="planTitle_standard-shipping_32872158">
              <span className="vc-icon vc-icon-authentication ssc-circle"></span>
              <span className="ssc-line w-50">&nbsp;</span>
            </div>
            <ul
              className="planServices"
              aria-labelledby="planTitle_standard-shipping_32872158"
              data-testid="planServices_standard-shipping"
            >
              <li className="planServices__item">
                <span className="planServices__item__title ssc-line w-40">&nbsp;</span>
                <span className="planServices__item__price ssc-line w-20">&nbsp;</span>
              </li>
              <li className="planServices__item">
                <span className="planServices__item__title ssc-line w-30">&nbsp;</span>
                <span className="planServices__item__price ssc-line w-10">&nbsp;</span>
              </li>
            </ul>
          </div>
          <div className="vc-text-s planItemLearnMore  ssc-line w-10">&nbsp;</div>
        </div>

        <div className="lineItem__container__delete vc-text-s ssc-line w-20">&nbsp;</div>
      </div>
    </div>
  );
};

export default LineItemLoader;
