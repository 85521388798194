import React, { memo } from 'react';
import './SubtotalsSkeleton.scss';

const SubtotalsSkeleton = (): JSX.Element => {
  return (
    <ul className="subtotals subtotals--skeleton ssc">
      <li className="subtotals__entry">
        <details className="subtotals__detailsWrapper">
          <summary className="subtotals__header">
            <span className="subtotals__title ssc-line w-40">&nbsp;</span>
            <span className="subtotals__amount ssc-line w-30">&nbsp;</span>
          </summary>
        </details>
      </li>
      <li className="subtotals__entry subtotals__header">
        <span className="subtotals__title ssc-line w-30">&nbsp;</span>
        <span className="ssc ssc-line sumup__amountLoading"></span>
      </li>
      <li className="subtotals__entry subtotals__header">
        <span className="subtotals__title ssc-line w-30">&nbsp;</span>
        <span className="ssc ssc-line sumup__amountLoading"></span>
      </li>
    </ul>
  );
};

export default memo(SubtotalsSkeleton);
