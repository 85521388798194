import { ActionTypes, Actions } from './types';
import { State } from './types';

const initialState: State = {} as State;

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_ORDER_LINES: {
      return {
        ...action.payload,
      };
    }
    case ActionTypes.REMOVE_LINE_ITEM: {
      return {
        ...state,
        [action.payload.orderLineIds]: { ...state[action.payload.orderLineIds], status: 'deleted' },
      };
    }
    case ActionTypes.UNDO_REMOVE_LINE_ITEM: {
      return {
        ...state,
        [action.payload.orderLineIds]: { ...state[action.payload.orderLineIds], status: '' },
      };
    }
    default:
      return state;
  }
};
