import { FC } from 'react';
import { WithTranslation } from 'react-i18next';
import { VoucherTypes } from '../../../features/voucher';

import './VoucherList.scss';

type VoucherListProps = {
  t: WithTranslation['t'];
  voucher?: VoucherTypes.Entity;
  reduction?: VoucherTypes.Reduction;
  onRemoveVoucher?: (x: VoucherTypes.Entity) => void;
};

const VoucherListUI: FC<VoucherListProps> = ({ t, voucher, reduction, onRemoveVoucher }) => {
  return (
    <div className="voucherList">
      <p className="voucherList__discountText">
        <span data-testid="voucher_applied_text">{voucher?.code}</span>
        {onRemoveVoucher && (
          <button
            type="button"
            className="vc-text-s voucherList__discountText__remove"
            data-testid="voucher_applied_remove_button"
            onClick={() => {
              if (voucher) {
                onRemoveVoucher?.(voucher);
              }
            }}
          >
            {t('VOUCHER.LIST.REMOVE_DISCOUNT', 'Remove')}
          </button>
        )}
      </p>
      <p className="voucherList__discountReduction" data-testid="summary_voucher_amount">
        -{reduction?.formatted}
      </p>
    </div>
  );
};

export default VoucherListUI;
