import { AddressTypes } from '.';
import { Api } from '../../services/api';

class AddressService extends Api {
  getList(): Promise<AddressTypes.ApiResponse> {
    return this.get<AddressTypes.ApiResponse>(`/users/addressV2?context=checkout`)
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  setType(addressId: number, type: AddressTypes.Type): Promise<AddressTypes.Entity> {
    return this.post<AddressTypes.Entity, Record<string, unknown>>(`/users/addressV2/${addressId}/flag/${type}`, {})
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  setDeliveryMethodShipping(addressId: AddressTypes.Entity['addressId']) {
    return this.post(`/orders/current/delivery-method/shipping/${addressId}`, {}).catch((err) => {
      throw err;
    });
  }
}

export const api = new AddressService();
