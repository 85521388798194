import { Entity, Actions, ActionTypes, ApiResponse } from './types';

export const fetch = (): Actions => ({
  type: ActionTypes.FETCH,
});
export const fetchResolve = (payload: ApiResponse): Actions => ({
  type: ActionTypes.RESOLVE,
  payload,
});
export const fetchReject = (payload: string): Actions => ({
  type: ActionTypes.REJECT,
  payload,
});

export const setBillingRequest = (payload: number): Actions => ({
  type: ActionTypes.SET_BILLING_ADDRESS_REQUEST,
  payload,
});
export const setBillingResolve = (): Actions => ({
  type: ActionTypes.SET_BILLING_ADDRESS_RESOLVE,
});
export const setBillingReject = (): Actions => ({
  type: ActionTypes.SET_BILLING_ADDRESS_REJECT,
});

export const setBillingSameAsDelivery = (payload: boolean): Actions => ({
  type: ActionTypes.SET_BILLING_SAME_AS_DELIVERY,
  payload,
});

export const checkAvailability = (): Actions => ({
  type: ActionTypes.CHECK_AVAILABILITY,
});

export const setDeliveryMethodShipping = (payload: Entity['addressId']): Actions => ({
  type: ActionTypes.SET_DELIVERY_METHOD_SHIPPING_ADDRESS,
  payload,
});
