import type { RootState } from '../../store';
import { Entity, ListRow, StatusEnum, Type } from './types';

export const getStatus = ({ address }: RootState) => address.status as StatusEnum;

export const pluck = (l: ListRow[], addressType: Type): Entity | undefined =>
  l.find((x) => !!x.address.flagList.find((z) => z.name === addressType))?.address;

export const pluckWithId = (l: Entity[], addressId = 0): Entity | undefined => l.find((x) => x.addressId === addressId);

export const pluckBilling = (l: ListRow[]): Entity | undefined => pluck(l, Type.BILLING);

export const pluckDelivery = (l: ListRow[]): Entity | undefined => pluck(l, Type.DELIVERY);

export const getListRow = ({ address }: RootState): ListRow[] => address.list;

export const getList = ({ address }: RootState): Entity[] => address.list.map((x) => x.address);

export const getBilling = (state: RootState): Entity | undefined =>
  getList(state).find((a) => a.addressId === state.address.selectedBillingAddressId) ||
  pluckBilling(getListRow(state)) ||
  undefined;

export const getDelivery = (state: RootState): Entity | undefined =>
  getList(state).find((a) => a.addressId === state.address.selectedDeliveryAddressId) ||
  pluckDelivery(getListRow(state)) ||
  undefined;

export const getSelectedDeliveryId = ({ address }: RootState): number | undefined => address.selectedDeliveryAddressId;

export const getLastSelectedDeliveryId = ({ address }: RootState): number | undefined =>
  address.lastSelectedDeliveryAddressId;

export const getLastSelectedDelivery = (state: RootState): Entity | undefined =>
  pluckWithId(getList(state), getLastSelectedDeliveryId(state)) || pluckDelivery(getListRow(state)) || undefined;

export const getIsBillingSameAsDelivery = ({ address }: RootState): boolean => address.isBillingSameAsDelivery;

export const getIsDeliveryAndBillingPending = ({ address }: RootState): boolean =>
  address.status === StatusEnum.PEND_GET_LIST && !address.list.length;
