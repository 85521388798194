import React from 'react';
import './Spinner.scss';

export type SpinnerProps = React.HTMLProps<HTMLDivElement>;

const Spinner: React.FC<SpinnerProps> = ({ className }: SpinnerProps) => {
  return (
    <div className={`spinner ${className}`}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g>
          <path
            fill="transparent"
            d="M16,0c3.2,0,6.3,0.9,8.9,2.7c2.6,1.8,4.7,4.3,5.9,7.2c1.2,2.9,1.5,6.1,0.9,9.2c-0.6,3.1-2.1,6-4.4,8.2
      c-2.2,2.2-5.1,3.8-8.2,4.4c-3.1,0.6-6.3,0.3-9.2-0.9c-2.9-1.2-5.4-3.3-7.2-5.9C0.9,22.3,0,19.2,0,16h4c0,2.4,0.7,4.7,2,6.7
      c1.3,2,3.2,3.5,5.4,4.4c2.2,0.9,4.6,1.1,6.9,0.7c2.3-0.5,4.5-1.6,6.1-3.3c1.7-1.7,2.8-3.8,3.3-6.1c0.5-2.3,0.2-4.7-0.7-6.9
      S24.6,7.3,22.7,6c-2-1.3-4.3-2-6.7-2V0z"
          />
        </g>
        <g>
          <path
            fill="currentColor"
            d="M30.1,15.9c1.1,0,2,0.9,1.9,2c-0.3,2.5-1.2,4.8-2.6,6.9c-1.8,2.6-4.3,4.7-7.2,5.9c-2.9,1.2-6.2,1.5-9.3,0.9
      c-3.1-0.6-6-2.1-8.2-4.4c-2.2-2.2-3.8-5.1-4.4-8.2C-0.3,16,0,12.7,1.2,9.8c1.2-2.9,3.3-5.4,5.9-7.2c2.1-1.4,4.5-2.3,6.9-2.6
      c1.1-0.1,2,0.8,2,1.9s-0.9,2-2,2.2C12.4,4.4,10.8,5,9.4,5.9c-2,1.3-3.5,3.2-4.4,5.4c-0.9,2.2-1.1,4.6-0.7,7
      c0.5,2.3,1.6,4.5,3.3,6.2c1.7,1.7,3.8,2.8,6.2,3.3s4.8,0.2,7-0.7s4.1-2.5,5.4-4.4c0.9-1.4,1.6-3,1.9-4.7
      C28.1,16.8,29,15.9,30.1,15.9z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Spinner;
