import { PaymentTypes } from '../payment';
import { ActionTypes, Actions, Modal, AddressForm, MessageModal, PricingBreakdownModalType } from './types';

export const setEmptyCartUi = (payload: boolean): Actions => ({
  type: ActionTypes.SET_EMPTY_CART,
  payload,
});

export const setCheckoutOverlay = (payload: boolean): Actions => ({
  type: ActionTypes.SET_CHECKOUT_OVERLAY,
  payload,
});

export const setOrderLineLoading = (payload: boolean): Actions => ({
  type: ActionTypes.SET_ORDER_LINE_LOADING,
  payload,
});

export const setSummaryLoading = (payload: boolean): Actions => ({
  type: ActionTypes.SET_SUMMARY_LOADING,
  payload,
});

export const setCTALoading = (payload: boolean): Actions => ({
  type: ActionTypes.SET_CTA_LOADING,
  payload,
});

export const setReInsuranceLoading = (payload: boolean): Actions => ({
  type: ActionTypes.SET_RE_INSURANCE_LOADING,
  payload,
});
export const setPayButtonLoading = (payload: boolean): Actions => ({
  type: ActionTypes.SET_PAY_BUTTON_LOADING,
  payload,
});

export const showSoldItemsModal = (payload: Partial<Modal>): Actions => ({
  type: ActionTypes.SOLD_ITEMS_MODAL,
  payload,
});

export const showProhibitedItemsModal = (payload: Partial<Modal>): Actions => ({
  type: ActionTypes.PROHIBITED_ITEMS_MODAL,
  payload,
});

export const setAddressForm = (payload: Partial<AddressForm>): Actions => ({
  type: ActionTypes.SET_ADDRESS_FORM,
  payload,
});

export const setCollectionPointModal = (payload: Partial<Modal>): Actions => ({
  type: ActionTypes.SET_COLLECTION_POINT_MODAL,
  payload,
});

export const setAuthModal = (payload: Partial<Modal>): Actions => ({
  type: ActionTypes.SET_AUTH_MODAL,
  payload,
});

export const setRedirectToCheckoutError = (payload: PaymentTypes.CheckoutPathResultCode | null): Actions => ({
  type: ActionTypes.SET_REDIRECT_TO_CHECKOUT_ERROR,
  payload,
});

export const setRefreshCartModal = (payload: Partial<Modal>): Actions => ({
  type: ActionTypes.SET_REFRESH_CART_MODAL,
  payload,
});

export const setMessageModal = (payload: Partial<MessageModal>): Actions => ({
  type: ActionTypes.SET_MESSAGE_MODAL,
  payload,
});

export const setPricingBreakdownModal = (payload: PricingBreakdownModalType): Actions => ({
  type: ActionTypes.SET_PRICING_BREAKDOWN_MODAL,
  payload,
});

export const highlightPaymentMethodUpdate = (payload: boolean): Actions => ({
  type: ActionTypes.SET_PAYMENT_METHOD_UPDATE_ALERT,
  payload,
});
