import { ActionTypes, Actions } from './types';
import { AnalyticsEvent, DataLayerInterface } from './types';

export const setDLAction = (payload: Partial<DataLayerInterface>): Actions<Partial<DataLayerInterface>> => ({
  type: ActionTypes.SET_DL,
  payload,
});

export const sendEventAction = (payload: AnalyticsEvent): Actions<AnalyticsEvent> => ({
  type: ActionTypes.SEND_EVENT,
  payload,
});
