import React from 'react';
import './Header.scss';
import { ReactComponent as Logo } from '../../assets/images/vestiairecollective_logo.svg';
import { getFenXOrigin } from '../../helpers';
import Icon from '../Icons/Icon';
import { useTranslation } from 'react-i18next';

function Header(): JSX.Element {
  const homepage = getFenXOrigin();
  const { t } = useTranslation();
  return (
    <header className="header">
      <div className="innerContainer">
        <a className="header__link" href={homepage} data-testid="header_logo_link">
          <Logo />
        </a>
        <div className="header__secured">
          <Icon name="lock" />
          <span>{t('HEADER.SECURE_CHECKOUT', 'Secure Checkout')}</span>
        </div>
      </div>
    </header>
  );
}

export default Header;
