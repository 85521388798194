import { voucherActions, voucherSelectors, VoucherTypes } from '../../../features/voucher';
import { createElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import VoucherListUI from './VoucherListUI';
import VoucherListSkeletonUI from './VoucherListSkeletonUI';

type VoucherListProps = {
  Component?: string;
};

const VoucherList: FC<VoucherListProps> = ({ Component = 'li' }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const voucher = useAppSelector(voucherSelectors.getCode);
  const reduction = useAppSelector(voucherSelectors.getReductions);
  const isVoucherLoading = useAppSelector(voucherSelectors.getStatus).startsWith('PEND');
  const onRemoveVoucher = (voucherCode: VoucherTypes.Entity) => {
    if (voucherCode.code) {
      dispatch(voucherActions.removeRequest(voucherCode));
    }
  };
  if (isVoucherLoading) {
    return createElement(VoucherListSkeletonUI);
  } else {
    return voucher?.code
      ? createElement(Component, {}, createElement(VoucherListUI, { voucher, t, reduction, onRemoveVoucher }))
      : null;
  }
};

export default VoucherList;
