import { Action, InferAction, Nullable, WithStatusType } from '../../types';

export type Dictionary = {
  country: CountryList;
  language: LanguageList;
  currency: CurrencyList;
};
export type LanguageList = {
  title: string;
  list: Language[];
};
export type Language = {
  isoCode: string;
  name: string;
};
export type CurrencyList = {
  title: string;
  list: ICurrency[];
};
export interface ICurrency {
  id: string;
  isoCode: string;
  name: string;
  symbol: string;
}
export type CountryList = {
  title: string;
  fullList: Country[];
  shortLists: Country[];
};
export type Country = {
  id: string;
  idRegion: string;
  isoCode: string;
  name: string;
};

export type CC = {
  CC: string;
  displayName: {
    fr: Nullable<string>;
    en: Nullable<string>;
    de: Nullable<string>;
    us: Nullable<string>;
    it: Nullable<string>;
    es: Nullable<string>;
  };
};

export type CK = {
  siteId: string;
  language: string;
  currency: string;
};

export interface ILocalization {
  country: string;
  language: string;
  currency: string;
  siteId?: string;
}

export type State = WithStatusType & {
  country: string;
  siteId: string;
  currency: string;
  language: string;
  dict: Nullable<Dictionary>;
};

export type MetaData = {
  withReason: boolean;
};

export type Type = Pick<State, 'country' | 'currency' | 'language' | 'siteId'>;
export type SelectRequestType = Pick<State, 'country' | 'currency' | 'language' | 'siteId'>;

export enum StatusEnum {
  IDLE = 'IDLE',
  OK_SET_ITEM = 'OK_SET_ITEM',
  ERROR_SET_ITEM = 'ERROR_SET_ITEM',
  PEND_SET_ITEM = 'PEND_SET_ITEM',
  PEND_GET_LIST = 'PEND_GET_LIST',
  OK_GET_LIST = 'OK_GET_LIST',
  ERROR_GET_LIST = 'ERROR_GET_LIST',
  PEND_REASON_GET_LIST = 'PEND_REASON_GET_LIST',
  OK_REASON_GET_LIST = 'OK_REASON_GET_LIST',
  ERROR_REASON_GET_LIST = 'ERROR_REASON_GET_LIST',
}

export enum ActionTypes {
  SET_I18N = 'i18n/persist in cookies',
  GET_I18N = 'i18n/get from cookies',
  SELECT_I18N_REQUEST = 'i18n/select i18n request',
  SELECT_I18N_RESOLVE = 'i18n/select i18n resolve',
  SELECT_I18N_REJECT = 'i18n/select i18n reject',
  GET_I18N_LIST_REQUEST = 'i18n/get full list request',
  GET_I18N_LIST_RESOLVE = 'i18n/get full list resolve',
  GET_I18N_LIST_REJECT = 'i18n/get full list reject',
}

export type PayloadTypes = {
  [ActionTypes.SET_I18N]: Type;
  [ActionTypes.GET_I18N]: undefined;
  [ActionTypes.SELECT_I18N_REQUEST]: SelectRequestType;
  [ActionTypes.SELECT_I18N_RESOLVE]: undefined;
  [ActionTypes.SELECT_I18N_REJECT]: undefined;
  [ActionTypes.GET_I18N_LIST_REQUEST]: undefined | GetLocalizationOptionsType;
  [ActionTypes.GET_I18N_LIST_RESOLVE]: Dictionary;
  [ActionTypes.GET_I18N_LIST_REJECT]: undefined;
};

export type Actions = Action<PayloadTypes, MetaData>[keyof Action<PayloadTypes, MetaData>];
export type InferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T, MetaData>;
export type GetLocalizationOptionsType = Partial<Pick<State, 'siteId' | 'language'>>;
