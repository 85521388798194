import React, { memo } from 'react';
import './SumupProductList.scss';
import { useGetOrderlines } from '../../../features/order-lines/hooks';
import SumupProduct from '../SumupProduct/SumupProduct';

const SumupProductList: React.FC = () => {
  const { orderLines, orderLineIds, isLoading } = useGetOrderlines();

  return (
    <ul className="spl">
      {!isLoading && orderLines
        ? orderLineIds?.map(
            (orderLineId) =>
              orderLines[orderLineId].product && (
                <li key={orderLines[orderLineId].product.id}>
                  <SumupProduct product={orderLines[orderLineId].product} />
                </li>
              )
          )
        : [1].map((item) => <div className="ssc ssc-line spl" key={item}></div>)}
    </ul>
  );
};

export default memo(SumupProductList);
