import { toast, ToastOptions } from 'react-toastify';
import i18n from '../i18n';

const defaultOption: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: 'vc-text-s',
};

export default {
  error: (err: string): void => {
    let message = i18n.t('ERRORS.GENERIC_MESSAGE', 'Ooops ! Something went wrong');

    if (typeof err === 'string') {
      message = err;
    }
    toast.error(message, defaultOption);
  },
};
