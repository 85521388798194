import React from 'react';
import { useTranslation } from 'react-i18next';
import './CheckoutFallback.scss';
import LineItemLoader from '../../../components/LineItem/LineItemLoader';
import DeliveryOptionsSkeleton from '../../../components/DeliveryOptions/DeliveryOptionsSkeleton';
import BillingAddressSkeleton from '../../../components/DeliveryOptions/BillingAddress/BillingAddressSkeleton';
import PaymentMethodsListSkeleton from '../../../components/Payment/PaymentMethodsList/PaymentMethodsListSkeleton';

const CheckoutFallback: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="checkoutSection__title">{t('CHECKOUT.TITLES.BAG', 'Bag')}</h2>
      <LineItemLoader />
      <h2 className="checkoutSection__title">{t('CHECKOUT.TITLES.SHIPPING', 'Shipping')}</h2>
      <DeliveryOptionsSkeleton />
      <BillingAddressSkeleton />
      <h2 className="checkoutSection__title">{t('CHECKOUT.TITLES.PAYMENT', 'Payment')}</h2>
      <PaymentMethodsListSkeleton />
    </div>
  );
};

export default CheckoutFallback;
