import type { RootState } from '../../store';
import { Entity, DraftAddress, StatusEnum } from './types';
import { cartSelectors, CartTypes } from '../cart';

export const getSelectedItem = (state: RootState): Entity | undefined => {
  return cartSelectors.getDeliveryMethod(state) === CartTypes.DeliveryMethods.COLLECTION_POINT
    ? state.collectionPoints.list.filter((x) => x.id === state.collectionPoints.selected)[0] ||
        state.collectionPoints.list.filter((x) => x.selected)[0]
    : undefined;
};

export const getLastSelectedItemId = ({ collectionPoints, cart }: RootState): string =>
  cart.deliverySelectedMethod === CartTypes.DeliveryMethods.COLLECTION_POINT ? collectionPoints.lastSelected : '';

export const getList = ({ collectionPoints }: RootState): Entity[] => collectionPoints.list;

export const getDraft = ({ collectionPoints }: RootState): Entity[] => collectionPoints.draftList;

export const getStatus = ({ collectionPoints }: RootState) => collectionPoints.status as StatusEnum;

export const getDraftAddress = ({ collectionPoints }: RootState): DraftAddress | undefined =>
  collectionPoints.draftAddress;

export const getItemDraftSelected = ({ collectionPoints }: RootState) => collectionPoints.draftSelected;

export const getLastSelectedItem = (state: RootState): Entity | undefined =>
  state.collectionPoints.list.filter((x) => x.id === getLastSelectedItemId(state))[0] || undefined;

export const getConfirmedDraftAddress = (state: RootState): DraftAddress | undefined =>
  state.collectionPoints.confirmedDraftAddress;
