import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootSaga from './sagas';

import { cartReducer } from '../features/cart';
import { orderLinesReducer } from '../features/order-lines';
import { addressReducer } from '../features/address';
import { collectionPointsReducer } from '../features/collection-points';
import { paymentReducer } from '../features/payment';
import { authReducer } from '../features/auth';
import { i18nReducer } from '../features/i18n';
import { trackingReducer } from '../features/tracking';
import { uiReducer } from '../features/ui';
import { bucketTestReducer } from '../features/bucket-test';
import { voucherReducer } from '../features/voucher';
import { subtotalsReducer } from '../features/subtotals';

export const rootReducer = combineReducers({
  address: addressReducer.reducer,
  auth: authReducer.reducer,
  bucketTest: bucketTestReducer.reducer,
  cart: cartReducer.reducer,
  collectionPoints: collectionPointsReducer.reducer,
  i18n: i18nReducer.reducer,
  orderLines: orderLinesReducer.reducer,
  subtotals: subtotalsReducer.reducer,
  payment: paymentReducer.reducer,
  tracking: trackingReducer.reducer,
  ui: uiReducer.reducer,
  voucher: voucherReducer.reducer,
});
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
