import { State } from './types';
import { Actions, ActionTypes } from './types';
import { Service } from './services';

const initialState: State = {
  datalayer: Service.updateDataLayer({ platform: 'checkout_project' }),
};

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_DL: {
      return {
        ...state,
        datalayer: { ...state.datalayer, ...action.payload },
      };
    }
    default:
      return state;
  }
};
