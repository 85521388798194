import { Entities } from '@vestiaire/api-specification';
import cookie from 'react-cookies';
import * as uuid from 'uuid';
import type { CookieSerializeOptions } from 'cookie';
import environment from '../../environments';
import { AuthTypes } from '.';
import { Api } from '../../services/api';
import logger from '../../services/logger.service';
import { I18nTypes } from '../i18n';

export const updateSettings = (settings: I18nTypes.ILocalization): Promise<I18nTypes.ILocalization> => {
  return new Api()
    .patch<I18nTypes.ILocalization>(`/users/me/settings`, settings)
    .then(({ data }) => data || Promise.reject(new Error('Error: user localisation settings did not updated')))
    .catch((e) => {
      throw e;
    });
};

export const fetchUserInformation = (id: string): Promise<Entities.User> => {
  return new Api()
    .get<Entities.User>(`/users/${id}`)
    .then(({ data }) => data)
    .catch((e) => {
      throw e;
    });
};

export const getSession = (): string => {
  return cookie.load(environment.sessionCookie) || '';
};

export const setSession = (payload: AuthTypes.State, options: CookieSerializeOptions = {}): boolean => {
  try {
    cookie.save(environment.sessionCookie, payload.id || '', {
      ...environment.cookieOptions,
      ...options,
    });
    return true;
  } catch (e) {
    logger.error('auth::saveSessionCookie', e);
    return false;
  }
};

export const setUserId = (userId: string, options: CookieSerializeOptions = {}): boolean => {
  try {
    cookie.save(environment.userIdCookie, userId, {
      ...environment.cookieOptions,
      ...options,
    });
    return true;
  } catch (e) {
    logger.error('auth::saveUserIdCookie', e);
    return false;
  }
};

export const clearSession = (): boolean => {
  try {
    cookie.remove(environment.sessionCookie);
    return true;
  } catch (e) {
    logger.error('auth::removeSessionCookie', e);
    return false;
  }
};

export const getUserId = (): string => cookie.load(environment.userIdCookie) || '';

export const getOrGenerateAnonymousId = (): string => {
  let anonymousId = cookie.load(environment.cookieAnonymousUser);
  if (anonymousId) {
    return anonymousId;
  }
  anonymousId = uuid.v4();
  const options = {
    ...environment.cookieOptions,
    expires: new Date(Date.now() + 13 * 30 * 86400 * 1000),
  };
  cookie.save(environment.cookieAnonymousUser, anonymousId, options);
  return anonymousId;
};

export const getUserIdFromAuthToken = (): string => {
  const [userIdCookie] = getSession().split('.'); // extracted user id from ezbsession.
  const userUID = getUserId(); // user from other key in the cookies.
  // clients are logged in if both keys be exist.
  return userUID === userIdCookie ? userIdCookie : '';
};

export const getOrGenerateRandomId = (): string => {
  let randomId = cookie.load(environment.cookieRandomId);
  if (randomId) {
    return randomId;
  }
  randomId = `anonymous_${Math.floor(Math.random() * 1000000) + 1}`;
  const options = {
    ...environment.cookieOptions,
    expires: new Date(Date.now() + 13 * 30 * 86400 * 1000),
  };
  cookie.save(environment.cookieRandomId, randomId, options);
  return randomId;
};
