import {
  GetLocalizationOptionsType,
  Actions,
  ActionTypes,
  Dictionary,
  MetaData,
  Type,
  SelectRequestType,
} from './types';

export const set = (payload: Type): Actions => ({
  type: ActionTypes.SET_I18N,
  payload,
});

export const selectRequest = (payload: SelectRequestType): Actions => ({
  type: ActionTypes.SELECT_I18N_REQUEST,
  payload,
});

export const selectResolve = (): Actions => ({
  type: ActionTypes.SELECT_I18N_RESOLVE,
});

export const selectReject = (): Actions => ({
  type: ActionTypes.SELECT_I18N_REJECT,
});

export const getListRequest = (payload?: GetLocalizationOptionsType, meta?: MetaData): Actions => ({
  type: ActionTypes.GET_I18N_LIST_REQUEST,
  payload,
  meta,
});

export const getListResolve = (payload: Dictionary, meta?: MetaData): Actions => ({
  type: ActionTypes.GET_I18N_LIST_RESOLVE,
  payload,
  meta,
});

export const getListReject = (payload?: undefined, meta?: MetaData): Actions => ({
  type: ActionTypes.GET_I18N_LIST_REJECT,
  meta,
});
