import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './Subtotals.scss';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icons/Icons';
import { subtotalsSelectors } from '../../features/subtotals';
import { useAppSelector } from '../../app/hooks';
import { addressSelectors } from '../../features/address';
import SumupProductList from '../Sumup/SumupProductList/SumupProductList';
import SubtotalItem from '../SubtotalItem/SubtotalItem';
import { CartTypes, cartSelectors } from '../../features/cart';
import SubtotalDetails from '../SubtotalDetails/SubtotalDetails';
import VoucherForm from '../Voucher/VoucherForm';
import VoucherList from '../Voucher/VoucherList';

type SubtotalsPropsTypes = {
  isSticky?: boolean;
};

const Subtotals: React.FC<SubtotalsPropsTypes> = ({ isSticky = false }: SubtotalsPropsTypes) => {
  const { t } = useTranslation();
  const subtotals = useSelector(subtotalsSelectors.getRoot);
  const deliveryAddress = useAppSelector(addressSelectors.getDelivery);
  const orderlineIds = useSelector(cartSelectors.getOrderLineIds);
  const cartStatus = useAppSelector(cartSelectors.getStatus);
  const isBillingAddressMissing = cartStatus === CartTypes.StatusEnum.ERROR_REASON_NO_BILLING_ADDRESS;

  const dutiesTooltip = () => {
    return (
      <Tooltip
        trigger={
          <button className="subtotals__entry__tooltip__icon" data-testid="summary_duties_tooltip" type="button">
            <Icon name="info" />
            <span className="visuallyhidden">{t('SUMMARY.DUTIES.TOOLTIP.ALT', 'know more')}</span>
          </button>
        }
        position={['top center', 'bottom center', 'left center']}
        id="subtotals_duties"
      >
        <Trans t={t} i18nKey="SUMMARY.DUTIES.TOOLTIP.CONTENT">
          One or more of your items is located in a different country. International duties and taxes will be applied to
          import them.
          <a
            className="subtotals__entry__tooltip__link"
            href={t(
              'SUMMARY.DUTIES.TOOLTIP.LINK',
              'https://faq.vestiairecollective.com/hc/en-us/articles/208748709-How-much-are-the-import-fees-and-taxes-'
            )}
            target="_blank"
            rel="noreferrer"
          >
            More Info
          </a>
        </Trans>
      </Tooltip>
    );
  };

  if (!orderlineIds) return null;

  return (
    <ul className="subtotals" data-testid="summary">
      <SubtotalItem
        testId="summary_subtotal"
        title={`${t('SUMMARY.PRICE_DETAILS', 'Price details')} ${
          orderlineIds && t('SUMMARY.X_ITEMS', { count: orderlineIds?.length })
        }`}
        amount={subtotals.itemsAmount?.formatted}
        noToggle={isSticky}
        trackingName="price_details"
      >
        <SumupProductList />
      </SubtotalItem>
      <li>
        <div className="sumup__voucher">
          <VoucherForm />
          <VoucherList Component="div" />
        </div>
      </li>
      <SubtotalItem
        testId="summary_shipping"
        title={t(`SUMMARY.SHIPPING_FEES${!deliveryAddress ? '_ESTIMATED' : ''}`, 'Shipping fees')}
        amount={subtotals.shippingAmount?.formatted || t('SHIPPING.FREE', 'Free')}
        trackingName="shipping_fee"
      >
        {orderlineIds.length > 1 && subtotals.shippingAmount?.details && (
          <SubtotalDetails details={subtotals.shippingAmount.details} />
        )}
      </SubtotalItem>
      {!!subtotals.buyerFeesAmount?.cents && (
        <SubtotalItem
          testId="summary_authentication"
          title={t('SUMMARY.CONTROL_AND_AUTHENTICATION', 'Control and authentication')}
          amount={subtotals.buyerFeesAmount?.formatted}
          trackingName="authentication_fee"
        >
          {orderlineIds.length > 1 && subtotals.buyerFeesAmount.details && (
            <SubtotalDetails details={subtotals.buyerFeesAmount.details} />
          )}
        </SubtotalItem>
      )}
      {!!subtotals.dutiesAmount?.cents && (
        <SubtotalItem
          testId="summary_duties"
          title={t('SUMMARY.DUTIES.TITLE', 'Import Duties and Taxes')}
          tooltip={dutiesTooltip}
          amount={subtotals.dutiesAmount.formatted}
        ></SubtotalItem>
      )}
      {!!subtotals.salesTaxAmount?.cents && isBillingAddressMissing && (
        <SubtotalItem
          testId="summary_salestax"
          title={t('SUMMARY.SALES_TAX_ESTIMATED', 'Sales tax estimated')}
          amount="--"
        ></SubtotalItem>
      )}
      {!!subtotals.salesTaxAmount?.cents && !isBillingAddressMissing && (
        <SubtotalItem
          testId="summary_salestax"
          title={t('SUMMARY.SALES_TAX', 'Sales tax')}
          amount={subtotals.salesTaxAmount?.formatted}
        ></SubtotalItem>
      )}
    </ul>
  );
};

export default memo(Subtotals);
