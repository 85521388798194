import { Actions, ActionTypes, State, StatusEnum } from './types';
import { getCountry, getCurrency, getLanguage, getSiteId } from './services';

export const initialState: State = {
  status: StatusEnum.IDLE,
  country: getCountry(),
  siteId: getSiteId(),
  language: getLanguage(),
  currency: getCurrency(),
  dict: null,
};

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_I18N:
      return { ...state, ...action.payload, status: StatusEnum.OK_SET_ITEM };

    case ActionTypes.SELECT_I18N_REQUEST:
      return { ...state, ...action.payload, status: StatusEnum.PEND_SET_ITEM };

    case ActionTypes.SELECT_I18N_RESOLVE:
      return { ...state, status: StatusEnum.OK_SET_ITEM };

    case ActionTypes.SELECT_I18N_REJECT:
      return { ...state, status: StatusEnum.ERROR_SET_ITEM };

    case ActionTypes.GET_I18N_LIST_REQUEST:
      if (action.meta?.withReason) {
        return { ...state, status: StatusEnum.PEND_REASON_GET_LIST };
      }
      return { ...state, status: StatusEnum.PEND_GET_LIST };

    case ActionTypes.GET_I18N_LIST_RESOLVE:
      if (action.meta?.withReason) {
        return { ...state, dict: action.payload, status: StatusEnum.OK_REASON_GET_LIST };
      }
      return { ...state, dict: action.payload, status: StatusEnum.OK_GET_LIST };

    case ActionTypes.GET_I18N_LIST_REJECT:
      if (action.meta?.withReason) {
        return { ...state, status: StatusEnum.ERROR_REASON_GET_LIST };
      }
      return { ...state, status: StatusEnum.ERROR_GET_LIST };

    default:
      return state;
  }
};
