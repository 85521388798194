import { Action } from 'redux';
import { OrderLine } from '../../types';

export enum ActionTypes {
  SET_ORDER_LINES = 'SET_ORDER_LINES',
  REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM',
  UNDO_REMOVE_LINE_ITEM = 'UNDO_REMOVE_LINE_ITEM',
  GET_ORDER_LINES = 'GET_ORDER_LINES',
  UPDATE_SHIPPING_PLAN = 'UPDATE_SHIPPING_PLAN',
  RESET_ORDER = 'RESET_ORDER',
  ENQUIRY_ORDER_LINES = 'ENQUIRY_ORDER_LINES',
}

export interface Actions<T = any> extends Action<ActionTypes> {
  payload?: T;
}

export interface State {
  [key: string]: OrderLine;
}

export interface IGetLineItem {
  lineItem: OrderLine | undefined;
}
