import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './PaymentTcAcceptance.scss';

function PaymentTcAcceptance() {
  const { t } = useTranslation();
  return (
    <p className="PaymentTcAcceptance" data-testid="payment_TcAcceptance">
      <Trans t={t} i18nKey="PAYMENT.GDPR.ACCEPT_TERMS_AND_CONDITIONS">
        Total includes{' '}
        <a
          className="summary__entry__tooltip__link"
          data-testid="payment_buyer_services_fees_link"
          href={t('PAYMENT.GDPR.BUYER_SERVICES_FEE.LINK', 'https://www.vestiairecollective.com/cgu2.shtml')}
          target="_blank"
          rel="noreferrer"
        >
          buyer service fee
        </a>{' '}
        and{' '}
        <a
          className="summary__entry__tooltip__link"
          data-testid="payment_buyer_services_fees_link"
          href={t('PAYMENT.GDPR.CURRENCY_CONVERSION_FEE.LINK', 'https://www.vestiairecollective.com/cgu2.shtml')}
          target="_blank"
          rel="noreferrer"
        >
          currency conversion fee
        </a>{' '}
        (if applicable). By placing your order, you agree to our{' '}
        <a
          className="summary__entry__tooltip__link"
          data-testid="payment_terms_and_conditions_link"
          href={t('PAYMENT.GDPR.TERMS_AND_CONDITIONS.LINK', 'https://www.vestiairecollective.com/cgu2.shtml')}
          target="_blank"
          rel="noreferrer"
        >
          Buyer Terms & Conditions
        </a>
        .
      </Trans>
    </p>
  );
}

export default PaymentTcAcceptance;
