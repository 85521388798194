import { all } from 'redux-saga/effects';
import { orderLinesSagas } from '../features/order-lines';
import { addressSagas } from '../features/address';
import { paymentSagas } from '../features/payment';
import { authSagas } from '../features/auth';
import { i18nSagas } from '../features/i18n';
import { cartSagas } from '../features/cart';
import { trackingSagas } from '../features/tracking';
import { collectionPointsSagas } from '../features/collection-points';
import { bucketTestSagas } from '../features/bucket-test';
import { voucherSagas } from '../features/voucher';

export default function* rootSaga(): Generator<any> {
  yield all([
    addressSagas.sagas(),
    authSagas.sagas(),
    bucketTestSagas.sagas(),
    cartSagas.sagas(),
    orderLinesSagas.sagas(),
    paymentSagas.sagas(),
    i18nSagas.sagas(),
    trackingSagas.sagas(),
    collectionPointsSagas.sagas(),
    voucherSagas.sagas(),
  ]);
}
