import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { paymentSelectors, PaymentTypes, paymentActions } from '../../../features/payment';
import Modal from '../../Modal/Modal';
import { ReactComponent as Logo } from '../../../assets/images/vestiairecollective_logo.svg';
import Icon from '../../Icons/Icons';
import './PaymentErrorPopin.scss';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../helpers/routes';
import { paymentServices } from '../../../features/payment';
import { uiActions } from '../../../features/ui';
import logger from '../../../services/logger.service';
import { bucketTestSelectors } from '../../../features/bucket-test';

const PaymentErrorPopin: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const paymentStatus = useAppSelector(paymentSelectors.getPaymentStatus);
  const reduxErrorMessage = useAppSelector(paymentSelectors.getErrorMessage);
  const routeErrorKey = new URLSearchParams(window.location.search)?.get('message');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [cleanRouteCode, setCleanRouteCode] = useState(false);
  const [status, setStatus] = useState<PaymentTypes.ErrorPopinStatus | undefined>();
  const [, routeStatus] = location.pathname.replace(/\/$/, '').split('/checkout/') as [
    string,
    PaymentTypes.ErrorPopinStatus | undefined
  ];
  const { checkoutErrorMessages: enableErrorMessages } = useAppSelector(bucketTestSelectors.getFlags);

  const resetStatus = useCallback(() => {
    dispatch(uiActions.setCheckoutOverlay(false));
    dispatch(paymentActions.resetPaymentStatus());
    setStatus(undefined);
    setCleanRouteCode(!!routeStatus);
    setErrorMessage(undefined);
  }, [dispatch, routeStatus]);

  const popinTranslations: Record<PaymentTypes.ErrorPopinStatus, { [key: string]: string }> = useMemo(
    () => ({
      [PaymentTypes.ErrorPopinStatus.CANCELLED]: {
        title: t('PAYMENT_POPIN.CANCELED.TITLE', "You're almost there!"),
        message: t(
          'PAYMENT_POPIN.CANCELED.MESSAGE',
          'Sorry, your payment was interrupted. Please try again or another payment method, and it should go through perfectly.'
        ),
        cta: t('PAYMENT_POPIN.ERROR.CTA', 'Try again'),
      },
      [PaymentTypes.ErrorPopinStatus.REFUSED]: {
        title: t('PAYMENT_POPIN.REFUSED.TITLE', "You're almost there!"),
        message: t(
          'PAYMENT_POPIN.REFUSED.MESSAGE',
          'Sorry, there was an issue with your payment. Please double-check your account details or try again with another payment method, and it should go through perfectly.'
        ),
        cta: t('PAYMENT_POPIN.ERROR.CTA', 'Try again'),
      },
      [PaymentTypes.ErrorPopinStatus.ERROR]: {
        title: t('PAYMENT_POPIN.ERROR.TITLE', "You're almost there!"),
        message: t(
          'PAYMENT_POPIN.ERROR.MESSAGE',
          'Sorry, there was an error with your payment. Please try again or another payment method, and it should go through perfectly.'
        ),
        cta: t('PAYMENT_POPIN.ERROR.CTA', 'Try again'),
      },
    }),
    [t]
  );

  const getErrorMessage = useCallback(() => {
    /* TO DO LATER : payment error messages in query params
     * download checkout-error-messages from phraseapp + send hash key from fenX
     * const hasValidRouteErrorMessage = enableErrorMessages && routeErrorKey && t(routeErrorKey);
     * if (hasValidRouteErrorMessage) return validRouteErrorMessage;
     */

    const hasValidReduxErrorMessage = !!(enableErrorMessages && reduxErrorMessage);
    if (hasValidReduxErrorMessage) return reduxErrorMessage;

    if (status) return popinTranslations[status]?.message;
  }, [reduxErrorMessage, status, popinTranslations, enableErrorMessages]);

  /* set payment status (cancel / refused / error)
   ** 1. in case of 3DS redirection the error status is returned from url path
   ** 2. otherwise the `Process` api will return the status stored in redux
   */
  useEffect(() => {
    const loggingCodes = new Set([
      PaymentTypes.ErrorPopinStatus.CANCELLED,
      PaymentTypes.ErrorPopinStatus.REFUSED,
      PaymentTypes.ErrorPopinStatus.ERROR,
    ]);
    const displayCodes = new Set([PaymentTypes.ErrorPopinStatus.REFUSED, PaymentTypes.ErrorPopinStatus.ERROR]);
    const unifiedCodeName =
      routeStatus && loggingCodes.has(routeStatus)
        ? routeStatus
        : paymentServices.mapPaymtStatusToPopinStatus.get(paymentStatus);

    /* The cancel event is logged in datadog but not displayed to the user */
    if (unifiedCodeName) {
      logger.error(`payment::error::${unifiedCodeName}`);
      if (displayCodes.has(unifiedCodeName)) setStatus(unifiedCodeName);
      if (unifiedCodeName === PaymentTypes.ErrorPopinStatus.CANCELLED) {
        resetStatus();
      }
    }
  }, [dispatch, resetStatus, paymentStatus, routeStatus]);

  /* set error message */
  useEffect(() => {
    let finalErrorMessage = getErrorMessage();

    if (!finalErrorMessage || finalErrorMessage === 'no_validated_error_message_found') {
      finalErrorMessage = popinTranslations[PaymentTypes.ErrorPopinStatus.ERROR].message;
    }

    setErrorMessage(finalErrorMessage);
  }, [getErrorMessage, routeErrorKey, reduxErrorMessage, status, popinTranslations]);

  if (cleanRouteCode) {
    return <Redirect to={ROUTES.CHECKOUT} />;
  }

  if (!status) return null;

  return (
    <Modal open={true} onModalClose={resetStatus} id={`payment_popin_${status}`}>
      <div className="popinErrorPayment">
        <Logo className="popinErrorPayment__logo" />
        <Icon name="issue" classes="popinErrorPayment__icon" />
        <h1 className="popinErrorPayment__title">{popinTranslations[status].title}</h1>
        <p className="popinErrorPayment__message">{errorMessage}</p>
        <button
          className="vc-btn vc-btn--primary vc-btn--medium popinErrorPayment__cta"
          data-testid={`payment_popin_${status}_retry_button`}
          onClick={resetStatus}
        >
          {popinTranslations[status].cta}
        </button>
      </div>
    </Modal>
  );
};

export default PaymentErrorPopin;
