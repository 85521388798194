import React, { ComponentProps, PropsWithChildren } from 'react';
import './Card.scss';

export type CardProps = PropsWithChildren<ComponentProps<'div'>> & {
  header: React.ReactNode;
};

const Card = ({ className, header, children }: CardProps): JSX.Element => {
  return (
    <div className={`card ${className}`}>
      {header && <div className="card__header">{header}</div>}
      <div className="card__body">{children}</div>
    </div>
  );
};

export default Card;
