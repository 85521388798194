import { useAppDispatch, useAppSelector } from '../../../app/hooks/index';
import { SyntheticEvent, createElement, FC, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VoucherFormUI from './VoucherForm';
import { voucherActions, voucherSelectors } from '../../../features/voucher';
import { trackingHooks } from '../../../features/tracking';
import { CartTypes, cartSelectors } from '../../../features/cart';
import { StatusEnum } from '../../../features/voucher/types';
import { uiSelectors } from '../../../features/ui';

const VoucherForm: FC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const voucher = useAppSelector(voucherSelectors.getRoot);
  const voucherStatus = useAppSelector(voucherSelectors.getStatus);
  const isVoucherLoading = useAppSelector(voucherSelectors.getStatus).startsWith('PEND');
  const [inputSubmitable, setIsInputSubmitable] = useState(false);
  const { sendEvent } = trackingHooks.useAnalytics();
  const cartStatus = useAppSelector(cartSelectors.getStatus);
  const isBillingAddressMissing = cartStatus === CartTypes.StatusEnum.ERROR_REASON_NO_BILLING_ADDRESS;
  const isSummaryLoading = useAppSelector(uiSelectors.getSummaryLoading);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(voucherActions.setRequest({ code: ref.current?.value || '' }));
    sendEvent('checkout_promo_code', {
      label: 'promo_code',
      property: ref.current?.value.toString(),
    });
  };

  const onInput = () => {
    setIsInputSubmitable(!!((ref.current?.value.length || 0) > 3));
  };

  useEffect(() => {
    if (!voucher) return;
    if (ref.current) {
      ref.current.value = voucher.code || '';
    }
    if (voucherStatus === StatusEnum.OK_SET_ITEM) {
      sendEvent('checkout_promo_code', { label: 'promo_code_success', property: voucher.code });
    }
    if (voucherStatus === StatusEnum.ERROR_SET_ITEM) {
      sendEvent('checkout_promo_code', { label: 'promo_code_failure', property: voucher.code });
    }
    // we don't wanna run this effect on voucher.error changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherStatus, sendEvent]);

  return createElement(VoucherFormUI, {
    t,
    ref,
    onSubmit,
    onInput,
    disabled: isBillingAddressMissing || isVoucherLoading || isSummaryLoading,
    submitable: inputSubmitable,
    voucher,
    loading: isVoucherLoading,
    hint: isBillingAddressMissing
      ? t('VOUCHER_FORM.BILLING_ADDRESS_MISSING', 'To apply promo code, add your shipping address')
      : '',
  });
};

export default VoucherForm;
