import { Entities } from '@vestiaire/api-specification';
import { Action, InferAction, WithStatusType } from '../../types/index';

export type Legacy = Entities.Address;
export type Entity = Entities.Addressv2Address;
export type ListRow = Entities.Addressv2AddressListRow;

export enum Type {
  DELIVERY = 'delivery',
  BILLING = 'billing',
}

export enum CtaType {
  EDIT = 'edit',
  ADD = 'add',
}

export enum StatusEnum {
  IDLE = 'IDLE',
  PEND_GET_LIST = 'PEND_GET_LIST',
  OK_GET_LIST = 'OK_GET_LIST',
  ERROR_GET_LIST = 'ERROR_GET_LIST',
  PEND_SET_ITEM = 'PEND_SET_ITEM',
  OK_SET_ITEM = 'OK_SET_ITEM',
  ERROR_SET_ITEM = 'ERROR_SET_ITEM',
}

export type State = WithStatusType & {
  list: ListRow[];
  selectedBillingAddressId?: number;
  selectedDeliveryAddressId?: number;
  lastSelectedDeliveryAddressId?: number;
  lastSelectedBillingAddressId?: number;
  isBillingSameAsDelivery: boolean;
};

export type ApiResponse = ListRow[];

export enum ActionTypes {
  FETCH = 'address/fetch',
  RESOLVE = 'address/resolveFetch',
  REJECT = 'address/rejectFetch',
  RETRY = 'address/retryFetch',

  SET_BILLING_ADDRESS_REQUEST = 'address/setBillingRequest',
  SET_BILLING_ADDRESS_RESOLVE = 'address/setBillingResolve',
  SET_BILLING_ADDRESS_REJECT = 'address/setBillingReject',
  SET_BILLING_ADDRESS_RETRY = 'address/setBillingRetry',

  SET_BILLING_SAME_AS_DELIVERY = 'address/setBillingSameAsDelivery',
  SET_DELIVERY_METHOD_SHIPPING_ADDRESS = 'address/setDeliveryMethodShipping',

  CHECK_AVAILABILITY = 'address/checkAvailability',
}

export type PayloadTypes = {
  [ActionTypes.FETCH]: undefined;
  [ActionTypes.RESOLVE]: ApiResponse;
  [ActionTypes.REJECT]: string;

  [ActionTypes.SET_BILLING_ADDRESS_REQUEST]: number;
  [ActionTypes.SET_BILLING_ADDRESS_RESOLVE]: undefined;
  [ActionTypes.SET_BILLING_ADDRESS_REJECT]: undefined;

  [ActionTypes.SET_BILLING_SAME_AS_DELIVERY]: boolean;
  [ActionTypes.CHECK_AVAILABILITY]: undefined;
  [ActionTypes.SET_DELIVERY_METHOD_SHIPPING_ADDRESS]: Entity['addressId'];
};

export type Actions = Action<PayloadTypes>[keyof Action<PayloadTypes>];
export type InferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T>;
