import { call, takeLatest, ForkEffect, put, SagaReturnType, select } from 'redux-saga/effects';
import { set as setAction, setUser } from './actions';
import { clearSession, fetchUserInformation, getSession, setSession, setUserId } from './services';
import logger from '../../services/logger.service';
import { Actions, ActionTypes, State } from './types';
import { authTokenSelector } from './selectors';
import { uiActions } from '../ui';

function* set({ payload }: Actions<State>) {
  try {
    const [userId = '', , jwtPayload = ''] = (payload?.id || '').split('.');
    const { exp = 0 } = JSON.parse(atob(jwtPayload) || '{}');
    yield call(setSession, payload || {}, { expires: new Date(exp * 1000) });
    yield call(setUserId, userId, { expires: new Date(exp * 1000) });
  } catch (e) {
    yield call(clearSession);
    logger.error('auth::set', e);
  }
}

function* get() {
  try {
    const authToken: ReturnType<typeof getSession> = yield call(getSession);
    if (authToken) {
      yield put(setAction({ id: authToken }));
    } else {
      yield call(clearSession);
    }
  } catch (e) {
    yield call(clearSession);
    logger.error('auth::get', e);
  }
}

function* getUser() {
  try {
    const authToken: string = yield select(authTokenSelector);
    const [userId] = authToken.split('.');
    const userInfo: SagaReturnType<typeof fetchUserInformation> = yield call(fetchUserInformation, userId);
    if (userInfo) {
      yield put(setUser(userInfo));
    }
  } catch (e: any) {
    logger.error('auth::getUser', e);
    if (401 === e?.errors?.status) {
      yield call(clearSession);
      yield put(uiActions.setAuthModal({ isOpen: true }));
    }
  }
}

export function* sagas(): Generator<ForkEffect<Actions<State>>> {
  yield takeLatest(ActionTypes.SET_AUTH, set);
  yield takeLatest(ActionTypes.GET_AUTH, get);
  yield takeLatest(ActionTypes.GET_USER, getUser);
}
