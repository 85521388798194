import { Entities } from '@vestiaire/api-specification';
import { AxiosResponse } from 'axios';

export interface OrderLineWithStatus extends Entities.OrderLine {
  status?: string;
}

export type OrderLine = OrderLineWithStatus;

export type Nullable<T> = T | null;
export interface ApiResponse<T> extends AxiosResponse<T> {
  data: T;
  meta: {
    limit: number;
    count: number;
    offset: number;
  };
}

export interface ApiResponseMonolith<T> extends AxiosResponse {
  result?: T;
}

export type Action<M extends Record<string, unknown>, V = unknown> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
        meta?: V;
      }
    : {
        type: Key;
        payload: M[Key];
        meta?: V;
      };
};

export type InferAction<R, T extends keyof R, V = unknown> = R[T] extends undefined
  ? {
      type: T;
      meta?: V;
    }
  : {
      type: T;
      payload: R[T];
      meta?: V;
    };

type Status = 'OK' | 'ERROR' | 'PEND' | 'RETRY' | 'RESET' | 'REFUSE' | 'CANCEL';
type Op = 'INIT' | 'GET' | 'SET' | 'PROCESS' | 'CHECK' | 'DELETE';
type Plural = 'LIST' | 'ITEM';
type WithReason = `${Status}_REASON_${string}`;
export type StatusOpTypes = `${Status}_${Op}_${Plural}`;
export type WithStatusType = { status: 'IDLE' | StatusOpTypes | WithReason | Status };

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export enum ErrorTypes {
  UNSPECIFIED = 'unspecified',
  CRITICAL = 'critical',
  MAJOR = 'major',
  MINOR = 'minor',
}
