import environments from '../../environments';

interface IProps {
  name: string;
  classes?: string;
  ariaHidden?: boolean | undefined;
  ariaLabel?: string | undefined;
}

const Icon = ({ name, classes, ariaHidden, ariaLabel }: IProps): JSX.Element => {
  return (
    <svg
      viewBox="0 0 16 16"
      className={`vc-icon vc-icon-${name} ${classes}`}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
    >
      <use xlinkHref={`${environments.svgSprite}#${name}`} />
    </svg>
  );
};

Icon.defaultProps = {
  classes: '',
  ariaHidden: undefined,
  ariaLabel: undefined,
};

export default Icon;
