import { takeLatest, call, put, ForkEffect, SagaReturnType, CallEffect, PutEffect } from 'redux-saga/effects';
import { Actions, ActionTypes, InferredAction } from './types';
import logger from '../../services/logger.service';
import { api } from './services';
import {
  fetchListReject,
  fetchListResolve,
  fetchDraftListResolve,
  fetchDraftListReject,
  setItemReject,
  setItemResolve,
} from './actions';
import toaster from '../../helpers/toaster';
import { cartActions } from '../cart';

export function* get({
  payload,
  meta,
}: InferredAction<ActionTypes.FETCH>): Generator<
  CallEffect | PutEffect,
  void,
  SagaReturnType<typeof api.getOrderList>
> {
  try {
    const res: SagaReturnType<typeof api.getOrderList> = yield call([api, 'getOrderList'], payload);
    yield put(fetchListResolve(res, meta));
  } catch (err) {
    logger.error('collection-points::get', err);
    yield put(fetchListReject(JSON.stringify(err), meta));
  }
}

export function* getDraft({
  payload,
  meta,
}: InferredAction<ActionTypes.FETCH_DRAFT>): Generator<
  CallEffect | PutEffect,
  void,
  SagaReturnType<typeof api.getDraftList>
> {
  try {
    const res: SagaReturnType<typeof api.getDraftList> = yield call([api, 'getDraftList'], payload);
    yield put(fetchDraftListResolve(res, meta));
  } catch (err) {
    logger.error('collection-points::get', err);
    yield put(fetchDraftListReject(JSON.stringify(err), meta));
  }
}

export function* setItem({
  payload,
}: InferredAction<ActionTypes.SET_COLLECTION_POINT_REQUEST>): Generator<
  CallEffect | PutEffect,
  void,
  SagaReturnType<typeof api.setItem>
> {
  try {
    const res: SagaReturnType<typeof api.setItem> = yield call([api, 'setItem'], payload);
    yield put(setItemResolve(res));
    yield put(cartActions.fetch());
  } catch (e) {
    logger.error('collection-points::setItem', e);
    const err = JSON.stringify(e);
    toaster.error(`failed to set collection-point`);
    yield put(setItemReject(err));
  }
}

export function* sagas(): Generator<ForkEffect<Actions>> {
  yield takeLatest(ActionTypes.FETCH, get);
  yield takeLatest(ActionTypes.FETCH_DRAFT, getDraft);
  yield takeLatest(ActionTypes.SET_COLLECTION_POINT_REQUEST, setItem);
}
