import { Entities } from '@vestiaire/api-specification';
import { Action, InferAction, WithStatusType } from '../../types/index';

export type Entity = Entities.OrderPickupPoint;

export type DraftSelected = string;

export type MetaData = { isDraft: boolean };

export enum StatusEnum {
  IDLE = 'IDLE',
  PEND_GET_LIST = 'PEND_GET_LIST',
  OK_GET_LIST = 'OK_GET_LIST',
  ERROR_GET_LIST = 'ERROR_GET_LIST',
  PEND_SET_ITEM = 'PEND_SET_ITEM',
  OK_SET_ITEM = 'OK_SET_ITEM',
  ERROR_SET_ITEM = 'ERROR_SET_ITEM',
}

export type DraftAddress = {
  address: string;
  city: string;
  postCode: string;
  countryCode: string;
};

export type State = WithStatusType & {
  list: Entity[];
  draftList: Entity[];
  selected: string;
  lastSelected: string;
  draftSelected?: string;
  draftAddress?: DraftAddress;
  confirmedDraftAddress?: DraftAddress;
};

export type ApiResponse = Entity[];

export type SetItemResponse = string;

export type SetItemParams = {
  reference: string;
  lastRelayUsedShouldBeReset?: boolean;
};

export enum ActionTypes {
  FETCH = 'collectionPoints/fetch',
  RESOLVE = 'collectionPoints/resolve',
  REJECT = 'collectionPoints/reject',
  RETRY = 'collectionPoints/retry',
  FETCH_DRAFT = 'collectionPoints/draft/fetch',
  RESOLVE_DRAFT = 'collectionPoints/draft/resolve',
  REJECT_DRAFT = 'collectionPoints/draft/reject',
  RETRY_DRAFT = 'collectionPoints/draft/retry',
  SET_DRAFT_SELECTED = 'collectionPoints/setDraftSelected',
  SET_DRAFT_ADDRESS = 'collectionPoints/setDraftAddress',
  SET_COLLECTION_POINT_REQUEST = 'collectionPoints/set/request',
  SET_COLLECTION_POINT_RESOLVE = 'collectionPoints/set/resolve',
  SET_COLLECTION_POINT_REJECT = 'collectionPoints/set/reject',
  SET_COLLECTION_POINT_RETRY = 'collectionPoints/set/retry',
}

export type PayloadTypes = {
  [ActionTypes.FETCH]: RequestParams;
  [ActionTypes.RESOLVE]: ApiResponse;
  [ActionTypes.REJECT]: string;
  [ActionTypes.FETCH_DRAFT]: RequestDraftListParams;
  [ActionTypes.RESOLVE_DRAFT]: DraftListResponse;
  [ActionTypes.REJECT_DRAFT]: string;
  [ActionTypes.SET_DRAFT_SELECTED]: DraftSelected;
  [ActionTypes.SET_DRAFT_ADDRESS]: DraftAddress;
  [ActionTypes.SET_COLLECTION_POINT_REQUEST]: SetItemParams;
  [ActionTypes.SET_COLLECTION_POINT_RESOLVE]: SetItemResponse;
  [ActionTypes.SET_COLLECTION_POINT_REJECT]: string;
};

export type MetaTypes = {
  [ActionTypes.SET_COLLECTION_POINT_REQUEST]: MetaData;
};

export type Actions = Action<PayloadTypes, MetaData>[keyof Action<PayloadTypes, MetaData>];
export type InferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T, MetaData>;

export type RequestParams = {
  itemNumber?: number;
};

export type RequestDraftListParams = Partial<
  {
    geoLatitude: string;
    geoLongitude: string;
    stateCode: string;
    selectionReference: string;
  } & DraftAddress
>;

export type DraftListResponse = Entity[];
