import React from 'react';
import { useAppSelector } from '../../app/hooks';
import Sumup from '../Sumup/Sumup';
import './Container.scss';
import { uiSelectors } from '../../features/ui';

const Container: React.FunctionComponent = ({ children }) => {
  const cartIsEmpty = useAppSelector(uiSelectors.getIsEmptyCartUi);

  return (
    <section className="mainContent">
      <div className="mainContent__content">{children}</div>
      <aside className="mainContent__aside">{!cartIsEmpty && <Sumup />}</aside>
    </section>
  );
};

export default Container;
