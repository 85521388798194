import { CartTypes } from '../cart';
import { Actions, ActionTypes, State } from './types';

export const set = (orderLines: State): Actions<State> => ({
  type: ActionTypes.SET_ORDER_LINES,
  payload: orderLines,
});

export const removeLineItem = (
  payload: CartTypes.RemoveOrderLinePayloadType
): Actions<CartTypes.RemoveOrderLinePayloadType> => ({
  type: ActionTypes.REMOVE_LINE_ITEM,
  payload,
});

export const undoRemoveLineItem = (id: string): Actions<string> => ({
  type: ActionTypes.UNDO_REMOVE_LINE_ITEM,
  payload: id,
});

export const updateShippingPlan = (productId: string, shippingOption: string): Actions<any> => ({
  type: ActionTypes.UPDATE_SHIPPING_PLAN,
  payload: { productId, shippingOption },
});

export const enquiry = (): Actions<string> => ({
  type: ActionTypes.ENQUIRY_ORDER_LINES,
});
