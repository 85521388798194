import { FC } from 'react';
import Spinner from '../../Spinner/Spinner';

type PaymentButtonLoadingProps = {
  text: string;
  noLoader?: boolean;
};

const PaymentButtonLoading: FC<PaymentButtonLoadingProps> = ({ text, noLoader = false }) => {
  return (
    <button
      type="button"
      className="paymentButton vc-btn vc-btn--primary vc-btn--large vc-btn--full"
      disabled
      data-testid="payment_button_loading"
    >
      {!noLoader && <Spinner className="paymentButton__spinner" />} {text}
    </button>
  );
};

export default PaymentButtonLoading;
