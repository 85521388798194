import React from 'react';

const VoucherSkeleton: React.FC = () => {
  return (
    <div className="sumup__voucher">
      <div className="voucherForm">
        <div className="voucherForm__body">
          <div className="vc-formUnit">
            <input
              id="voucherForm"
              data-testid="voucher_text_input"
              type="text"
              className="vc-inputText"
              name="voucher"
              disabled={true}
              aria-invalid="false"
            />
          </div>
          <div className="voucherForm__body__button vc-btn vc-btn--primary vc-btn--large disabled ssc--btn">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default VoucherSkeleton;
