import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import environment from '../environments';
import { ApiResponse, ApiResponseMonolith } from '../types';
import { Api } from './api';
import { i18nServices } from '../features/i18n';

export interface IMonolithParams {
  m: string;
  [key: string]: string;
}
export interface IMonolithConfig extends IMonolithParams {
  a: string;
  h: string;
  u: string;
  v: string;
  lang: string;
  currency: string;
  id_site: string;
}

export function getMonolithConfig(params: IMonolithParams): IMonolithConfig {
  const [idSite, lang, currency] = i18nServices.get();

  const monolithConfig = {
    a: 'int_js',
    h: '5fcdc0ac04537595a747e2830037cca0',
    u: '4stor',
    v: '1',
    lang,
    currency,
    id_site: idSite,
  };

  return { ...monolithConfig, ...params };
}

class ApiMonolith extends Api {
  private monolithConfig = (params: IMonolithParams): AxiosRequestConfig => {
    return {
      baseURL: environment.apiLegacyBaseUrl, // env file
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: getMonolithConfig(params),
      timeout: 15000,
    };
  };

  getMonolith<R>(url: string, monolitParams: IMonolithParams): Promise<ApiResponseMonolith<R>> {
    return Axios.get(url, this.monolithConfig(monolitParams))
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err));
  }

  postMonolith<R, P = R>(url: string, data: P, monolitParams: IMonolithParams): Promise<ApiResponse<R>> {
    return Axios.post(url, data, this.monolithConfig(monolitParams))
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err));
  }

  patchMonolith<R, P = R>(url: string, data: P, monolitParams: IMonolithParams): Promise<ApiResponse<R>> {
    return Axios.patch(url, data, this.monolithConfig(monolitParams))
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err));
  }

  deleteMonolith<R>(url: string, monolitParams: IMonolithParams): Promise<AxiosResponse<R>> {
    return Axios.delete(url, this.monolithConfig(monolitParams))
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err));
  }
}

export default ApiMonolith;
