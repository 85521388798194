import { Entities } from '@vestiaire/api-specification';

export interface ISubtotalDetail {
  brand: string;
  name: string;
  amount: string;
}

export interface ISubtotalDetailList {
  authentication: ISubtotalDetail[];
  shipping: ISubtotalDetail[];
}

export interface ISubtotalItem extends Entities.Price {
  details?: ISubtotalDetail[];
}

export interface State {
  itemsAmount: Entities.Price;
  shippingAmount: ISubtotalItem;
  buyerFeesAmount: ISubtotalItem;
  buyerServiceFeesTotalAmount?: ISubtotalItem;
  dutiesAmount: Entities.Price;
  salesTaxAmount: Entities.Price;
  subTotalCostBeforeShipping: Entities.Price;
  totalAmountShoppingCart: Entities.Price;
  totalAmount: Entities.Price;
  voucherAmount: Entities.Price;
  itemsAmountCents: number;
  itemsDutiesTaxesAmountEuro?: Entities.Price;
}

export const SET_SUBTOTALS = 'subtotal/set';

export type ActionType = {
  type: typeof SET_SUBTOTALS;
  payload: State;
};
