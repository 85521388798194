import { Entities } from '@vestiaire/api-specification';
import { Actions, ActionTypes, State } from './types';

export const set = (payload: Pick<State, 'id'>): Actions<State> => ({
  type: ActionTypes.SET_AUTH,
  payload,
});

export const get = (): Actions => ({
  type: ActionTypes.GET_AUTH,
});

export const setUser = (payload: Entities.User): Actions => ({
  type: ActionTypes.SET_USER,
  payload,
});

export const getUser = (): Actions => ({
  type: ActionTypes.GET_USER,
});
