import React from 'react';
import './SumupSkeleton.scss';
import SubtotalsSkeleton from '../../Subtotals/SubtotalsSkeleton/SubtotalsSkeleton';
import VoucherSkeleton from '../../Voucher/VoucherSkeleton/VoucherSkeleton';

const SumupSkeleton: React.FC = () => {
  return (
    <div className="sumup sumup--skeleton ssc">
      <h2 className="sumup__title">
        <span className="ssc-line w-30">&nbsp;</span>
      </h2>
      <SubtotalsSkeleton />
      <div className="sumup__voucher">
        <VoucherSkeleton />
      </div>
      <div className="sumup__cta">
        <div className="sumup__cta__total">
          <span className="ssc-line w-40">&nbsp;</span>
          <span className="subtotals__entry__amount ssc-line w-20">&nbsp;</span>
        </div>
      </div>
      <div>
        <div className="vc-btn vc-btn--primary vc-btn--large vc-btn--full ssc--btn">&nbsp;</div>
        <p className="PaymentTcAcceptance">
          <span className="ssc-line w-100">&nbsp;</span>
          <span className="ssc-line w-60">&nbsp;</span>
        </p>
      </div>
    </div>
  );
};

export default SumupSkeleton;
