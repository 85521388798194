import { IEnvironment } from './model';

const productionEnvironment: IEnvironment = {
  env: 'production',
  logLevel: 'warn',
  apiBaseUrl: 'https://apiv2.vestiairecollective.com',
  apiLegacyBaseUrl: 'https://api.vestiairecollective.com',
  apiStaticBaseUrl: 'https://api-static-s3.vestiairecollective.com',
  sessionCookie: 'EZBO_SESSION_vdclive',
  userIdCookie: 'vc_uid',
  cookieOptions: { domain: '.vestiairecollective.com', path: '/' },
  i18nCookie: 'vc_ck',
  imagesPath: 'https://images.vestiairecollective.com/images/resized',
  assetsPath: 'https://assets.vestiairecollective.com',
  mainDomain: 'https://www.vestiairecollective.com',
  analytics: { envName: 'prod', verbose: false },
  cookieAnonymousUser: 'nl_user',
  cookieRandomId: 'random_range_id',
  launchDarkly: {
    sdkKey: 'sdk-604872a5-17f2-4139-bfeb-139f7934c773',
    clientId: '612f6859878959262228ba9c',
  },
  google: {
    maps: 'AIzaSyBO4EOhk_89Qgy8XdfL_iWky8dLiI-4c7Y',
  },
  payment: {
    adyen: {
      environment: 'live',
      clientKey: 'live_5F2OVXX5MNAYDNIXILWHHP4CHA3HSGAG',
      analytics: true,
    },
    braintree: {
      clientId: 'ARBRUR_-2sj3s73ZtK0lIi5ikwNRi1wi27IMTb-7tr9HsctQ4Mekq1gUWGZmzoDfqGTYT0vczKu2rK4Z',
    },
  },
  svgSprite: '/assets/icons/sprite.svg',
  svgPath: '/assets/icons/svg-icons',
};

export default productionEnvironment;
