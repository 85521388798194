import { Entities } from '@vestiaire/api-specification';
import type { Action, InferAction, WithStatusType } from '../../types';
import { OrderPaymentMethod } from '../payment/types';

export enum StatusEnum {
  IDLE = 'IDLE',
  PEND_SET_ITEM = 'PEND_SET_ITEM',
  OK_SET_ITEM = 'OK_SET_ITEM',
  ERROR_SET_ITEM = 'ERROR_SET_ITEM',
  PEND_DELETE_ITEM = 'PEND_DELETE_ITEM',
  OK_DELETE_ITEM = 'OK_DELETE_ITEM',
  ERROR_DELETE_ITEM = 'ERROR_DELETE_ITEM',
  PEND_CHECK_OPTION_PAYMENT_METHOD = 'PEND_CHECK_OPTION_PAYMENT_METHOD',
  OK_CHECK_OPTION_PAYMENT_METHOD = 'PEND_CHECK_PAYMENT_METHOD_UPDATE',
}

export enum OptionsStatusEnum {
  IDLE = 'IDLE',
  PEND_CHECK_LIST = 'PEND_CHECK_LIST',
}

export type Entity = Entities.OrderCoupon;
export type Reduction = Entities.Price;

export type State = WithStatusType & { code?: string; error?: string; checkCartOptions?: VoucherOptionsState };

export type SetAddRequest = { code?: string };
export type SetRemoveRequest = SetAddRequest;
export type SetResolveRequest = SetAddRequest;

export enum VoucherOptionToCheck {
  'PAYMENT_METHOD' = 'paymentMethod',
  'DELIVERY_OPTION' = 'deliveryOption',
}

export type VoucherCurrentCartOptions = {
  [VoucherOptionToCheck.PAYMENT_METHOD]: OrderPaymentMethod['mnemonic'];
};

export type VoucherOptionsState = VoucherCurrentCartOptions &
  WithStatusType & {
    stack: VoucherOptionToCheck[];
  };

export enum ActionTypes {
  SET_VOUCHER_REQUEST = 'voucher/setRequest',
  SET_VOUCHER_RESOLVE = 'voucher/setResolve',
  SET_VOUCHER_REJECT = 'voucher/setReject',

  REMOVE_VOUCHER_REQUEST = 'voucher/removeRequest',
  REMOVE_VOUCHER_RESOLVE = 'voucher/removeResolve',
  REMOVE_VOUCHER_REJECT = 'voucher/removeReject',

  STACK_CHECK_LIST_OPTIONS = 'voucher/stackCheckListOptions',
  UNSTACK_CHECK_LIST_OPTION = 'voucher/unstackCheckListOption',
}

export type PayloadTypes = {
  [ActionTypes.SET_VOUCHER_REQUEST]: SetAddRequest;
  [ActionTypes.SET_VOUCHER_RESOLVE]: SetResolveRequest;
  [ActionTypes.SET_VOUCHER_REJECT]: string;

  [ActionTypes.REMOVE_VOUCHER_REQUEST]: SetRemoveRequest;
  [ActionTypes.REMOVE_VOUCHER_RESOLVE]: undefined;
  [ActionTypes.REMOVE_VOUCHER_REJECT]: undefined;

  [ActionTypes.STACK_CHECK_LIST_OPTIONS]: VoucherCurrentCartOptions;
  [ActionTypes.UNSTACK_CHECK_LIST_OPTION]: VoucherOptionToCheck;
};

export type Actions = Action<PayloadTypes>[keyof Action<PayloadTypes>];
export type InferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T>;
