import React from 'react';
import Card from '../Card/Card';
import RadioButton from '../RadioButton/RadioButton';
import classNames from 'classnames';
import { useAppSelector } from '../../app/hooks';
import { bucketTestSelectors } from '../../features/bucket-test';

const DeliveryOptionsSkeleton = (): JSX.Element => {
  const { smoothCheckoutCollapsed } = useAppSelector(bucketTestSelectors.getFlags);

  return (
    <div
      className={classNames('deliveryOptions deliveryOptions--skeleton ssc', {
        // next line to be removed after the feature flag is removed
        'deliveryOptions--currentVersion': !smoothCheckoutCollapsed,
      })}
      data-testid="deliveryoption_loading"
    >
      <Card
        className="deliveryOptions__entry"
        header={
          <RadioButton className="deliveryOptions__label" name="deliveryOptions" id="homeDelivery">
            <div className="ssc ssc-line w-60" />
          </RadioButton>
        }
      >
        <div className="deliveryAddress ssc">
          <div className="deliveryAddress__current w-50">
            <p className="deliveryAddress__current__name">
              <span className="ssc-line w-50" />
            </p>
            <div className="ssc-line w-70">&nbsp;</div>
            <div className="ssc-line w-70">&nbsp;</div>
            <div className="ssc-line w-50">&nbsp;</div>
          </div>
          <div className="deliveryAddress__actions w-50">
            <span className="deliveryAddress__actions__cta w-50">
              <span className="ssc-line">&nbsp;</span>
            </span>
            <span className="deliveryAddress__actions__cta w-40">
              <span className="ssc-line">&nbsp;</span>
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DeliveryOptionsSkeleton;
