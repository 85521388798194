import React, { memo } from 'react';
import { uiSelectors } from '../../../features/ui';
import { useAppSelector } from '../../../app/hooks';
import { useTranslation } from 'react-i18next';
import { Entities } from '@vestiaire/api-specification';
import './SumupProduct.scss';

type SumupProductType = {
  product: Entities.Product;
};

function SumupProduct({ product }: SumupProductType): JSX.Element {
  const isSummaryLoading = useAppSelector(uiSelectors.getSummaryLoading);
  const { t } = useTranslation();

  return (
    <div className="product" key={product.id} data-testid="product_sumup">
      <h2 className="product__main">
        <div className="product__title">
          <span className="product__title__brand">{product.brand?.name}</span>, {product.name}
        </div>
        <div className="product__amount" aria-busy={isSummaryLoading} aria-live="polite">
          {isSummaryLoading ? (
            <span className="ssc-line sumup__amountLoading" aria-hidden="true"></span>
          ) : (
            <span>{product.price.formatted}</span>
          )}
        </div>
      </h2>
      <ul className="product__priceDetails">
        <li className="product__priceDetails__item">
          <div>{t('SUMMARY.PRICE', 'Item price')}</div>
          <div data-testid="product_sellerprice" aria-busy={isSummaryLoading} aria-live="polite">
            {isSummaryLoading ? (
              <span className="ssc-line sumup__amountLoading" aria-hidden="true"></span>
            ) : (
              <span>{product.pricingBreakdown?.sellerPrice?.formatted}</span>
            )}
          </div>
        </li>
        <li className="product__priceDetails__item">
          <div>{t('SUMMARY.BUYERFEES', 'Buyer service fees (incl. VAT)')}</div>
          <div
            className="product__priceDetails__item__amount"
            data-testid="product_buyerfees"
            aria-busy={isSummaryLoading}
            aria-live="polite"
          >
            {isSummaryLoading ? (
              <span className="ssc-line sumup__amountLoading" aria-hidden="true"></span>
            ) : (
              <span>{product.pricingBreakdown?.buyerFees?.amount.formatted}</span>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
}

export default memo(SumupProduct);
