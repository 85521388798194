import logger from '../services/logger.service';

export const readLocalStorageJson = <T>(key: string, defaultValue: T): T => {
  try {
    return JSON.parse(localStorage.getItem(key) || 'null') ?? defaultValue;
  } catch (e) {
    logger.error('localStorage', e);
    return defaultValue;
  }
};

export const writeLocalStorageJson = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    logger.error('localStorage', e);
  }
};
