import React, { ComponentProps, FC, useRef } from 'react';

import Popup from 'reactjs-popup';
import { PopupActions, PopupPosition } from 'reactjs-popup/dist/types';

import './Tooltip.scss';
import Icon from '../Icons/Icons';

export type TooltipPropsTypes = ComponentProps<'div'> & {
  trigger: JSX.Element | (() => JSX.Element);
  position?: PopupPosition | PopupPosition[];
  onTooltipOpen?: (e: CustomEvent) => void;
  children: React.ReactNode;
};

const Tooltip: FC<TooltipPropsTypes> = ({
  trigger,
  position = 'right center',
  onTooltipOpen,
  children,
  ...props
}): JSX.Element => {
  const tooltip = useRef({} as PopupActions);
  const closeTooltip = () => tooltip.current.close();

  const onOpen = () => {
    onTooltipOpen?.({} as CustomEvent);
  };

  return (
    <Popup ref={tooltip} trigger={trigger} position={position} keepTooltipInside onOpen={onOpen} className={'dark'}>
      <div>
        <button
          type="button"
          className="vc-tooltip-close"
          data-testid={`${props.id}_tooltip_close`}
          onClick={closeTooltip}
        >
          <Icon name="cross-x" />
        </button>
        {children}
      </div>
    </Popup>
  );
};

export default Tooltip;
