import { setResolve, removeResolve, setReject, removeReject } from './actions';
import { call, ForkEffect, takeLatest, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';
import { Actions, ActionTypes, InferredAction, VoucherOptionToCheck } from './types';
import logger from '../../services/logger.service';
import voucherService from './services';
import { cartActions } from '../cart';
import { ErrorTypes } from '../../types';
import { paymentSelectors } from '../payment';
import { voucherActions } from '.';
import { OrderPaymentMethod } from '../payment/types';

function* set({
  payload,
}: InferredAction<ActionTypes.SET_VOUCHER_REQUEST>): Generator<PutEffect | CallEffect | SelectEffect> {
  try {
    const currentPaymentMethod = yield select(paymentSelectors.getSelectedPaymentMethod);
    if (currentPaymentMethod) {
      yield put(
        voucherActions.stackOptionsToCheck({
          [VoucherOptionToCheck.PAYMENT_METHOD]: (currentPaymentMethod as OrderPaymentMethod).mnemonic,
        })
      );
    }
    yield call([voucherService, voucherService.set], payload);
    yield put(setResolve(payload));
    yield put(cartActions.fetch());
  } catch (e: any) {
    yield put(setReject(e?.errors?.detail || ''));
    logger.error('voucher::set', e, {
      errorType: ErrorTypes.MINOR,
    });
  }
}

function* remove({ payload }: InferredAction<ActionTypes.REMOVE_VOUCHER_REQUEST>): Generator<PutEffect | CallEffect> {
  try {
    yield call([voucherService, voucherService.remove], payload);
    yield put(removeResolve());
    yield put(cartActions.fetch());
  } catch (e: any) {
    yield put(removeReject());
    logger.error('voucher::remove', e);
  }
}

export function* sagas(): Generator<ForkEffect<Actions>> {
  yield takeLatest(ActionTypes.SET_VOUCHER_REQUEST, set);
  yield takeLatest(ActionTypes.REMOVE_VOUCHER_REQUEST, remove);
}
