import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { Dispatch } from 'redux';
import type { AppDispatch, RootState } from '../../store';
import type { AddressTypes } from '../../features/address';
import type { AuthTypes } from '../../features/auth';
import type { I18nTypes } from '../../features/i18n';
import type { OrderLinesTypes } from '../../features/order-lines';
import type { TrackingTypes } from '../../features/tracking';
import type { CartTypes } from '../../features/cart';
import type { UITypes } from '../../features/ui';
import type { PaymentTypes } from '../../features/payment';
import type { CollectionPointsTypes } from '../../features/collection-points';
import type { BucketTestTypes } from '../../features/bucket-test';
import type { VoucherTypes } from '../../features/voucher';

// Typed `useDispatch` and `useSelector`
type StoreTypes =
  | AddressTypes.Actions
  | AuthTypes.Actions
  | BucketTestTypes.Actions
  | CartTypes.Actions
  | OrderLinesTypes.Actions
  | PaymentTypes.Actions
  | I18nTypes.Actions
  | TrackingTypes.Actions
  | UITypes.Actions
  | CollectionPointsTypes.Actions
  | VoucherTypes.Actions;

export const useAppDispatch = (): Dispatch<StoreTypes> => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
