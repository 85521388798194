import environments from '../environments';

export const BASENAME = environments.env !== 'test' ? '/shop' : '';

export const ROUTES = {
  CART: `${BASENAME}/cart`,
  CHECKOUT: `${BASENAME}/checkout`,
  ORDER_CONFIRMATION: '/order-confirmation', // fenx
  PAYMENT_CONFIRMATION: '/payment-confirmation', // fenx
  PAYMENT_REDIRECT: `${BASENAME}/payment/redirect`,
  PAYMENT_REFUSE: `${BASENAME}/checkout/refuse`,
  PAYMENT_CANCEL: `${BASENAME}/checkout/cancel`,
  PAYMENT_ERROR: `${BASENAME}/checkout/error`,
} as const;

export type ValidRouteKeys = keyof typeof ROUTES;
export type ValidRoute = typeof ROUTES[ValidRouteKeys];
