import React from 'react';
import { ISubtotalDetail } from '../../features/subtotals/types';
import { useAppSelector } from '../../app/hooks';
import { uiSelectors } from '../../features/ui';
import './SubtotalDetails.scss';

type PropsEntry = {
  details: ISubtotalDetail[];
};

const SubtotalDetails: React.FC<PropsEntry> = ({ details }) => {
  const isSummaryLoading = useAppSelector(uiSelectors.getSummaryLoading);

  return (
    <ul className="SubtotalDetails">
      {details.map((elem, index) => {
        return (
          <li key={index} className="SubtotalDetails__item">
            <span className="SubtotalDetails__item__desc">
              <span className="brand">{elem.brand}</span>, {elem.name}
            </span>
            {isSummaryLoading ? (
              <span className="ssc-line sumup__amountLoading ssc"></span>
            ) : (
              <span className="SubtotalDetails__item__amount">{elem.amount}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default SubtotalDetails;
