import { Actions, ActionTypes, RejectPayload, ResolvePayload } from './types';

export const initialize = (): Actions => ({
  type: ActionTypes.INITIALIZE,
});

export const initializeResolve = (payload: ResolvePayload): Actions => ({
  type: ActionTypes.RESOLVE,
  payload,
});

export const initializeReject = (payload: RejectPayload): Actions => ({
  type: ActionTypes.REJECT,
  payload,
});

export const identifyUser = (): Actions => ({
  type: ActionTypes.IDENTIFY_USER,
});
