import type { RootState } from '../../store';
import {
  OrderPaymentMethods,
  AdyenCheckout,
  GatewayInfo,
  ServiceProvider,
  OrderPaymentMethod,
  StatusEnums,
  AdyenRedirection,
  AdyenRedirectionData,
} from './types';

export const getOrderPaymentMethods = (state: RootState): OrderPaymentMethods => state.payment.orderPaymentMethods;
export const getSelectedPaymentMethod = (state: RootState): OrderPaymentMethod =>
  state.payment.orderPaymentMethods.items.filter((e) => e.isSelected === true)[0];

export const getGateway = ({ payment }: RootState): ServiceProvider | undefined => payment.gatewayService;
export const getPreviousGatewayName = (state: RootState): string | undefined => {
  return state.payment.orderPaymentMethods?.items?.find((e) => {
    return e.internalRef === state.payment.selectedPayment.previous;
  })?.pspName;
};
export const getGatewayStatus = ({ payment }: RootState) => payment.gatewayService.status as StatusEnums;
export const getGenericGatewayInfo = ({ payment }: RootState): GatewayInfo | undefined =>
  payment.gatewayService.genericGateway?.data;
export const getNonGenericGatewayInfo = ({ payment }: RootState): string | undefined =>
  payment.gatewayService.nonGenericGateway?.data;
export const getAdyenCheckoutInstance = ({ payment }: RootState): AdyenCheckout | undefined =>
  payment.gatewayService.adyenCheckout?.data;
export const getPaymentStatus = (state: RootState): StatusEnums => state.payment.paymentStatus;
export const getFetchOrderPaymentMethodsStatus = ({ payment }: RootState) =>
  payment.orderPaymentMethods.status as StatusEnums;
export const getSelectPaymentMethodStatus = ({ payment }: RootState) => payment.selectedPayment.status as StatusEnums;
export const getErrorMessage = ({ payment }: RootState) => payment.errorMessage;
export const getAdyenRedirection = ({ payment }: RootState) => payment.adyenRedirection as AdyenRedirection;
export const getAdyenRedirectionData = ({ payment }: RootState) =>
  payment.adyenRedirection.data as AdyenRedirectionData;
