import classnames from 'classnames';
import { DOMAttributes, FormEvent, forwardRef, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { VoucherTypes } from '../../../features/voucher';
import Icon from '../../Icons/Icon';
import './VoucherForm.scss';

type VoucherFormProps = {
  onSubmit?: DOMAttributes<HTMLFormElement>['onSubmit'];
  onInput?: DOMAttributes<HTMLInputElement>['onInput'];
  disabled: boolean;
  submitable?: boolean;
  voucher: VoucherTypes.State;
  loading: boolean;
  hint?: string;
  t: WithTranslation['t'];
};

const VoucherFormUI = forwardRef<HTMLInputElement, VoucherFormProps>(
  ({ t, onSubmit, onInput, disabled, voucher, loading, submitable = true, hint }, voucherInputRef) => {
    const [draft, setdraft] = useState(false);
    const [showCTA, setshowCTA] = useState(false);

    useEffect(() => {
      if (!loading) setshowCTA(false);
    }, [loading]);

    const onInputFc = (e: FormEvent<HTMLInputElement>) => {
      setdraft(true);
      if (onInput) onInput(e);
    };

    const onSubmitFc = (e: FormEvent<HTMLFormElement>) => {
      setdraft(false);
      if (onSubmit) onSubmit(e);
    };

    const handleFocus = () => {
      setshowCTA(true);
    };

    const handleBlur = (e: FormEvent<HTMLInputElement>) => {
      const hide = e.currentTarget.value.length === 0;
      if (hide) setshowCTA(false);
    };

    return (
      <form className="voucherForm" onSubmit={onSubmitFc}>
        <div className="voucherForm__body">
          <div
            className={classnames('vc-formUnit', {
              error: !!voucher.error && !draft,
              showCTA: showCTA,
            })}
          >
            <input
              id="voucherForm"
              data-testid="voucher_text_input"
              ref={voucherInputRef}
              type="text"
              className="vc-inputText"
              name="voucher"
              required
              onInput={onInputFc}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-invalid={!!voucher.error}
              placeholder={t('VOUCHER.FORM.LABEL', 'Promotional code')}
              disabled={disabled || loading}
            />
          </div>
          <button
            type="submit"
            disabled={!submitable || disabled || loading}
            className={classnames('voucherForm__body__button vc-btn vc-btn--primary vc-btn--large', {
              ssc: loading,
              'ssc-line': loading,
              disabled: disabled || loading,
            })}
            data-testid="voucher_apply_button"
          >
            {t('VOUCHER.FORM.APPLY', 'Apply')}
          </button>
        </div>
        {!draft && !!voucher.error && (
          <span className="voucherForm__errorMsg vc-text-xs">
            <Icon name="warning" ariaHidden />
            {voucher.error}
          </span>
        )}
        {hint && <span className="voucherForm__hint vc-text-xs">{hint}</span>}
      </form>
    );
  }
);

export default VoucherFormUI;
