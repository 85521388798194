import { Entities } from '@vestiaire/api-specification';
import { Action } from 'redux';

export interface IState {
  i18n: Entities.I18n;
  type: string;
  id: string;
  user: Entities.User;
  vcBotmind: string;
}

export type User = Entities.User;

export type State = Partial<IState>;

export enum ActionTypes {
  SET_AUTH = 'SET_AUTH',
  GET_AUTH = 'GET_AUTH',
  SET_USER = 'SET_USER',
  GET_USER = 'GET_USER',
}

export interface Actions<T = any> extends Action<ActionTypes> {
  payload?: T;
}
