import { Action } from 'redux';
import environments from '../../environments';
import { ValidRoute } from '../../helpers/routes';

export enum TagContainer {
  Media = '1',
  Analytics = '7',
  ABtests = '12',
}

export interface State {
  datalayer: DataLayerInterface;
}

export type EventPayload = Record<string, unknown>;

export interface AnalyticsEvent<T = EventPayload> {
  /**
   * The type of event.
   */
  type: string;

  /**
   * Container Id.
   */
  container?: TagContainer;

  /**
   * An object which contains all event own variables.
   */
  payload?: T;
  /**
   * If a time out is nedeed for sending tracking info
   */
  timeout?: number;
}

export enum FunctionTrackers {
  TCE1 = 'tc_events_1',
  TCE7 = 'tc_events_7',
  TCE12 = 'tc_events_12',
}

export type FunctionTrackersKeys = keyof typeof FunctionTrackers;
export type FunctionTrackersValueTypes = typeof FunctionTrackers[FunctionTrackersKeys];

export interface PreSelectedVariables {
  product_shipping_method_preselected: {
    productId: string;
    preSelected: string;
  }[];
  order_payment_method_preselected: number;
  order_delivery_preselected: string;
}

export interface I18nDataLayerInterface {
  ISO_country_code: string;
  env_country: string;
  env_language: string;
  id_site: string;
  user_currency: string;
  order_currency: string;
}
export interface UserDataLayerState {
  authAttempted: BooleanString.True | BooleanString.False;
  user_loginStatus: 'logged' | 'non-logged';
  user_logged: BooleanString.True | BooleanString.False;
  cust_firstname?: string;
  user_id?: string;
  user_email: string;
  order_email: string;
  user_accountLevel: UserAccountLevel.Regular | NonLogged.Value;
  user_category: UserCategory | NonLogged.Value | '';
  user_buyerStatus: BuyerStatus | NonLogged.Value | '';
  user_sellerStatus: SellerStatus | NonLogged.Value | '';
  user_iphoneApp: BooleanString.True | BooleanString.False | NonLogged.Value;
  user_androidApp: BooleanString.True | BooleanString.False | NonLogged.Value;
  user_date_inscription: string;
  user_relativedate: string;
  user_seller_rating: SellerRating | NonLogged.Value;
  user_fashion_activist: BooleanString.True | BooleanString.False | NonLogged.Value;
  user_postalcode: string;
  user_gender: string;
  user_country: string;
  pageUrl: string;
  order_confirmation_pageUrl: string;
  anonymous_id: string;
}

export interface ICheckoutDataLayer {
  basket_id: string;
  order_id: string;
  basket_ds_eligible: string;
  order_is_pickup_eligible: string;
  basket_shipping_address_created: string;
  basket_same_address: string;
  basket_nb_products: string;
  basket_total_value: string;
  checkout_step: ScreenName | ValidRoute | '';
  basket_shipping_costs: string;
  basket_authentication_costs: string;
  basket_import_duties_taxes: string;
  order_amount_ati_with_sf: string;
  order_amount_ati_without_sf: string;
  order_payment_method_displayed: string;
  order_payment_method_preselected: string;
  order_payment_method_selected: string;
  order_promo_codes: string;
  order_discount: string;
  order_amount_with_taxes: string;
  order_amount_without_taxes: string;
  order_discount_with_taxes: string;
  order_discount_without_taxes: string;
  order_products_number: string;
  order_delivery_options_displayed: string;
  order_delivery_preselected: PreSelectedVariables['order_delivery_preselected'];
  order_delivery_selected: string;
  order_delivery_option_selected: string;
  order_shipping_costs: string;
  order_authentication_costs: string;
  order_import_duties_taxes: string;
  order_salestax: string;
  failure_reason: string;
}

export interface IProductDataLayer {
  product_details: {
    product_id: string;
    seller_id: string;
    product_universe: string;
    product_category: string;
    product_subcategory: string;
    product_brand: string;
    product_unit_price: string;
    product_country: string;
    product_currency: string;
    product_crossborder_transaction: string;
    product_ds_eligible: string;
    product_shipping_method_preselected: string;
    product_shipping_method_selected: string;
  }[];
  // this object is added to ensure retro-compatibility
  // with marketing tracking via TagCommander
  // see "src/features/tracking/README.md"
  order_products: {
    order_products_quantity: string;
    // unit price
    // from val.product.regularPrice
    order_products_unitprice_ati: string;
    // SKU
    order_products_id: string;
    order_products_name: string;
    order_products_brand: string;
    order_products_color: string;
  }[];
}

export interface DataLayerInterface
  extends I18nDataLayerInterface,
    UserDataLayerState,
    ICheckoutDataLayer,
    IProductDataLayer {
  new_platform: string;
  platform: string;
  env_work: string;
  env_channel: string;
  responsive: string;
  cookie_analytics: string;
  cookie_customization: string;
  cookie_media: string;
  index_name: string;
  product_unitprice_ati: string;
  onsite_campaign_id: string;
  filtered_campaign_id: string;
  product_discount_ati: string;
  env_template: string;
  screen_name: PathScreenNameValues | ValidRoute | '';
  screen_category: string;
  screen_subcategory: ScreenName | ValidRoute | '';
  previous_screen_name: PathScreenNameValues | ValidRoute | '';
  previous_screen_category: string;
  previous_screen_subcategory: ScreenName | ValidRoute | '';
}

export enum BooleanString {
  True = 'true',
  False = 'false',
}

export enum UserAccountLevel {
  Regular = 'regular',
}

export enum NonLogged {
  Value = 'non-logged',
}

/**
 * User Category.
 *
 * - `Visitor` when the user has neither purchased nor sold anything yet on Vestiaire,
 * - `Buyer` when the user has already placed at least 1 order but has not sold anything yet,
 * - `Seller` when the user has already sold an item but has not purchased anything yet,
 * - `Mixed` when the user has both placed at least 1 order and has sold at least 1 item.
 */
export enum UserCategory {
  Visitor = 'visitor',
  Buyer = 'buyer',
  Seller = 'seller',
  Mixed = 'mixed',
}

/**
 * Buyer Status.
 *
 * - `Viewer` when the user has not purchased anything yet,
 * - `FirstBuyer` when the user has already made 1 order,
 * - `RepeatedBuyer` when the user has already made more than 2 orders,
 * - `TopBuyer` when the user spent more than 3.5K€ in the past 12 months.
 */
export enum BuyerStatus {
  Viewer = 'viewer',
  FirstBuyer = 'first buyer',
  RepeatedBuyer = 'repeated buyer',
  TopBuyer = 'top buyer',
}

/**
 * Seller Status.
 *
 * - `Viewer` when the user has not sold anything yet,
 * - `FirstSeller` when the user has already posted 1 sell,
 * - `RepeatedSeller` when the user has already posted more than 2 sells
 * - `TopSeller` when the user sold for more than 5K€ in the past 12 months.
 * - `deposant` when the user has deposit products and no products sold.
 */
export enum SellerStatus {
  Viewer = 'viewer',
  FirstSeller = 'first seller',
  RepeatedSeller = 'repeated seller',
  TopSeller = 'top seller',
  Depositor = 'first deposant',
}

export enum VisitorStatus {
  Viewer = 'viewer',
}

/**
 * Seller Rating for tracking
 *
 * - `standard` is tracking equivalent of "Common" seller rating badge
 * - `Trusted` & `Expert` are exposed as below
 */
export enum SellerRating {
  Common = 'standard',
  Trusted = 'trusted seller',
  Expert = 'expert seller',
}

export enum UserBadge {
  Pro = 'pro',
  Recommended = 'recommended',
  Depositor = 'depositor',
  ProductReporter = 'productReporter',
  DirectShipping = 'direct_shipping',
  FashionActivist = 'fashion_activist',
  TrustedSeller = 'trusted_seller',
  ExpertSeller = 'expert_seller',
  OfficialStore = 'official_store',
}

export enum ScreenName {
  Cart = 'shopping_bag',
  Checkout = 'order_details',
  Confirmation = 'payment_confirmation',
  PaymentRedirect = 'payment_redirection',
}

export const PathScreenName = {
  Cart: `/${ScreenName.Cart}`,
  Checkout: `/${ScreenName.Checkout}`,
  Confirmation: `/${ScreenName.Confirmation}`,
  PaymentRedirect: `/${ScreenName.PaymentRedirect}`,
} as const;

export type PathScreenNameValues = typeof PathScreenName[keyof typeof PathScreenName];

export enum EnvTemplate {
  Cart = 'cartsummary',
  Checkout = 'cartend',
  Confirmation = 'paiement_ok',
}

export enum PageUrlsEnum {
  Cart = 'checkout/cart',
  Checkout = 'checkout/order',
  Confirmation = 'checkout/confirmation',
}

export enum ShippingMethod {
  'standard-shipping' = 'authenticated_shipping',
  'direct-shipping' = 'direct_shipping',
}

export const DLInitialState: DataLayerInterface = {
  pageUrl: '',
  ISO_country_code: '',
  env_country: '',
  env_language: '',
  id_site: '',
  user_currency: '',
  new_platform: 'true',
  platform: 'checkout_project',
  authAttempted: BooleanString.False,
  env_work: environments.analytics.envName,
  env_channel: 'desktop',
  responsive: 'true',
  user_loginStatus: 'non-logged',
  user_logged: BooleanString.False,
  cust_firstname: '',
  user_id: '',
  user_email: '',
  order_email: '',
  user_accountLevel: NonLogged.Value,
  user_buyerStatus: NonLogged.Value,
  user_sellerStatus: NonLogged.Value,
  user_seller_rating: NonLogged.Value,
  user_fashion_activist: NonLogged.Value,
  user_postalcode: '',
  user_gender: '',
  user_category: NonLogged.Value,
  user_country: '',
  cookie_analytics: 'default_false',
  cookie_customization: 'default_false',
  cookie_media: 'default_false',
  user_iphoneApp: NonLogged.Value,
  user_androidApp: NonLogged.Value,
  user_date_inscription: '',
  user_relativedate: '',
  index_name: '',
  order_amount_ati_with_sf: '',
  order_amount_ati_without_sf: '',
  order_currency: '',
  product_unitprice_ati: '',
  onsite_campaign_id: '',
  filtered_campaign_id: '',
  product_discount_ati: '',
  env_template: 'checkout',
  basket_id: '',
  order_id: '',
  basket_ds_eligible: 'false',
  order_is_pickup_eligible: 'false',
  basket_shipping_address_created: 'false',
  basket_same_address: 'false',
  basket_nb_products: '0',
  basket_total_value: '0',
  checkout_step: '',
  basket_shipping_costs: '0',
  basket_authentication_costs: '0',
  basket_import_duties_taxes: '0',
  product_details: [],
  order_products: [],
  screen_name: PathScreenName.Cart,
  screen_category: 'checkout',
  screen_subcategory: '',
  order_payment_method_displayed: '',
  order_payment_method_preselected: '',
  order_payment_method_selected: '',
  order_promo_codes: '',
  order_discount: '',
  order_amount_with_taxes: '0',
  order_amount_without_taxes: '0',
  order_discount_with_taxes: '0',
  order_discount_without_taxes: '0',
  order_products_number: '0',
  order_delivery_options_displayed: '',
  order_delivery_preselected: '',
  order_delivery_option_selected: '',
  order_delivery_selected: '',
  order_shipping_costs: '0',
  order_authentication_costs: '0',
  order_import_duties_taxes: '0',
  previous_screen_name: '',
  previous_screen_category: '',
  previous_screen_subcategory: '',
  failure_reason: 'null',
  order_confirmation_pageUrl: '',
  order_salestax: '',
  anonymous_id: '',
};

export type StructuredEvent = {
  event: string;
  category: string; // will be 'checkout' by default
  action: ScreenName | ValidRoute | string; // todo : find a way to prevent string here use genric for using FlexibleEvent
  label?: string;
  value?: string;
  property?: string;
};

export type FlexibleEvent = Omit<StructuredEvent, 'action'> & {
  action: string;
};

export enum ActionTypes {
  SET_DL = 'analytics/set datalayer',
  SEND_EVENT = 'analytics/send event',
}

export interface Actions<T = any> extends Action<ActionTypes> {
  payload: T;
}
