import { Entities } from '@vestiaire/api-specification';
import { State, Response, DeliveryMethods, RemoveOrderLinePayloadType, TransformOrder, CheckResponse } from './types';
import { AdapterAtlas } from '../../services/adapters';
import { Api } from '../../services/api';
import { State as orderLineState } from '../order-lines/types';
import { State as subtotalsState } from '../subtotals/types';
import logger from '../../services/logger.service';

class Service extends Api {
  getEntity(): Promise<Response> {
    return this.get<Response>(`/orders/current-light`, {
      params: {
        calcTotals: 1,
      },
    })
      .then(({ data }) => data)
      .catch((e) => {
        throw e;
      });
  }

  removeProduct(data: RemoveOrderLinePayloadType) {
    return this.delete('/orders/current/order-lines-light', data);
  }

  updateProductDeliveryMethod(productId: string, shippingOption: string) {
    return this.post(`/orders/current/order-lines/${productId}/${shippingOption}`, {});
  }

  check() {
    return this.get<CheckResponse>('/orders/current/check-cart-light').then(({ data }) => data);
  }

  setDeliveryMethod(type: DeliveryMethods) {
    return this.patch(`/orders/current/delivery-option`, {
      type,
    });
  }
}

export function transformOrder(order: Response): TransformOrder {
  const adapter = new AdapterAtlas(order);
  const newOrderLines =
    order.orderLines?.reduce((prev: string[], curr: Entities.OrderLine) => [...prev, curr.id], []) || []; // format oderLines to list of ids
  const { orderLines, ...withoutOrderLines } = order;
  const cart = { ...withoutOrderLines, orderLines: newOrderLines } as State;
  cart.deliverySelectedMethod =
    cart.deliverySelectedMethod === 'relay' && cart.isElligibleToCollectionPoint
      ? DeliveryMethods.COLLECTION_POINT
      : DeliveryMethods.HOME;
  return {
    orderLines: adapter.getOrderLines(),
    subTotals: adapter.getSubtotals(),
    cart,
  };
}

export function checkPricingBreakdownContract(product: Entities.Product) {
  if (
    product.pricingBreakdown &&
    // missing data
    (!product.pricingBreakdown.buyerFees?.amount.cents ||
      !product.pricingBreakdown.sellerPrice?.cents ||
      // wrongSum
      product.pricingBreakdown.buyerFees.amount.cents + product.pricingBreakdown.sellerPrice.cents !==
        product.price.cents)
  ) {
    logger.error('cart::monetization::buyer-fees-mismatch', {
      productId: product.id,
      pricingBreakdown: product.pricingBreakdown,
      from: 'checkout',
    });
  }
}

export function checkBuyerFeesTotal(cartId: string, orderLines: orderLineState, subtotals: subtotalsState) {
  const mappedOrderlines = Object.values(orderLines)?.map((product) => {
    return {
      productId: product.id,
      pricingBreakdown: product.product.pricingBreakdown,
    };
  });
  const sum =
    mappedOrderlines?.reduce((acc, product) => acc + (product.pricingBreakdown?.buyerFees?.amount.cents || 0), 0) || 0;
  if (mappedOrderlines && subtotals.buyerServiceFeesTotalAmount?.cents !== sum) {
    logger.error('cart::monetization::calculation-mismatch', {
      orderId: cartId,
      orderlines: mappedOrderlines,
      sumpOfBuyerFess: sum,
      buyerServiceFeesTotalAmount: subtotals.buyerServiceFeesTotalAmount,
    });
  }
}

export const api = new Service();
