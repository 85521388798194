import { IEnvironment } from './model';

const localDockerEnvironment: IEnvironment = {
  env: 'local.docker',
  logLevel: 'debug',
  apiBaseUrl: 'https://atlas.cluster.dev.tech.vestiairecollective.com',
  apiLegacyBaseUrl: 'https://monolith.cluster.dev.tech.vestiairecollective.com',
  apiStaticBaseUrl: 'https://api-static-s3.staging.vestiairecollective.com',
  sessionCookie: 'EZBO_SESSION_vdclive_DEV',
  userIdCookie: 'vc_uid',
  cookieOptions: { domain: '.vestiairecollective.com' || 'localhost', path: '/' },
  i18nCookie: 'vc_ck',
  imagesPath: 'https://images.staging.vestiairecollective.com/images/resized',
  assetsPath: 'https://assets.staging.vestiairecollective.com',
  mainDomain: 'https://www.local.dev.tech.vestiairecollective.com',
  analytics: { envName: 'dev', verbose: true },
  cookieAnonymousUser: 'nl_user',
  cookieRandomId: 'random_range_id',
  launchDarkly: {
    sdkKey: 'sdk-7810b539-fd39-4780-8546-44c084040882',
    clientId: '613770ed387f5e3843209a1e',
  },
  google: {
    maps: '',
  },
  payment: {
    adyen: {
      environment: 'test',
      clientKey: 'test_2RZCD443UJELPB45WUIEVW3LS4YB6ZVT',
      analytics: false,
    },
    braintree: {
      clientId: 'AdXGmXPWJ-TWD8V-wLe_4qKuvnkPzuhGHJ0ACxZAcICjWHyzVCNLBLWJ10p_OGyjmPHbXTW4ksWufEux',
    },
  },
  svgSprite: '/assets/icons/sprite.svg',
  svgPath: '/assets/icons/svg-icons',
};

export default localDockerEnvironment;
