import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { StructuredEvent } from './types';
import { sendEventAction } from './actions';
import { useAppDispatch } from '../../app/hooks';
import { ValidRoute } from '../../helpers/routes';
import { getScreenName } from './services';

const eventsDictionary: Record<string, Partial<StructuredEvent>> = {
  // This event should be using FlexibleEvent type
  monetization_show_buyer_fee: {
    category: 'monetization',
    action: 'show_buyer_fee',
    label: 'buyer_view',
    property: '#pagename#',
  },
  // This event should be using FlexibleEvent type
  monetization_buyer_fee_pop_in_close: {
    category: 'pop_in_monetization_buyer_fee',
    action: 'close',
    label: 'buyer_view',
    property: '#pagename#',
    value: '#buyerFees#',
  },
  monetization_price_details_dropdown: {
    label: 'price_details_dropdown',
    property: '#expand or collapse#',
  },
  checkout_price_details_dropdown: {
    label: 'price_details_dropdown',
    property: '#expand or collapse#',
    // value?: '#product_id#',
  },
  checkout_shipping_fee_dropdown: {
    label: 'shipping_fee_dropdown',
    property: '#expand or collapse#',
  },
  checkout_authentication_fee_dropdown: {
    label: 'authentication_fee_dropdown',
    property: '#expand or collapse#',
  },
  checkout_pay_button: {
    label: 'pay',
  },
  checkout_pay_card: {
    label: 'pay_card',
  },
  checkout_remove_item: {
    label: 'delete_item',
    property: '#product_id',
  },
  checkout_learn_more: {
    label: 'learn_more',
    property: '#plan_code#',
  },
  back_pdp: {
    label: '#item_picture | product_description#',
  },
  checkout_next_button: {
    label: 'next',
  },
  checkout_next_successful: {
    label: 'next_successful',
  },
  checkout_continue_shopping_button: {
    label: 'continue_shopping',
  },
  shipping_method_checkbox: {
    label: '#option_name#',
  },
  checkout_delivery_option_button: {
    label: '#home | collection point#',
  },
  checkout_payment_method_option_button: {
    label: 'payment_method',
    property: '#payment_id#',
  },
  checkout_promo_code: {
    label: '#promo_code | promo_code_success | promo_code_failure#',
    property: '#promo_code#',
  },
  checkout_address_management: {
    label: '#add_new_address | change_address#',
    property: '#delivery_address | billing_address#',
    value: '0', // (1 means this is the first address the user is setting)
  },
  checkout_address_checkbox: {
    label: 'address_checkbox',
    property: '#check | uncheck#',
  },
  checkout_change_pick_up_point: {
    label: 'change_pick_up_point',
    property: '#pickup_up_point_name#',
  },
  checkout_seller_info: {
    label: 'seller_info',
    property: '#expand or collapse#',
    value: '#product_id#',
  },
  checkout_confirm_step: {
    label: 'confirm_step',
    property: '#section_name#', // SectionType (bag, shipping, payment)
  },
  checkout_edit_step: {
    label: 'edit_step',
    property: '#section_name#', // SectionType (bag, shipping, payment)
  },
  checkout_close_step: {
    label: 'close_step',
    property: '#section_name#', // SectionType (bag, shipping, payment)
  },
};

export const useAnalytics = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const callContainer = useCallback(
    (payload?: Record<string, unknown>) => {
      dispatch(
        sendEventAction({
          type: 'containerCall',
          payload: {
            ...payload,
          },
        })
      );
    },
    [dispatch]
  );

  // regarding tracking plan most of the events should be using StructuredEvent
  // in StructuredEvent, action key should not be a string but only ScreenName | ValidRoute
  // find a way to use FlexibleEvent for the few events that requires action as string
  // and delete string type for action key of StructuredEvent
  const sendEvent = useCallback(
    (key: keyof typeof eventsDictionary, payload?: Partial<StructuredEvent>) => {
      const mappedScreenName = getScreenName(location.pathname as ValidRoute);
      const event: StructuredEvent = {
        event: key,
        action: mappedScreenName || (location.pathname as ValidRoute),
        category: 'checkout',
        ...eventsDictionary[key],
        ...payload,
      };
      dispatch(
        sendEventAction({
          type: 'event',
          payload: event,
        })
      );
    },
    [dispatch, location]
  );

  return { callContainer, sendEvent };
};
