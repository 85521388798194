import { Entities } from '@vestiaire/api-specification';
import { Price } from '../../helpers/money';
import { CartTypes } from '../../features/cart';
import { OrderLinesTypes } from '../../features/order-lines';
import { SubtotalsTypes } from '../../features/subtotals';
import { ISubtotalDetail, ISubtotalDetailList, ISubtotalItem } from '../../features/subtotals/types';

export class AdapterAtlas {
  order: CartTypes.Response;

  constructor(order: CartTypes.Response) {
    this.order = order;
  }

  getSubtotals(): SubtotalsTypes.State {
    const details = this.getSubtotalDetails();
    const buyerServiceFeesDetails = this.getBuyerServiceFeesDetails();
    return {
      itemsAmount: this.order.itemsAmount || new Price(),
      shippingAmount: this.order.shippingAmount
        ? details?.shipping
          ? { ...this.order.shippingAmount, ...{ details: details?.shipping } }
          : this.order.shippingAmount
        : new Price(),
      dutiesAmount: this.order.dutiesAmount || new Price(),
      buyerFeesAmount: this.order.buyerFeesAmount
        ? details?.authentication
          ? { ...this.order.buyerFeesAmount, ...{ details: details?.authentication } }
          : this.order.buyerFeesAmount
        : new Price(),
      buyerServiceFeesTotalAmount: this.order.buyerServiceFeesTotalAmount
        ? buyerServiceFeesDetails
          ? { ...this.order.buyerServiceFeesTotalAmount, ...{ details: buyerServiceFeesDetails } }
          : this.order.buyerServiceFeesTotalAmount
        : (new Price() as ISubtotalItem),
      salesTaxAmount: this.order.salesTaxAmount || new Price(),
      subTotalCostBeforeShipping: this.order.subTotalCostBeforeShipping || new Price(),
      totalAmountShoppingCart: this.order.totalAmountShoppingCart || new Price(),
      totalAmount: this.order.totalAmount,
      voucherAmount: this.order.reduction || new Price(),
      itemsAmountCents: (this.order.orderLines || [])
        .filter((x) => x.type === 'orderLine')
        .reduce((sum, current) => current.product.price.cents + sum, 0),
      itemsDutiesTaxesAmountEuro: this.order.itemsDutiesTaxesAmountEuro,
    };
  }

  getSubtotalDetails(): ISubtotalDetailList | undefined {
    if (this.order.orderLines) {
      const details = this.order.orderLines?.reduce(
        (acc, { product, plans }) => {
          plans
            ?.filter((e) => e.selected)[0]
            .services.map((el) => {
              if (el.cost) {
                /* Note for the condition on next line :
                  1. it should be fixed from backend
                  2. we filter products with mandatory authentication,
                     as the authentication fee is blended into the productPrice from the PLP and PDP
                     and authentication fees should not be returned as free in the subtotal.
                  3. `includesAuthenticationFees` from api is wrongly named and should be `blendedAuthenticationFees` */
                if (el.code != 'authentication' || !!!product.pricingBreakdown?.buyerFees?.includesAuthenticationFees) {
                  acc[el.code].push({
                    brand: product.brand?.name,
                    name: product.name,
                    amount: el.cost.formatted as string,
                  });
                }
              }
            });
          return acc;
        },
        { authentication: [], shipping: [] }
      );
      return details;
    }
    return;
  }

  getBuyerServiceFeesDetails(): ISubtotalDetail[] | undefined {
    if (this.order.orderLines && this.order.orderLines[0].product.pricingBreakdown) {
      return this.order.orderLines.map(({ product }) => {
        if (product.pricingBreakdown) {
          return {
            brand: product.brand?.name || '',
            name: product.name,
            amount: product.pricingBreakdown.buyerFees?.amount.formatted || '',
          };
        } else {
          return {} as ISubtotalDetail;
        }
      });
    }
    return;
  }

  getOrderLines(): OrderLinesTypes.State {
    const orderLines: OrderLinesTypes.State =
      this.order.orderLines?.reduce(
        (prev: OrderLinesTypes.State, curr: Entities.OrderLine) => ({ ...prev, [curr.id]: curr }),
        {} as OrderLinesTypes.State
      ) || {};

    return orderLines;
  }
}
