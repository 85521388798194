import React from 'react';
import './PaymentMethodsList.scss';
import './PaymentMethodOption/PaymentMethodOption.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../../app/hooks';
import { bucketTestSelectors } from '../../../features/bucket-test';

function PaymentMethodsListSkeleton(): JSX.Element {
  const { smoothCheckoutCollapsed } = useAppSelector(bucketTestSelectors.getFlags);

  return (
    <div
      className={classNames('orderPaymentMethods orderPaymentMethods--skeleton ssc', {
        // next line to be removed after the feature flag is removed
        'orderPaymentMethods--currentVersion': !smoothCheckoutCollapsed,
      })}
      data-testid="payment_loading"
    >
      <ul className="orderPaymentMethods__list">
        {[1, 2, 3, 4].map((payment) => {
          return (
            <li className="pmOption" key={payment}>
              <div className="radioButton__label pmOption__label">
                <span className="ssc-line w-40">&nbsp;</span>
                <span className="pmOption__logos">
                  <div className="ssc-square pmOption__logos__item" />
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default PaymentMethodsListSkeleton;
