import { IEnvironment } from './model';

import dev from './environment.dev';
import stage from './environment.stage';
import production from './environment.production';
import qa from './environment.qa';
import test from './environment.jest';
import localDocker from './environment.localdocker';

const environments: {
  [key: string]: IEnvironment;
} = {
  dev,
  stage,
  qa,
  production,
  test,
  'local.docker': localDocker,
};

const env: string = process.env.REACT_APP_ENVIRONMENT || 'dev';

export default environments[env];
