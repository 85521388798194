import { Actions, ActionTypes, State, StatusEnum } from './types';

export const initialState: State = {
  status: StatusEnum.IDLE,
  selected: '',
  lastSelected: '',
  list: [],
  draftList: [],
};

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH: {
      return {
        ...state,
        status: StatusEnum.PEND_GET_LIST,
      };
    }
    case ActionTypes.RESOLVE: {
      return {
        ...state,
        status: StatusEnum.OK_GET_LIST,
        draftList: action.payload,
        list: action.payload,
        selected: '',
      };
    }
    case ActionTypes.REJECT: {
      return {
        ...state,
        list: [],
        draftList: [],
        status: StatusEnum.ERROR_GET_LIST,
      };
    }
    case ActionTypes.RESOLVE_DRAFT: {
      return {
        ...state,
        status: StatusEnum.OK_GET_LIST,
        draftList: action.payload,
      };
    }
    case ActionTypes.REJECT_DRAFT: {
      return {
        ...state,
        list: [],
        draftList: [],
        status: StatusEnum.ERROR_GET_LIST,
      };
    }
    case ActionTypes.SET_DRAFT_SELECTED: {
      return {
        ...state,
        draftSelected: action.payload,
      };
    }
    case ActionTypes.SET_DRAFT_ADDRESS: {
      return {
        ...state,
        draftAddress: action.payload,
      };
    }
    case ActionTypes.SET_COLLECTION_POINT_REQUEST: {
      return {
        ...state,
        status: StatusEnum.PEND_SET_ITEM,
        selected: action.payload.reference,
        confirmedDraftAddress: state.draftSelected
          ? Object.assign({}, state.draftAddress)
          : state.confirmedDraftAddress,
        list: state.draftList,
      };
    }
    case ActionTypes.SET_COLLECTION_POINT_RESOLVE: {
      return {
        ...state,
        status: StatusEnum.OK_SET_ITEM,
        draftSelected: state.selected,
        lastSelected: state.selected,
      };
    }
    case ActionTypes.SET_COLLECTION_POINT_REJECT: {
      return {
        ...state,
        status: StatusEnum.ERROR_SET_ITEM,
        selected: state.lastSelected,
        confirmedDraftAddress: undefined,
      };
    }

    default:
      return state;
  }
};
