import { Entities } from '@vestiaire/api-specification';
import { Actions, ActionTypes, State } from './types';
import { getSession } from './services';

const initialState = {
  id: getSession(),
} as State;

export const reducer = (state = initialState, { type, payload }: Actions<State>): State => {
  if (type === ActionTypes.SET_AUTH) {
    return {
      ...state,
      id: payload?.id,
    };
  }
  if (type === ActionTypes.SET_USER) {
    return {
      ...state,
      user: {
        ...(payload as Entities.User),
      },
    };
  }
  return state;
};
