import React, { ComponentProps, PropsWithChildren } from 'react';
import './RadioButton.scss';

export type RadioButtonProps = PropsWithChildren<ComponentProps<'input'> & { labelClassName?: string }>;

const RadioButton = ({ className, labelClassName, id, children, ...props }: RadioButtonProps): JSX.Element => {
  return (
    <div data-testid={`radio_warpper_${id}`} className={`radioButton ${className ? className : ''}`}>
      <input
        data-testid={`radio_input_${id}`}
        type="radio"
        className="radioButton__input visuallyhidden"
        id={`radioBox_${id}`}
        {...props}
      />
      <label
        data-testid={`radio_label_${id}`}
        data-dd-action-name={`select_${id}`}
        className={`radioButton__label ${labelClassName ? labelClassName : ''}`}
        htmlFor={`radioBox_${id}`}
      >
        {children}
      </label>
    </div>
  );
};

export default RadioButton;
