import React from 'react';
import './BillingAddressSkeleton.scss';
import classNames from 'classnames';
import { useAppSelector } from '../../../app/hooks';
import { bucketTestSelectors } from '../../../features/bucket-test';

const BillingAddressSkeleton = (): JSX.Element => {
  const { smoothCheckoutCollapsed } = useAppSelector(bucketTestSelectors.getFlags);

  return (
    <div
      className={classNames('billingAddress billingAddressSkeleton ssc', {
        // next line to be removed after the feature flag is removed
        'billingAddress--currentVersion': !smoothCheckoutCollapsed,
      })}
      data-testid="billingAddress_loading"
    >
      <div className="billingAddress__sameAsDelivery">
        <span className="ssc-line w-40" />
      </div>
      <div className="billingAddress__card ssc">
        <div className="billingAddress__current ssc">
          <span className="ssc-line w-40" />
          <span className="ssc-line w-60" />
          <span className="ssc-line w-50" />
        </div>
        <div className="billingAddress__actions ssc">
          <span className="ssc-line w-40" />
          <span className="ssc-line w-40" />
        </div>
      </div>
    </div>
  );
};

export default BillingAddressSkeleton;
