import { pluckDelivery, pluckBilling } from './selectors';
import { Actions, ActionTypes, State, StatusEnum } from './types';

export const initialState: State = {
  status: StatusEnum.IDLE,
  list: [],
  isBillingSameAsDelivery: false,
};

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH: {
      return {
        ...state,
        status: StatusEnum.PEND_GET_LIST,
      };
    }
    case ActionTypes.RESOLVE: {
      const billing = pluckBilling(action.payload);
      const delivery = pluckDelivery(action.payload);
      return {
        ...state,
        status: StatusEnum.OK_GET_LIST,
        list: action.payload,
        selectedDeliveryAddressId: pluckDelivery(action.payload)?.addressId || 0,
        selectedBillingAddressId: pluckBilling(action.payload)?.addressId || 0,
        isBillingSameAsDelivery: billing?.addressId === delivery?.addressId,
      };
    }
    case ActionTypes.REJECT: {
      return {
        ...state,
        status: StatusEnum.ERROR_GET_LIST,
      };
    }
    case ActionTypes.SET_BILLING_ADDRESS_REQUEST: {
      return {
        ...state,
        status: StatusEnum.PEND_SET_ITEM,
        selectedBillingAddressId: action.payload,
      };
    }
    case ActionTypes.SET_BILLING_ADDRESS_RESOLVE: {
      return {
        ...state,
        status: StatusEnum.OK_SET_ITEM,
        lastSelectedBillingAddressId: state.selectedBillingAddressId,
      };
    }
    case ActionTypes.SET_BILLING_ADDRESS_REJECT: {
      return {
        ...state,
        status: StatusEnum.ERROR_SET_ITEM,
        selectedBillingAddressId: state.lastSelectedBillingAddressId,
      };
    }
    case ActionTypes.SET_BILLING_SAME_AS_DELIVERY: {
      return {
        ...state,
        isBillingSameAsDelivery: action.payload,
      };
    }
    default:
      return state;
  }
};
