import { Entities } from '@vestiaire/api-specification';
import { i18nServices } from '../features/i18n';

export class Price implements Entities.Price {
  public cents: number;

  public formatted: string;

  public currency: Entities.Currency;

  constructor() {
    this.cents = 0;
    this.formatted = '';
    this.currency = i18nServices.getCurrency();
  }
}
