import Axios, { AxiosRequestConfig } from 'axios';
import environment from '../environments';
import { ApiResponse } from '../types';
import { authServices } from '../features/auth';
import { i18nServices } from '../features/i18n';

function getI18nConfig(): {
  'x-currency': string;
  'x-language': string;
  'x-siteid': string;
} {
  const [idSite, lang, currency] = i18nServices.get();

  return {
    'x-currency': currency,
    'x-language': lang,
    'x-siteid': idSite,
  };
}

/* 
  this Axios instance needs to be compatible with :
  - generic atlas calls
  - vc-auth react component api calls
  - extendable by apiMonolith
*/
Axios.interceptors.response.use((res) => Promise.resolve(res));

export class Api {
  private atlasConfig: AxiosRequestConfig = {
    baseURL: environment.apiBaseUrl, // env file
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  private prepareConfigs(options: AxiosRequestConfig = {}) {
    const { params = {}, ...rest } = options;
    return {
      ...this.atlasConfig,
      params: { ...getI18nConfig(), ...params },
      headers: {
        ...this.atlasConfig.headers,
        __session: authServices.getSession(),
      },
      ...rest,
    };
  }

  get<R>(url: string, options?: AxiosRequestConfig): Promise<ApiResponse<R>> {
    return Axios.get(url, this.prepareConfigs(options))
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err.response ? err.response.data : err));
  }

  post<R, P = R>(url: string, payload: P, options?: AxiosRequestConfig): Promise<ApiResponse<R>> {
    return Axios.post(url, payload, this.prepareConfigs(options))
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err.response ? err.response.data : err));
  }

  patch<R, P = R>(url: string, payload: P): Promise<ApiResponse<R>> {
    return Axios.patch(url, payload, this.prepareConfigs())
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err.response ? err.response.data : err));
  }

  delete<R, P = R>(url: string, payload: P): Promise<ApiResponse<R>> {
    return Axios.delete(url, {
      ...this.prepareConfigs(),
      data: payload,
    })
      .then((res) => res.data || res)
      .catch((err) => Promise.reject(err.response ? err.response.data : err));
  }
}

export default Api;
