import { Actions, State, ActionTypes, StatusEnum } from './types';

export const bucketTestInitialState: State = {
  status: StatusEnum.IDLE,
  clientId: '',
  errorMsg: undefined,
  client: undefined,
  flags: {},
  options: {},
  user: {},
};

export const reducer = (state = bucketTestInitialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.INITIALIZE: {
      return {
        ...state,
        status: StatusEnum.PEND_INIT_ITEM,
      };
    }
    case ActionTypes.RESOLVE: {
      return {
        ...state,
        status: StatusEnum.OK_INIT_ITEM,
        ...action.payload,
      };
    }
    case ActionTypes.REJECT: {
      return {
        ...state,
        ...bucketTestInitialState,
        status: StatusEnum.ERROR_INIT_ITEM,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
