import { State, ActionType, SET_SUBTOTALS } from './types';

const initialState: State = {} as State;

export const reducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case SET_SUBTOTALS: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
