import { takeLatest, call, put, ForkEffect, PutEffect, SelectEffect, select } from 'redux-saga/effects';
import { uiActions } from '../ui';
import { Actions, ActionTypes } from './types';
import { cartServices, cartActions, CartTypes } from '../cart';
import logger from '../../services/logger.service';
import toaster from '../../helpers/toaster';
import i18n from '../../i18n';
import { State } from './types';
import { getSoldItems, getReservedItems, getProhibitedItems } from './selectors';

export function* enquiry(): Generator<PutEffect | SelectEffect, void, string[]> {
  const soldItems = yield select(getSoldItems);
  const reservedItems = yield select(getReservedItems);
  const prohibitedItems = yield select(getProhibitedItems);
  yield put(cartActions.setSoldProductsInBasket(soldItems.concat(reservedItems)));
  yield put(cartActions.setProhibitedProductsInBasket(prohibitedItems));
}

function* updateShippingPlan(action: Actions<{ productId: string; shippingOption: string }>) {
  const { productId, shippingOption } = action?.payload || { productId: '', shippingOption: '' };
  try {
    yield call([cartServices.api, 'updateProductDeliveryMethod'], productId, shippingOption);
    yield put(cartActions.fetch());
  } catch (err) {
    logger.error('order-lines::updateShippingPlan', err);
    toaster.error(
      i18n.t(
        'ERRORS.CHANGE_SHIPPING_PLANS',
        'Sorry, we encounter an issue when we change the shipping plans please try another time'
      )
    );
    yield put(uiActions.setSummaryLoading(false));
  }
}

function* removeLineItem(action: CartTypes.InferredAction<CartTypes.ActionTypes.REMOVE_LINE_ITEM>) {
  yield put(cartActions.removeLineItem(action.payload));
  try {
    yield call([cartServices.api, 'removeProduct'], action.payload);
  } catch (err) {
    logger.error('order-lines::removeLineItem', err);
    toaster.error(
      i18n.t('ERRORS.REMOVE_PRODUCT', 'Sorry, we encounter an issue when we remove the item please try another time')
    );
  } finally {
    yield put(cartActions.fetch());
  }
}

export function* sagas(): Generator<ForkEffect<Actions<State>>> {
  yield takeLatest(ActionTypes.REMOVE_LINE_ITEM, removeLineItem);
  yield takeLatest(ActionTypes.UPDATE_SHIPPING_PLAN, updateShippingPlan);
  yield takeLatest(ActionTypes.ENQUIRY_ORDER_LINES, enquiry);
}
