import React, { Fragment, useEffect, useState } from 'react';
import './SubtotalItem.scss';
import Icon from '../Icons/Icons';
import { useAppSelector } from '../../app/hooks';
import { uiSelectors } from '../../features/ui';
import classNames from 'classnames';
import { trackingHooks } from '../../features/tracking';
import { useLocation } from 'react-router-dom';

type PropsTypes = {
  title: string;
  amount: string;
  testId: string;
  tooltip?: React.FC;
  noToggle?: boolean;
  children?: React.ReactNode;
  trackingName?: string;
};

const InnerSubtotal: React.FC<PropsTypes> = ({ title, amount, testId, tooltip, noToggle, children }): JSX.Element => {
  const isSummaryLoading = useAppSelector(uiSelectors.getSummaryLoading);

  return (
    <Fragment key={`InnerSubtotal_${testId}`}>
      <span className="subtotals__title">
        {title}
        {children && !noToggle && <Icon name="arrow-head-down" />}
        {tooltip && tooltip({})}
      </span>
      {isSummaryLoading ? (
        <span className="ssc ssc-line sumup__amountLoading"></span>
      ) : (
        <span className="subtotals__amount" data-testid={`${testId}_amount`}>
          {amount}
        </span>
      )}
    </Fragment>
  );
};

const SubtotalItem: React.FC<PropsTypes> = (props): JSX.Element => {
  const { children, noToggle, trackingName } = props;
  const { sendEvent } = trackingHooks.useAnalytics();
  const [openDetails, setOpenDetails] = useState(false);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<null | string>(null);

  const onToggleDetails = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!!!noToggle) {
      setOpenDetails(!openDetails);
      if (trackingName) {
        sendEvent(`checkout_${trackingName}_dropdown`, {
          property: !openDetails ? 'expand' : 'collapse', //'#expand or collapse#'
        });
      }
    }
  };

  useEffect(() => {
    if (location.pathname !== prevLocation) setOpenDetails(false);
    setPrevLocation(location.pathname);
  }, [location, prevLocation]);

  return (
    <Fragment key={`SubtotalItem_${props.testId}`}>
      {children ? (
        <li className="subtotals__entry">
          <details
            className={classNames('subtotals__detailsWrapper', {
              noToggle: !!noToggle,
            })}
            open={!!noToggle || openDetails}
          >
            <summary className="subtotals__header" onClick={onToggleDetails}>
              <InnerSubtotal {...props} />
            </summary>
            <div className="subtotals__content">{children}</div>
          </details>
        </li>
      ) : (
        <li className="subtotals__entry subtotals__header">
          <InnerSubtotal {...props} />
        </li>
      )}
    </Fragment>
  );
};

export default SubtotalItem;
