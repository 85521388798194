import React from 'react';
import { useTranslation } from 'react-i18next';
import './MainErrorView.scss';

// TODO ask for proper wordings

const MainErrorView = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="mainError">
      <p className="mainError__message">
        {t('CHECKOUT.MAIN_ERROR.MESSAGE', 'There was a technical error. Please try to reload your page')}
      </p>
      <button className="mainError__cta vc-btn vc-btn--primary vc-btn--large" onClick={() => window.location.reload()}>
        {t('COMMON.REFRESH', 'Refresh')}
      </button>
    </div>
  );
};

export default MainErrorView;
