import { AddressTypes } from '../address';
import { Action, InferAction } from '../../types/index';
import { PaymentTypes } from '../payment';
import { Entities } from '@vestiaire/api-specification';

export type Modal = {
  isOpen: boolean;
  onPageLoad?: boolean;
  onClickCTA?: boolean;
  type?: string;
};

export type MessageModal = {
  message: string;
} & Modal;

type BaseModal = {
  isOpen: boolean;
};

export type ClosedModal = BaseModal & {
  isOpen: false;
};

export type OpenedPricingBreakdownModal = BaseModal & {
  isOpen: true;
  product: Entities.Product;
};

export type PricingBreakdownModalType = ClosedModal | OpenedPricingBreakdownModal;

export type AddressForm = {
  isModalOpen: boolean;
  type: AddressTypes.Type;
  action: AddressTypes.CtaType;
};

export interface State {
  emptyCart: boolean;
  checkoutOverlay: boolean;
  orderLine: {
    loading: boolean;
  };
  summary: {
    loading: boolean;
  };
  cta: {
    loading: boolean;
  };
  reInsurance: {
    loading: boolean;
  };
  payButton: {
    loading: boolean;
  };
  soldItemsModal: Partial<Modal>;
  prohibitedItemsModal: Partial<Modal>;
  addressForm: Partial<AddressForm>;
  collectionPointModal: Partial<Modal>;
  authModal: Partial<Modal>;
  redirectToCheckoutError: PaymentTypes.CheckoutPathResultCode | null;
  refreshCartModal: Partial<Modal>;
  messageModal: Partial<MessageModal>;
  pricingBreakdownModal: PricingBreakdownModalType;
  highlightPaymentMethodUpdate: boolean;
}

export enum ActionTypes {
  SET_EMPTY_CART = 'ui/setEmptyCart',
  SET_CHECKOUT_OVERLAY = 'ui/setCheckoutOverlay',
  SET_CTA_LOADING = 'ui/setCTALoading',
  SET_ORDER_LINE_LOADING = 'ui/setOrderLineLoading',
  SET_SUMMARY_LOADING = 'ui/setSummaryLoading',
  SET_RE_INSURANCE_LOADING = 'ui/setReInsuranceLoading',
  SET_PAY_BUTTON_LOADING = 'ui/setPayButtonLoading',
  SOLD_ITEMS_MODAL = 'ui/showSoldItemsModal',
  PROHIBITED_ITEMS_MODAL = 'ui/showProhibitedItemsModal',
  SET_ADDRESS_FORM = 'ui/setAddressForm',
  SET_COLLECTION_POINT_MODAL = 'ui/setCollectionPointModal',
  SET_AUTH_MODAL = 'ui/authModal',
  SET_REDIRECT_TO_CHECKOUT_ERROR = 'ui/redirectToCheckoutError',
  SET_REFRESH_CART_MODAL = 'ui/setRefreshCartModal',
  SET_MESSAGE_MODAL = 'ui/setMessageModal',
  SET_PRICING_BREAKDOWN_MODAL = 'ui/setPricingBreakdownModal',
  SET_PAYMENT_METHOD_UPDATE_ALERT = 'ui/highlightPaymentMethodUpdate',
}

export type PayloadTypes = {
  [ActionTypes.SET_EMPTY_CART]: boolean;
  [ActionTypes.SET_CHECKOUT_OVERLAY]: boolean;
  [ActionTypes.SET_CTA_LOADING]: boolean;
  [ActionTypes.SET_ORDER_LINE_LOADING]: boolean;
  [ActionTypes.SET_SUMMARY_LOADING]: boolean;
  [ActionTypes.SET_RE_INSURANCE_LOADING]: boolean;
  [ActionTypes.SET_PAY_BUTTON_LOADING]: boolean;
  [ActionTypes.SOLD_ITEMS_MODAL]: Partial<Modal>;
  [ActionTypes.PROHIBITED_ITEMS_MODAL]: Partial<Modal>;
  [ActionTypes.SET_ADDRESS_FORM]: Partial<AddressForm>;
  [ActionTypes.SET_COLLECTION_POINT_MODAL]: Partial<Modal>;
  [ActionTypes.SET_AUTH_MODAL]: Partial<Modal>;
  [ActionTypes.SET_REDIRECT_TO_CHECKOUT_ERROR]: PaymentTypes.CheckoutPathResultCode | null;
  [ActionTypes.SET_REFRESH_CART_MODAL]: Partial<Modal>;
  [ActionTypes.SET_MESSAGE_MODAL]: Partial<MessageModal>;
  [ActionTypes.SET_PRICING_BREAKDOWN_MODAL]: PricingBreakdownModalType;
  [ActionTypes.SET_PAYMENT_METHOD_UPDATE_ALERT]: boolean;
};

export type Actions = Action<PayloadTypes>[keyof Action<PayloadTypes>];
export type CartInferredAction<T extends keyof PayloadTypes> = InferAction<PayloadTypes, T>;
